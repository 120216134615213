import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface AuthEmployerInitialData {
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    employerCategory?: string;
    areaId?: string[];
}

const authNewEmployerInitialValues = autoGeneratedReducer<AuthEmployerInitialData>({});
export { authNewEmployerInitialValues };
