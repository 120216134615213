import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { Paging } from 'src/models/paging.types';

export interface EmployersCompanyIndustry {
    id: string;
    parentId: string | null;
    title: string;
    translit: string;
}

export interface EmployerCompany {
    id: string;
    name: string;
    vacanciesOpen: number;
}

interface EmployersCompany {
    area: {
        id: string;
        locativeCase: string;
    };
    industries: EmployersCompanyIndustry[];
    employers: {
        list: EmployerCompany[];
        count: number;
        paging: Paging;
    } | null;
}

const employersCompany = autoGeneratedReducer<EmployersCompany>(null);
export { employersCompany };
