import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { EmployerAddress } from 'src/models/employerAddresses.types';

const APPEND_EMPLOYER_ADDRESS = 'APPEND_EMPLOYER_ADDRESS';

interface PayloadTypes {
    [APPEND_EMPLOYER_ADDRESS]: EmployerAddress;
}

const ActionCreator = ActionCreatorHelper<PayloadTypes>();

export const appendEmployerAddress = ActionCreator(APPEND_EMPLOYER_ADDRESS);

const employerAddresses = createReducer<EmployerAddress[], PayloadTypes>([], {
    [APPEND_EMPLOYER_ADDRESS]: (state, action) => {
        if (state.find((addr) => addr.id === action.payload.id)) {
            return state;
        }

        return [...state, action.payload];
    },
});

export { employerAddresses };
