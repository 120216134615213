import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { VacancySearchItem } from 'src/types/search/vacancy/card';

import { RelatedVacancies } from 'src/models/relatedVacancies';

const PUSH_RELATED_EMPLOYER_VACANCIES = 'PUSH_RELATED_EMPLOYER_VACANCIES';

const INITIAL_STATE: RelatedVacancies = {
    vacancies: [],
};

interface PayloadTypes {
    [PUSH_RELATED_EMPLOYER_VACANCIES]: VacancySearchItem[];
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();
export const pushRelatedVacanciesForEmployerAction = actionCreator(PUSH_RELATED_EMPLOYER_VACANCIES);

const relatedVacanciesForEmployer = createReducer<RelatedVacancies, PayloadTypes>(INITIAL_STATE, {
    [PUSH_RELATED_EMPLOYER_VACANCIES]: (state, action) => ({
        ...state,
        vacancies: [...state.vacancies, ...(action.payload ?? [])],
    }),
});

export { relatedVacanciesForEmployer };
