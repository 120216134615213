import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

const pageMetaData = autoGeneratedReducer({
    header: '',
    title: '',
    ogTitle: '',
    description: '',
    ogDescription: '',
    rssHref: '',
    rssTitle: '',
    canonical: '',
    h1: '',
    hreflangItems: [],
    verifications: [],
});

export { pageMetaData };
