import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { PriceRecommendedBargain } from 'src/models/price/priceBargain';
import { DBAccessProductType, PublicationProductType } from 'src/models/price/product.types';

export enum RecommendedPriceItemType {
    Bargain = 'bargain',
    HHRating = 'hhrating',
    Kakdela = 'kakdela',
    Talantix = 'talantix',
    // tempexp_40483_next_line
    Tempexp40483 = 'tempexp-40483',
    HrSpace = 'hr-space',
    VideoInterview = 'video-interview',
    DreamJob = 'dreamjob',
}

export enum RecommendedTabVariants {
    Classic = 'CLASSIC',
    Landing = 'LANDING',
}

interface PriceRecommendedPageState {
    type: RecommendedTabVariants;
    recommendedPrices: {
        dbAccessPrice: DBAccessProductType & { regionTrl: string };
        publicationPrice: PublicationProductType;
    } | null;
    bargains: PriceRecommendedBargain[];
    hhRatingPromo: boolean;
    talantixPromo: boolean;
    hrSpacePromo: boolean;
    kakdelaPromo: boolean;
    videointerviewPromo: boolean;
    dreamJob: boolean;
}

const priceRecommended = autoGeneratedReducer<PriceRecommendedPageState>(null);
export { priceRecommended };
