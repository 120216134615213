import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

export const COMPLAINT_REASON_TYPES = 'COMPLAINT_REASON_TYPES';

export enum ComplaintReason {
    AdInNegotiation = 'AD_IN_NEGOTIATION',
    AdInResume = 'AD_IN_RESUME',
    TooManyNegotiations = 'TOO_MANY_NEGOTIATIONS',
    NoSenseNegotiation = 'NO_SENSE_NEGOTIATION',
    Other = 'OTHER',
}

export interface ComplaintReasonType {
    needComment: boolean;
    reasonType: ComplaintReason;
}

export interface ComplaintReasonTypeDoggyStyle {
    ['@needComment']: boolean;
    ['@reasonType']: ComplaintReason;
}

export type ComplaintReasonTypes = ComplaintReasonType[];

export const INITIAL_STATE: ComplaintReasonTypes = [];

interface PayloadTypes {
    [COMPLAINT_REASON_TYPES]: ComplaintReasonTypeDoggyStyle[];
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();
export const complaintReasonTypesAction = actionCreator(COMPLAINT_REASON_TYPES);

const complaintReasonTypes = createReducer<ComplaintReasonTypes, PayloadTypes>(INITIAL_STATE, {
    [COMPLAINT_REASON_TYPES]: (_, action) =>
        action.payload?.map((type: ComplaintReasonTypeDoggyStyle) => {
            return {
                needComment: type['@needComment'],
                reasonType: type['@reasonType'],
            };
        }),
});

export { complaintReasonTypes };
