import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

const EMPLOYER_LOGO_SET_LOGO_STATUS = 'EMPLOYER_LOGO_SET_STATUS';
const EMPLOYER_LOGO_SET_LOGO_URL = 'EMPLOYER_LOGO_SET_URL';
const EMPLOYER_LOGO_REMOVE_LOGO_URL = 'EMPLOYER_LOGO_REMOVE_LOGO_URL';

export enum Status {
    Dirty = 'Dirty',
    Fetching = 'Fetching',
    Success = 'Success',
    Fail = 'Fail',
}

interface EmployerLogo {
    logoUrl?: string;
    isRoundLogoUsed?: boolean;
    logoStatus: Status;
}

export const INITIAL_STATE = {
    logoStatus: Status.Dirty,
};

interface PayloadTypes {
    [EMPLOYER_LOGO_SET_LOGO_STATUS]: Status;
    [EMPLOYER_LOGO_SET_LOGO_URL]: EmployerLogo['logoUrl'];
    [EMPLOYER_LOGO_REMOVE_LOGO_URL]: void;
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();
export const setLogoUrl = actionCreator(EMPLOYER_LOGO_SET_LOGO_URL);
export const removeLogoUrl = actionCreator(EMPLOYER_LOGO_REMOVE_LOGO_URL);
export const setLogoStatus = actionCreator(EMPLOYER_LOGO_SET_LOGO_STATUS);

const employerLogo = createReducer<EmployerLogo, PayloadTypes>(INITIAL_STATE, {
    [EMPLOYER_LOGO_SET_LOGO_STATUS]: (state, { payload }) => {
        return { ...state, logoStatus: payload };
    },
    [EMPLOYER_LOGO_SET_LOGO_URL]: (state, { payload }) => {
        return { ...state, logoUrl: payload, isRoundLogoUsed: true };
    },
    [EMPLOYER_LOGO_REMOVE_LOGO_URL]: (state) => {
        return { ...state, logoUrl: undefined };
    },
});

export { employerLogo };
