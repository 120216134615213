import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface CompanyOfTheDay {
    companyName: string;
    vacanciesNumber: number;
    id: number;
    workInCompanyId: number;
    placeId: number;
    viewUrl?: string | null;
    logoUrl?: string;
}

const companiesOfTheDay = autoGeneratedReducer<CompanyOfTheDay[]>([]);
export { companiesOfTheDay };
