import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface Crosslinks {
    id: number;
    domain: string;
    trl: string;
}

const crosslinks = autoGeneratedReducer<{ links: Crosslinks[]; translit: string }>(null);
export { crosslinks };
