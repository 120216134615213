import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer/lib/createReducer';

import { VacanciesType } from 'src/models/employerVacancies/vacanciesCount';

export interface VacancyTab {
    name: VacanciesType;
    href: string;
    title: string;
}

export type VacanciesTabsState = VacancyTab[];

export const setVacanciesTabs = makeSetStoreField('vacanciesTabs');

const vacanciesTabs = autoGeneratedReducer<VacanciesTabsState>([]);
export { vacanciesTabs };
