import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';
import { WorkSchedule, WorkExperience } from '@hh.ru/types-hh-microcore';

import { CurrencyType } from 'src/models/currencies.types';

import { ClickmeProducts, ProductName } from 'src/models/clickme/clickmeProducts';

export enum AutoCampaignCreationStatus {
    Disabled = 'DISABLED',
    NotCreated = 'NOT_CREATED',
    CampaignDraftCreated = 'CAMPAIGN_DRAFT_CREATED',
    CampaignCreated = 'CAMPAIGN_CREATED',
}

export enum CampaignStatus {
    Moderation = 'MODERATION',
    ModerationFailed = 'MODERATION_FAILED',
    Active = 'ACTIVE',
    Blocked = 'BLOCKED',
    CampaignLowBalance = 'CAMPAIGN_LOW_BALANCE',
}

export enum AccountStatus {
    AccountLowBalance = 'ACCOUNT_LOW_BALANCE',
    AccountOkBalance = 'ACCOUNT_OK_BALANCE',
}

export interface CampaignInfo {
    campaignId: number;
    campaignStatus: CampaignStatus;
    accountStatus: AccountStatus;
    budget: number | null;
    vacancyId: number;
    campaignBalance: number;
}

export interface CampaignDraft {
    autoCampaignDraftId: string;
    purchaseUrl: string;
    title: string;
    text: string;
    type: ProductName;
}

export interface CurrentVacancyClickmeAutoCampaignData {
    accountStatus?: AccountStatus;
    campaignCreationStatus: AutoCampaignCreationStatus;
    campaignStatus?: CampaignStatus;
}

export interface ClickmeAutoCampaignData {
    campaignCreationStatus: AutoCampaignCreationStatus;
    accountStatus: AccountStatus;
    campaignInfo: CampaignInfo | null;
    campaignDraft: CampaignDraft | null;
    currency: CurrencyType;
    vacancyId: number | null;
    availableMoney: number;
    hideClickmeBalance: boolean;
    accountBalance: number;
    minBudget: number;
}

export interface InfoFromVacancyWorkExperience {
    id: number;
    name: WorkExperience;
    translation: string;
}
export interface InfoFromVacancyWorkSchedule {
    id: number;
    name: WorkSchedule;
    translation: string;
}

export interface AutoCampaignInfoFromVacancy {
    vacancyId: number;
    title: string;
    text: string;
    alreadyCreated: boolean;
    availableProducts: ClickmeProducts;
    type: ProductName;
    workExperience: InfoFromVacancyWorkExperience;
    workSchedule: InfoFromVacancyWorkSchedule;
}

const SET_CLICKME_AUTO_CAMPAIGN_DATA = 'SET_CLICKME_AUTO_CAMPAIGN_DATA';
const SET_CLICKME_AUTO_CAMPAIGN_DRAFT = 'SET_CLICKME_AUTO_CAMPAIGN_DRAFT';

interface PayloadTypes {
    [SET_CLICKME_AUTO_CAMPAIGN_DATA]: ClickmeAutoCampaignData;
    [SET_CLICKME_AUTO_CAMPAIGN_DRAFT]: CampaignDraft;
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();

export const setClickmeAutoCampaignData = actionCreator(SET_CLICKME_AUTO_CAMPAIGN_DATA);
export const setClickmeAutoCampaignDraft = actionCreator(SET_CLICKME_AUTO_CAMPAIGN_DRAFT);

interface InitialState {
    autoCampaignInfoFromVacancy: AutoCampaignInfoFromVacancy | null;
    clickmeAutoCampaignData: ClickmeAutoCampaignData;
    vacancyPremoderateStatus: string | null;
}

export const INITIAL_STATE: InitialState = {
    autoCampaignInfoFromVacancy: null,
    clickmeAutoCampaignData: {
        campaignCreationStatus: AutoCampaignCreationStatus.Disabled,
        accountStatus: AccountStatus.AccountOkBalance,
        campaignInfo: null,
        campaignDraft: null,
        currency: CurrencyType.RUR,
        vacancyId: null,
        availableMoney: 0,
        hideClickmeBalance: true,
        accountBalance: 0,
        minBudget: 0,
    },
    vacancyPremoderateStatus: null,
};

const clickmeAutoCampaignPromotion = createReducer<InitialState, PayloadTypes>(INITIAL_STATE, {
    [SET_CLICKME_AUTO_CAMPAIGN_DATA]: (state, { payload }) => ({
        ...state,
        clickmeAutoCampaignData: payload,
    }),
    [SET_CLICKME_AUTO_CAMPAIGN_DRAFT]: (state, { payload }) => ({
        ...state,
        clickmeAutoCampaignData: {
            ...state.clickmeAutoCampaignData,
            campaignDraft: payload,
        },
    }),
});

export { clickmeAutoCampaignPromotion };
