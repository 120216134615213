import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { ManagerType } from 'src/models/employerManagers.types';
import { NegotiationStats } from 'src/models/managerNegotiationStats';
import { Paging } from 'src/models/paging.types';
import { ServiceItemCode } from 'src/models/price/productCodes';

interface VacancyData {
    id: number;
    name: string;
    link: string;
}

export interface ManagersList {
    canCreateVacancies: boolean;
    canDelete: boolean;
    canEdit: boolean;
    canViewResumeContacts: boolean;
    email: string;
    fullName: string;
    id: number;
    userId: number;
    self?: boolean;
    type: Exclude<ManagerType, ManagerType.McpGroup>;
    quotas: Quotas[];
    managerType: Extract<ManagerType, ManagerType.McpGroup | ManagerType.Manager>;
    hiringManagerVacancies?: VacancyData[];
}

type QuotasServiceEntry =
    | ServiceItemCode.ContactsView
    | ServiceItemCode.DynamicTestOfNumericalAbilities
    | ServiceItemCode.DynamicTestOfVerbalAbilities
    | ServiceItemCode.UniversalCompetencyQuestionnaire
    | ServiceItemCode.Advertizing
    | ServiceItemCode.Anonymous
    | ServiceItemCode.Standart
    | ServiceItemCode.StandartPlus
    | ServiceItemCode.Premium;

export interface Quotas {
    permittedToSpend: boolean;
    serviceEntry: QuotasServiceEntry;
    value: number;
}

type ResumeViews = Record<number, string>;

export interface ManagersViewMeta {
    ableToCreateManager: boolean;
    ableToCreateHiringManager: boolean;
    canEditEmployerManagerQuotas: boolean;
    managersCreationLimitReached: boolean;
    onlyOneManagerEnabled: boolean;
    MCPSGroup: boolean;
    resumeViewsAccessDenied: boolean;
    ResumeViewHistory: boolean;
    showResumeViewsNumbers: boolean;
    isSudo?: boolean;
    giveMCPAllowed: boolean;
    userEmail: string;
    isShowMCP: boolean;
}

interface EmployerManagersPageState {
    managersViewMeta: ManagersViewMeta;
    managersShown: ManagersList[];
    negotiationStats: Record<number, { employerManagerIndex: NegotiationStats }>;
    resumeViews: ResumeViews;
    paging?: Paging;
    filter: string;
    loading: boolean;
}

const DELETE_MANAGER = 'DELETE_MANAGER';
const FILTER_LOADING = 'FILTER_LOADING';

interface PayloadTypes {
    [DELETE_MANAGER]: number;
    [FILTER_LOADING]: boolean;
}

const ActionCreator = ActionCreatorHelper<PayloadTypes>();

export const deleteManager = ActionCreator(DELETE_MANAGER);
export const filterLoading = ActionCreator(FILTER_LOADING);

const initialState: EmployerManagersPageState = {
    managersViewMeta: {
        ableToCreateManager: false,
        ableToCreateHiringManager: false,
        canEditEmployerManagerQuotas: false,
        managersCreationLimitReached: true,
        onlyOneManagerEnabled: false,
        MCPSGroup: false,
        resumeViewsAccessDenied: true,
        ResumeViewHistory: false,
        showResumeViewsNumbers: false,
        giveMCPAllowed: false,
        userEmail: '',
        isShowMCP: false,
    },
    managersShown: [],
    negotiationStats: {},
    resumeViews: {},
    filter: '',
    loading: false,
};

const employerManagersPage = createReducer<EmployerManagersPageState, PayloadTypes>(initialState, {
    [DELETE_MANAGER]: (state, action) => ({
        ...state,
        managersShown: state.managersShown.filter((manager) => manager.id !== action.payload),
    }),
    [FILTER_LOADING]: (state, action) => ({
        ...state,
        loading: action.payload,
    }),
});

export { employerManagersPage };
