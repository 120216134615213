import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { CurrencyType } from 'src/models/currencies.types';
import { DriverLicense } from 'src/models/driverLicense.types';

export enum Gender {
    Male = 'male',
    Female = 'female',
    Unknown = 'unknown',
}

export enum EducationLevel {
    None = 'none',
    Higher = 'higher',
    Bachelor = 'bachelor',
    Master = 'master',
    Candidate = 'candidate',
    Doctor = 'doctor',
    UnfinishedHigher = 'unfinished_higher',
    Secondary = 'secondary',
    SpecialSecondary = 'special_secondary',
}

export enum ExperienceOption {
    None = 'none',
    One2Three = 'one2three',
    Three2Six = 'three2six',
    MoreThanSix = 'moreThanSix',
}

export enum RelocationOption {
    Living = 'living',
    LivingButRelocation = 'living_but_relocation',
    LivingOrRelocation = 'living_or_relocation',
    Relocation = 'relocation',
}

export enum OrderOption {
    Date = 'DATE',
    Relevance = 'RELEVANCE',
}

export enum FilterName {
    Age = 'age',
    Gender = 'gender',
    Areas = 'area',
    Relocation = 'relocation',
    Citizenship = 'citizenship',
    Education = 'education',
    Universities = 'university',
    Experience = 'experience',
    DriverLicenses = 'driverLicense',
    Languages = 'language',
    Salary = 'salary',
    Distance = 'distance',
    ShowOnlyWithVehicle = 'showOnlyWithVehicle',
    ShowOnlyNew = 'showOnlyNew',
    ShowOnlyNewResponses = 'showOnlyNewResponses',
    Order = 'order',
    SearchText = 'searchText',
}

export interface Range {
    from: string;
    to: string;
}

export interface SalaryRange extends Range {
    currencyCode: CurrencyType;
}

export interface University {
    id: string;
    text: string;
    acronym?: string;
}

export interface FilterValues {
    [FilterName.Age]: Range;
    [FilterName.Gender]: Gender;
    [FilterName.Areas]: string[];
    [FilterName.Relocation]: RelocationOption;
    [FilterName.Citizenship]: string[];
    [FilterName.Education]: EducationLevel;
    [FilterName.Universities]: University[];
    [FilterName.DriverLicenses]: DriverLicense[];
    [FilterName.Languages]: string[];
    [FilterName.Salary]: SalaryRange;
    [FilterName.Experience]: ExperienceOption[];
    [FilterName.Distance]: string;
    [FilterName.ShowOnlyWithVehicle]: boolean;
    [FilterName.ShowOnlyNew]: boolean;
    [FilterName.ShowOnlyNewResponses]: boolean;
    [FilterName.SearchText]: string;
    [FilterName.Order]: OrderOption | null;
}

export type GetFilterValueType<T extends FilterName> = FilterValues[T];
export type GetFilterValueSimpleType<T extends FilterName> = FilterValues[T] extends (infer I)[] ? I : FilterValues[T];

export type FilterStateMap = Partial<Record<FilterName, boolean>>;

export interface AvailableFilters {
    map: FilterStateMap;
    list: FilterName[];
}

export interface VacancyResponsesFilters {
    dicts: {
        genderTypes: Gender[];
        educationLevelTypes: EducationLevel[];
        experienceOptions: ExperienceOption[];
        relocationOptions: RelocationOption[];
        driverLicenseTypes: DriverLicense[];
    };
    initialValues: FilterValues;
    emptyValues: FilterValues;
    isLoading: boolean;
    isResettingFilters: boolean;
    isApplyingFilters: boolean;
    isQuerySynced: boolean;
    isDirtyDistanceFilter: boolean;
    activeFilters: FilterStateMap;
    availableFilters: AvailableFilters;
}

const RESPONSES_FILTERS_START_LOADING = 'RESPONSES_FILTERS_START_LOADING';
const RESPONSES_FILTERS_FINISH_LOADING = 'RESPONSES_FILTERS_FINISH_LOADING';
const RESPONSES_FILTERS_START_RESET = 'RESPONSES_FILTERS_START_RESET';
const RESPONSES_FILTERS_START_APPLY = 'RESPONSES_FILTERS_START_APPLY';
const RESPONSES_FILTERS_UPDATE_STATE = 'RESPONSES_FILTERS_UPDATE_STATE';
const MARK_RESPONSES_FILTERS_QUERY_AS_SYNCED = 'MARK_RESPONSES_FILTERS_QUERY_AS_SYNCED';

interface PayloadTypes {
    [RESPONSES_FILTERS_START_LOADING]: void;
    [RESPONSES_FILTERS_FINISH_LOADING]: void;
    [RESPONSES_FILTERS_START_RESET]: void;
    [RESPONSES_FILTERS_START_APPLY]: void;
    [RESPONSES_FILTERS_UPDATE_STATE]: Omit<VacancyResponsesFilters, 'dicts'>;
    [MARK_RESPONSES_FILTERS_QUERY_AS_SYNCED]: void;
}

const ActionCreator = ActionCreatorHelper<PayloadTypes>();

export const startLoading = ActionCreator(RESPONSES_FILTERS_START_LOADING);
export const finishLoading = ActionCreator(RESPONSES_FILTERS_FINISH_LOADING);
export const startReset = ActionCreator(RESPONSES_FILTERS_START_RESET);
export const startApply = ActionCreator(RESPONSES_FILTERS_START_APPLY);
export const updateState = ActionCreator(RESPONSES_FILTERS_UPDATE_STATE);
export const markQueryAsSynced = ActionCreator(MARK_RESPONSES_FILTERS_QUERY_AS_SYNCED);

const fallbackFilterValues: FilterValues = {
    [FilterName.Age]: { from: '', to: '' },
    [FilterName.Gender]: Gender.Unknown,
    [FilterName.Areas]: [],
    [FilterName.Relocation]: RelocationOption.LivingOrRelocation,
    [FilterName.Citizenship]: [],
    [FilterName.Education]: EducationLevel.None,
    [FilterName.Universities]: [],
    [FilterName.DriverLicenses]: [],
    [FilterName.Languages]: [],
    [FilterName.Salary]: { from: '', to: '', currencyCode: CurrencyType.RUR },
    [FilterName.Experience]: [],
    [FilterName.ShowOnlyWithVehicle]: false,
    [FilterName.ShowOnlyNew]: false,
    [FilterName.ShowOnlyNewResponses]: false,
    [FilterName.Order]: OrderOption.Relevance,
    [FilterName.SearchText]: '',
    [FilterName.Distance]: '',
};

const initialValues: VacancyResponsesFilters = {
    dicts: {
        genderTypes: [],
        educationLevelTypes: [],
        experienceOptions: [],
        relocationOptions: [],
        driverLicenseTypes: [],
    },
    initialValues: fallbackFilterValues,
    emptyValues: fallbackFilterValues,
    isLoading: false,
    isResettingFilters: false,
    isApplyingFilters: false,
    isQuerySynced: true,
    isDirtyDistanceFilter: false,
    activeFilters: {},
    availableFilters: {
        map: {},
        list: [],
    },
};

const vacancyResponsesFilters = createReducer<VacancyResponsesFilters, PayloadTypes>(initialValues, {
    [RESPONSES_FILTERS_START_LOADING]: (state) => ({ ...state, isLoading: true }),
    [RESPONSES_FILTERS_FINISH_LOADING]: (state) => ({
        ...state,
        isLoading: false,
        isResettingFilters: false,
        isApplyingFilters: false,
    }),
    [RESPONSES_FILTERS_START_RESET]: (state) => ({
        ...state,
        isLoading: true,
        isResettingFilters: true,
        isApplyingFilters: false,
    }),
    [RESPONSES_FILTERS_START_APPLY]: (state) => ({
        ...state,
        isLoading: true,
        isResettingFilters: false,
        isApplyingFilters: true,
    }),
    [RESPONSES_FILTERS_UPDATE_STATE]: (state, action) => ({ ...state, ...action.payload }),
    [MARK_RESPONSES_FILTERS_QUERY_AS_SYNCED]: (state) => ({ ...state, isQuerySynced: true }),
});

export { vacancyResponsesFilters, DriverLicense };
