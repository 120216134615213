import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { UpdateType } from 'src/components/IndexPageEmployer/components/Updates/constants';

const MARK_UPDATE_VIEWED = 'MARK_UPDATE_VIEWED';

interface EmployerUpdate {
    type: UpdateType;
    href: string;
    text: string;
    date?: string;
    viewed: boolean;
}

interface PayloadTypes {
    [MARK_UPDATE_VIEWED]: Pick<EmployerUpdate, 'href'>;
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();

export const markUpdateViewed = actionCreator(MARK_UPDATE_VIEWED);

const oldEmployerUpdates = createReducer<EmployerUpdate[], PayloadTypes>([], {
    [MARK_UPDATE_VIEWED]: (state, { payload }) =>
        state.map((update) =>
            update.href === payload.href
                ? {
                      ...update,
                      viewed: true,
                  }
                : update
        ),
});

export { oldEmployerUpdates };
