import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface ResumeComplaint {
    topicId: number;
    vacancyId: number;
    redirectUrl: string;
}

export type ResumeComplaintState = Record<string, ResumeComplaint>;

const resumesComplaint = autoGeneratedReducer<ResumeComplaintState>({});
export { resumesComplaint };
