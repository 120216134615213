import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

export enum ChangeEmailHoverTip {
    ContactMCP = 'ContactMCP',
    ContactSupport = 'ContactSupport',
    SudoNotAllowed = 'SudoNotAllowed',
    NotAllowed = 'NotAllowed',
}

export interface PasswordInfo {
    expirationTime: string;
    hasPassword: boolean;
    expired: boolean;
    invalidated: boolean;
    lastChangeTime: string;
    lastInvalidationDate: number;
}

interface EmployerSettingsPageState {
    email: string;
    newEmail: string;
    changeEmailHoverTip: null | ChangeEmailHoverTip;
    targetEmployerManagerId: string;
    changeEmailForMyself: boolean;
    isCurrentEmployerManagerId: boolean;
    passwordInfo?: PasswordInfo;
}

const initialState: EmployerSettingsPageState = {
    email: '',
    newEmail: '',
    changeEmailHoverTip: null,
    targetEmployerManagerId: '',
    changeEmailForMyself: true,
    isCurrentEmployerManagerId: true,
};

export const SET_NEW_EMAIL = 'SET_NEW_EMAIL';

interface PayloadTypes {
    [SET_NEW_EMAIL]: string;
}

const ActionCreator = ActionCreatorHelper<PayloadTypes>();

export const setNewEmail = ActionCreator(SET_NEW_EMAIL);

const employerSettingsPage = createReducer<EmployerSettingsPageState, PayloadTypes>(initialState, {
    [SET_NEW_EMAIL]: (state, action) => ({
        ...state,
        newEmail: action.payload,
    }),
});

export { employerSettingsPage };
