import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

export enum PfpLiquidityStatus {
    VacancyDeprioritized = 'VacancyDeprioritized',
    ManagerDeprioritized = 'ManagerDeprioritized',
    ManagerVacanciesHidden = 'ManagerVacanciesHidden',
    LowBalance = 'LowBalance',
}

export const PFP_LIQUIDITY_DEPRIORITIZATION_STATUSES = [
    PfpLiquidityStatus.ManagerDeprioritized,
    PfpLiquidityStatus.ManagerVacanciesHidden,
    PfpLiquidityStatus.VacancyDeprioritized,
];

export interface TopResponsesVacancies {
    id: number;
    name: string;
    areaName: string;
}

export type PfpLiquidityDeprioritizationStatus =
    | PfpLiquidityStatus.ManagerDeprioritized
    | PfpLiquidityStatus.ManagerVacanciesHidden
    | PfpLiquidityStatus.VacancyDeprioritized;

export interface PfpLiquidityVacanciesPageBannerData {
    status:
        | PfpLiquidityStatus.ManagerDeprioritized
        | PfpLiquidityStatus.ManagerVacanciesHidden
        | PfpLiquidityStatus.LowBalance;
    vacancies?: Array<TopResponsesVacancies> | undefined;
    responsesToBuy?: number;
}

const OPEN_INFO_MODAL = 'OPEN_INFO_MODAL';
const CLOSE_INFO_MODAL = 'CLOSE_INFO_MODAL';
const OPEN_VACANCIES_RESPONSES_TOP_MODAL = 'OPEN_VACANCIES_RESPONSES_TOP_MODAL';
const CLOSE_VACANCIES_RESPONSES_TOP_MODAL = 'CLOSE_VACANCIES_RESPONSES_TOP_MODAL';
interface PayloadTypes {
    [OPEN_INFO_MODAL]: void;
    [CLOSE_INFO_MODAL]: void;
    [OPEN_VACANCIES_RESPONSES_TOP_MODAL]: void;
    [CLOSE_VACANCIES_RESPONSES_TOP_MODAL]: void;
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();
export const openInfoModal = actionCreator(OPEN_INFO_MODAL);
export const closeInfoModal = actionCreator(CLOSE_INFO_MODAL);
export const openVacanciesResponsesTopModal = actionCreator(OPEN_VACANCIES_RESPONSES_TOP_MODAL);
export const closeVacanciesResponsesTopModal = actionCreator(CLOSE_VACANCIES_RESPONSES_TOP_MODAL);

export interface PpfpLiquidityWarning {
    status: PfpLiquidityStatus;
    vacancies: Array<TopResponsesVacancies> | undefined;
    responsesToBuy: number;
    infoModalVisible?: boolean;
    vacanciesResponsesTopModalVisible?: boolean;
}

const pfpLiquidityWarning = createReducer<PpfpLiquidityWarning, PayloadTypes>(null, {
    [OPEN_INFO_MODAL]: (state) => ({
        ...state,
        infoModalVisible: true,
    }),
    [CLOSE_INFO_MODAL]: (state) => ({
        ...state,
        infoModalVisible: false,
    }),
    [OPEN_VACANCIES_RESPONSES_TOP_MODAL]: (state) => ({
        ...state,
        vacanciesResponsesTopModalVisible: true,
    }),
    [CLOSE_VACANCIES_RESPONSES_TOP_MODAL]: (state) => ({
        ...state,
        vacanciesResponsesTopModalVisible: false,
    }),
});

export { pfpLiquidityWarning };
