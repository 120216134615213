import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import {
    ExpiringContactsResumeAccessesAdvice,
    ExpiringResumeAccessesAdvice,
    ExpiringVacanciesAdvice,
    LowActivityVacanciesAdvice,
    LowSalaryAdvice,
    ManagerUnreadResponsesAdvice,
    MCPManagerUnreadResponsesAdvice,
    NotVerifiedAdvice,
    OpenEmployerCongratulationAdvice,
    TrialExtensionAdvice,
    UnusedPublicationsAdvice,
    WaitActivationAdvice,
    WaitPaymentAdvice,
    WithoutReviewsAdvice,
} from 'src/models/employerAdvicesWidget/advices';

export type AdviceItem =
    | ExpiringVacanciesAdvice
    | ExpiringResumeAccessesAdvice
    | ExpiringContactsResumeAccessesAdvice
    | UnusedPublicationsAdvice
    | TrialExtensionAdvice
    | NotVerifiedAdvice
    | WaitPaymentAdvice
    | WaitActivationAdvice
    | LowActivityVacanciesAdvice
    | ManagerUnreadResponsesAdvice
    | MCPManagerUnreadResponsesAdvice
    | LowSalaryAdvice
    | WithoutReviewsAdvice
    | OpenEmployerCongratulationAdvice;

export interface EmployerAdviceWidget {
    advices: AdviceItem[];
}

const employerAdvicesWidget = autoGeneratedReducer<EmployerAdviceWidget>({ advices: [] });
export { employerAdvicesWidget };
