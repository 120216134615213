import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

const SET_RESUME_SEARCH_SEEN_ANALYTICS = 'SET_RESUME_SEARCH_SEEN_ANALYTICS';
const MARK_RESUME_SEARCH_SEEN_ANALYTICS_AS_SENT = 'MARK_RESUME_SEARCH_SEEN_ANALYTICS_AS_SENT';
const DELETE_RESUME_SEARCH_SEEN_ANALYTICS_OLD_DATA = 'DELETE_RESUME_SEARCH_SEEN_ANALYTICS_OLD_DATA';

const INITIAL_STATE = {};

export interface ResumeSearchSeenAnalytics {
    [key: string]: {
        lastSeenPosition: number;
        lastSeenId: string;
        globalAnalyticsParams: GlobalAnalyticsParams;
        wasSent?: true;
    };
}

interface PayloadTypes {
    [SET_RESUME_SEARCH_SEEN_ANALYTICS]: {
        searchRid: string;
        itemId: string;
    };
    [MARK_RESUME_SEARCH_SEEN_ANALYTICS_AS_SENT]: void;
    [DELETE_RESUME_SEARCH_SEEN_ANALYTICS_OLD_DATA]: void;
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();
export const resumeSearchSeenAnalyticsAction = actionCreator(SET_RESUME_SEARCH_SEEN_ANALYTICS);
export const resumeSearchSeenAnalyticsMarkAsSentAction = actionCreator(MARK_RESUME_SEARCH_SEEN_ANALYTICS_AS_SENT);
export const resumeSearchSeenAnalyticsDeleteOldDataAction = actionCreator(DELETE_RESUME_SEARCH_SEEN_ANALYTICS_OLD_DATA);

const resumeSearchSeenAnalytics = createReducer<ResumeSearchSeenAnalytics, PayloadTypes>(INITIAL_STATE, {
    [SET_RESUME_SEARCH_SEEN_ANALYTICS]: (oldState, action) => {
        const { searchRid, itemId } = action.payload;
        const globalAnalyticsParams = {
            ...window?.globalVars?.analyticsParams,
            originalRequestId: window?.globalVars?.requestId,
        };

        return {
            ...oldState,
            [searchRid]: {
                globalAnalyticsParams,
                lastSeenPosition: oldState[searchRid] ? oldState[searchRid].lastSeenPosition + 1 : 1,
                lastSeenId: itemId,
            },
        };
    },
    [MARK_RESUME_SEARCH_SEEN_ANALYTICS_AS_SENT]: (oldState) => {
        const newState: ResumeSearchSeenAnalytics = {};
        Object.entries(oldState).forEach(([searchRid, value]) => {
            newState[searchRid] = {
                ...value,
                wasSent: true,
            };
        });
        return newState;
    },
    [DELETE_RESUME_SEARCH_SEEN_ANALYTICS_OLD_DATA]: () => {
        return {};
    },
});

export { resumeSearchSeenAnalytics };
