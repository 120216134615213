import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface ApplicantEmployerPoliteness {
    allTopicCount: number;
    employerId: number;
    readTopicPercent: number;
    replyTotalWorkingTimeDays: number;
}

interface ApplicantEmployerPolitenessState {
    employerPolitenessIndexes: Record<number, ApplicantEmployerPoliteness>;
}

const applicantEmployerPoliteness = autoGeneratedReducer<ApplicantEmployerPolitenessState>({
    employerPolitenessIndexes: {},
});

export { applicantEmployerPoliteness };
