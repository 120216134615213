import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface ClusterGroup {
    value: string;
}

export interface AreaGroup extends ClusterGroup {
    priority: number;
    name: string;
    childrenAreas: AreaGroup[];
}

export interface EnrichClusterGroup extends ClusterGroup {
    text: string;
}

export interface ManagerName {
    firstName: string;
    lastName: string;
    middleName?: string;
}

export interface ManagerClusterGroup extends ClusterGroup {
    name: ManagerName;
}

export interface ManagerCluster {
    areaCluster: {
        clusterGroups: AreaGroup[];
        isComposite?: boolean;
    };
    employerManagerCluster: {
        clusterGroups: ManagerClusterGroup[];
        isComposite?: boolean;
    };
    premoderationStatusCluster: {
        clusterGroups: EnrichClusterGroup[];
    };
    vacancyDefiningPropertiesCluster: {
        clusterGroups: EnrichClusterGroup[];
    };
    vacancyPayForPerformanceCluster: {
        clusterGroups: EnrichClusterGroup[];
    };
    vacancyPublicationFormatCluster: {
        clusterGroups: EnrichClusterGroup[];
    };
    vacancyVisibilityCluster: {
        clusterGroups: EnrichClusterGroup[];
    };
    auctionStateCluster: {
        clusterGroups: EnrichClusterGroup[];
    };
    multiPostingStateCluster: {
        clusterGroups: EnrichClusterGroup[];
    };
}

const managerCluster = autoGeneratedReducer<Partial<ManagerCluster>>({});
export { managerCluster };
