import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface VrVacancy {
    vacancyId: number;
    name: string;
    responses: number;
}

const vrVacancies = autoGeneratedReducer<VrVacancy[]>(null);
export { vrVacancies };
