import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface EmployerManager {
    id: string;
    lastName: string;
    firstName: string;
    type: string;
}

const employerManager = autoGeneratedReducer<EmployerManager>(null);
export { employerManager };
