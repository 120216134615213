import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

interface VacancyResponseSummary {
    items: {
        id: string;
        name: string;
        value: {
            formatted: string;
            raw: {
                id: string;
                name: string;
                percentage?: number;
            }[];
        } | null;
    }[];
    isPurchased: boolean;
}

const vacancyResponseSummary = autoGeneratedReducer<VacancyResponseSummary>(null);
export { vacancyResponseSummary };
