import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { JobSearchStatusSetted } from 'src/models/applicantUserStatuses';
import { CurrencyType } from 'src/models/currencies.types';
import { DBAccessProductType } from 'src/models/price/product.types';
import { EducationLevel, Gender } from 'src/models/resume/resumeCommon.types';
import type { ResumeCardData } from 'src/models/resumeCard';
import { Fixes } from 'src/models/search/vacancy/result';
import { CriteriaKey, FilterExpPeriodOption, OrderByOption } from 'src/types/search/common/criteria';
import { Suggests } from 'src/types/search/common/suggests';
import { Criteria, SearchPeriodOption } from 'src/types/search/resume/criteria';

export const RESUME_SEARCH_RESULT_UPDATE_RESUME = 'RESUME_SEARCH_RESULT_UPDATE_RESUME';

export interface SearchResultStats {
    totalResults: number;
    totalUngroupedResults: number;
    normalSearchResults: number;
    normalGroupedSearchResults: number;
}

export enum SettingName {
    ShowFiltersOnTheRight = 'show_filters_on_the_right',
}

export interface ResumeSearchQueries {
    itemsOnPageBase: string;
    currentParams?: string;
    orderByBase: string;
    searchPeriodBase: string;
    noMagic?: string;
    highlight?: string;
    autosearch?: string;
}

export enum SettingType {
    LastChangeTime = 'last_change_time',
    Name = 'name',
    Photo = 'photo',
    LastExperience = 'last_experience',
    Comments = 'comments',
    CommentsExpanded = 'comments_expanded',
    Languages = 'languages',
    Area = 'area',
    Education = 'education',
    Citizenship = 'citizenship',
    Companies = 'companies',
    Relocation = 'relocation',
    Certificates = 'certificates',
    ExperienceSum = 'experience_sum',
    ProfessionalRole = 'professional_role',
    RoleWarring = 'roleWarring',
}

export enum Action {
    Apply = 'APPLY',
    Buy = 'BUY',
}

export interface ResumeSearchResult {
    searchCriteria?: {
        [`vacancy_id`]: number;
    };
    totalResults?: number;
    resumes: ResumeCardData[];
    criteria?: Criteria;
    videos?: Record<string, string>;
    paging: unknown;
    proxiedSearchFormParams?: Partial<Criteria>;
    queries?: ResumeSearchQueries;
    saveSearch?: Record<string, string>;
    settings: {
        activeSettings: SettingType[];
        availableSettings: SettingType[];
        filtersOnTheRight?: { name: SettingName.ShowFiltersOnTheRight; value: boolean; employerManagerId: string };
        resumeSearchViewClicked?: boolean;
    };
    clusters: unknown;
    hidden: number;
    total: number;
    fixes?: Fixes;
    applicantUserStatuses: Partial<
        Record<
            string,
            {
                jobSearchStatus: { name: JobSearchStatusSetted } | null;
            }
        >
    >;
    vacancySimilarBuyContacts?: {
        '@id'?: number;
        action?: string;
        areaId?: number;
        name?: string;
        '@code'?: string;
        serviceEntryCode?: string;
        daysLost?: string;
        product?: DBAccessProductType & { regionTrl: string };
    };
    isSimilarSearch?: boolean;
    stats: SearchResultStats;
    suggests?: Suggests;
    totalUsedFilters?: number;
    withHiddenResumes?: boolean;
}

interface PayloadTypes {
    [RESUME_SEARCH_RESULT_UPDATE_RESUME]: ResumeCardData;
}

const INITAL_STATE: ResumeSearchResult = {
    criteria: {
        [CriteriaKey.SearchPeriod]: SearchPeriodOption.AllTime,
        [CriteriaKey.OrderBy]: OrderByOption.Relevance,
        [CriteriaKey.ItemsOnPage]: '20',
        [CriteriaKey.DateFrom]: '',
        [CriteriaKey.DateTo]: '',
        [CriteriaKey.AgeFrom]: null,
        [CriteriaKey.AgeTo]: null,
        [CriteriaKey.District]: [],
        [CriteriaKey.CurrencyCode]: CurrencyType.RUR,
        [CriteriaKey.DriverLicenseTypes]: [],
        [CriteriaKey.Education]: EducationLevel.None,
        [CriteriaKey.EducationLevel]: [],
        [CriteriaKey.Employment]: [],
        [CriteriaKey.ExpCompanySize]: [],
        [CriteriaKey.ExpIndustry]: [],
        [CriteriaKey.Experience]: [],
        [CriteriaKey.FilterExpIndustry]: [],
        [CriteriaKey.FilterExpPeriodForIndustry]: FilterExpPeriodOption.AllTime,
        [CriteriaKey.Gender]: Gender.Unknown,
        [CriteriaKey.JobSearchStatus]: [],
        [CriteriaKey.Text]: [],
        [CriteriaKey.Label]: [],
        [CriteriaKey.Language]: [],
        [CriteriaKey.Metro]: [],
        [CriteriaKey.NoMagic]: false,
        [CriteriaKey.ProfessionalRole]: [],
        [CriteriaKey.SalaryFrom]: null,
        [CriteriaKey.SalaryTo]: null,
        [CriteriaKey.Schedule]: [],
        [CriteriaKey.VerifiedSkills]: [],
        [CriteriaKey.VerifiedLanguages]: [],
        [CriteriaKey.Skill]: [],
        [CriteriaKey.SavedSearchId]: null,
        [CriteriaKey.SearchSessionId]: null,
        [CriteriaKey.University]: [],
        [CriteriaKey.ExpPeriod]: [],
        [CriteriaKey.WorkTicket]: [],
    },
    paging: null,
    resumes: [],
    settings: { activeSettings: [], availableSettings: [] },
    clusters: {},
    hidden: 0,
    total: 0,
    applicantUserStatuses: {},
    vacancySimilarBuyContacts: {},
    stats: {
        totalResults: 0,
        totalUngroupedResults: 0,
        normalSearchResults: 0,
        normalGroupedSearchResults: 0,
    },
};

const actionCreator = ActionCreatorHelper<PayloadTypes>();
export const resumeSearchResultUpdateResume = actionCreator(RESUME_SEARCH_RESULT_UPDATE_RESUME);

const resumeSearchResult = createReducer<ResumeSearchResult, PayloadTypes>(INITAL_STATE, {
    [RESUME_SEARCH_RESULT_UPDATE_RESUME]: (state, action) => {
        const idx = state.resumes.findIndex((resume) => resume._attributes.id === action.payload._attributes.id);
        if (idx < 0) {
            return state;
        }

        const resumes = [...state.resumes];
        resumes.splice(idx, 1, action.payload);

        return {
            ...state,
            resumes,
        };
    },
});

export { resumeSearchResult };
