import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export enum JobStatisticsTab {
    Work = 'work',
    Lock = 'lock',
}

interface JobStatistics {
    employerId: string;
    disableDaysBeforeDate: string;
    quotaStatisticsAdmin: boolean;
    showNegotiationCostBanner: boolean;
}

const jobStatistics = autoGeneratedReducer<JobStatistics>({
    employerId: '',
    disableDaysBeforeDate: '',
    quotaStatisticsAdmin: false,
    showNegotiationCostBanner: false,
});

export { jobStatistics };
