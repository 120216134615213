import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export enum Platform {
    HeadHunter = 'HEADHUNTER',
    Zarplata = 'ZARPLATA',
}

export enum SiteLanguages {
    RU = 'RU',
    EN = 'EN',
    AZ = 'AZ',
    UA = 'UA',
    KZ = 'KZ',
    UZ = 'UZ',
    KG = 'KG',
}

export interface Locale {
    areaId: string;
    domainAreaId: string;
    host: string;
    protocolHost: string;
    isSeoDomain: boolean;
    platform: Platform;
    availableLanguages: [`${SiteLanguages}`];
    siteLanguage: SiteLanguages;
}

const locale = autoGeneratedReducer<Locale>({
    areaId: '',
    domainAreaId: '',
    host: '',
    protocolHost: '',
    isSeoDomain: false,
    platform: Platform.HeadHunter,
    availableLanguages: [SiteLanguages.RU],
    siteLanguage: SiteLanguages.RU,
});

export { locale };
