import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface EmployerServiceWidget {
    name: string;
    order: number;
    active: boolean;
}

const employerServicesWidget = autoGeneratedReducer<EmployerServiceWidget[]>([]);
export { employerServicesWidget };
