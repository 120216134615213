import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

interface VacancyDiscountPromoControlsInfo {
    visibility: boolean;
    extraPublicationsNeeded?: number;
}

const vacancyDiscountPromoControlsInfo = autoGeneratedReducer<VacancyDiscountPromoControlsInfo>({
    visibility: false,
});

export { vacancyDiscountPromoControlsInfo };
