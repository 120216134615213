import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { RelatedVacanciesType } from 'src/components/RelatedVacancies/relatedVacanciesTypes';
import { VacancySearchItem } from 'src/types/search/vacancy/card';

const PUSH_RELATED_VACANCIES = 'PUSH_RELATED_VACANCIES';

export interface RelatedVacancies {
    itemsOnPage?: number;
    resultsFound?: number;
    totalPages?: number;
    type?: RelatedVacanciesType;
    lastActivityTimeByHHID?: Record<string, { dt: string }>;
    vacancies: VacancySearchItem[];
    relatedVacancySimilar?: boolean;
}

const INITIAL_STATE: RelatedVacancies = {
    vacancies: [],
};

interface PayloadTypes {
    [PUSH_RELATED_VACANCIES]: VacancySearchItem[];
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();
export const pushRelatedVacanciesAction = actionCreator(PUSH_RELATED_VACANCIES);

const relatedVacancies = createReducer<RelatedVacancies, PayloadTypes>(INITIAL_STATE, {
    [PUSH_RELATED_VACANCIES]: (state, action) => ({
        ...state,
        vacancies: [...state.vacancies, ...(action.payload ?? [])],
    }),
});

export { relatedVacancies };
