import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { DistanceValues } from 'src/models/resume/resumeCommon.types';

interface Distance {
    code: DistanceValues;
    value: number;
}

export interface Distances {
    default: string;
    list: Distance[];
}

const distances = autoGeneratedReducer<Distances>(null);
export { distances };
