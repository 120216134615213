import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export enum CounterType {
    DV360 = 'dv360',
    RW = 'rw',
}

export interface Counter {
    id: number | string;
    type?: CounterType;
    uid?: number | string;
}

const counters = autoGeneratedReducer<Record<string, Counter[]>>({});
export { counters };
