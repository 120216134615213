import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

export type VrSurveyType = 'STANDARD' | 'WITH_SUITABLE_VACANCIES';
export const VR_OPERATION_TYPE = 'VR_SIGNUP_LOGIN';

const SET_VR_SIGNUP_LOGIN_FINGERPRINTS = 'SET_VR_SIGNUP_LOGIN_FINGERPRINTS';

interface PayloadTypes {
    [SET_VR_SIGNUP_LOGIN_FINGERPRINTS]: Record<string, unknown>;
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();

export const setVrSingUpLoginFingerprints = actionCreator(SET_VR_SIGNUP_LOGIN_FINGERPRINTS);

export interface VrSignupLogin {
    vrResponseHash: string;
    fingerprints: Record<string, unknown>;
    profRolesFromSurvey: number[];
    query: string;
    surveyType: VrSurveyType;
}

const initialState = {
    vrResponseHash: '',
    fingerprints: {},
    profRolesFromSurvey: [],
    query: '',
    surveyType: 'STANDARD' as VrSurveyType,
};

const vrSignupLogin = createReducer<VrSignupLogin, PayloadTypes>(initialState, {
    [SET_VR_SIGNUP_LOGIN_FINGERPRINTS]: (state, action) => {
        return { ...state, fingerprints: action.payload };
    },
});

export { vrSignupLogin };
