import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

export enum UserStat {
    ApplicantInvitations = 'applicant-invitations',
    NewApplicants = 'new-applicants',
    NewApplicantInvitations = 'new-applicant-invitations',
    NewResumesSavedSearch = 'new-resumes-saved-search',
    NewResumesViews = 'new-resumes-views',
    NewStatsTotal = 'new-stats-total',
    NewWannaWork = 'new-wanna-work',
    ResumesViews = 'resumes-views',
    WannaWork = 'wanna-work',
}

const INCREMENT_APPLICANT_INVITATIONS_COUNT = 'INCREMENT_APPLICANT_INVITATIONS_COUNT';

interface PayloadTypes {
    [INCREMENT_APPLICANT_INVITATIONS_COUNT]: number;
}

const ActionCreator = ActionCreatorHelper<PayloadTypes>();

export const incrementApplicantInvitationsCount = ActionCreator(INCREMENT_APPLICANT_INVITATIONS_COUNT);

type UserStats = Record<UserStat, number> | null;

const userStats = createReducer<UserStats, PayloadTypes>(null, {
    [INCREMENT_APPLICANT_INVITATIONS_COUNT]: (state, { payload }) => {
        if (state) {
            return {
                ...state,
                [UserStat.ApplicantInvitations]: state[UserStat.ApplicantInvitations] + payload,
            };
        }
        return state;
    },
});

export { userStats };
