import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { VacancySearchItem } from 'src/types/search/vacancy/card';

export const MIN_ANONYMOUS_SUITABLE_VACANCIES_COUNT = 5;
export const MIN_APPLICANT_SUITABLE_VACANCIES_COUNT = 1;

export interface SuitableVacancies {
    itemsOnPage: number;
    resultsFound: number;
    totalPages: number;
    lastActivityTimeByHHID: Record<string, { dt: string }>;
    vacancies: VacancySearchItem[];
    areaIds: string[];
}

const suitableVacancies = autoGeneratedReducer<SuitableVacancies>(null);
export { suitableVacancies };
