import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

export enum CompetencyResult {
    Success = 'SUCCESS',
    Failure = 'FAILURE',
}

export interface RsvUserCompetencies {
    isModalWindowOpened: boolean;
    competenciesResult: CompetencyResult | null;
}

const UPDATE_RSV_USER_COMPETENCIES_MODAL = 'UPDATE_RSV_USER_COMPETENCIES_MODAL';
const UPDATE_RSV_USER_COMPETENCIES_RESULT = 'UPDATE_RSV_USER_COMPETENCIES_RESULT';

interface PayloadTypes {
    [UPDATE_RSV_USER_COMPETENCIES_MODAL]: boolean;
    [UPDATE_RSV_USER_COMPETENCIES_RESULT]: CompetencyResult | null;
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();

export const updateRsvUserCompetenciesModal = actionCreator(UPDATE_RSV_USER_COMPETENCIES_MODAL);
export const updateRsvUserCompetenciesResult = actionCreator(UPDATE_RSV_USER_COMPETENCIES_RESULT);

const initialState: RsvUserCompetencies = {
    isModalWindowOpened: false,
    competenciesResult: null,
};

const rsvUserCompetencies = createReducer<RsvUserCompetencies, PayloadTypes>(initialState, {
    [UPDATE_RSV_USER_COMPETENCIES_MODAL]: (state, { payload }) => {
        return { ...state, isModalWindowOpened: payload };
    },
    [UPDATE_RSV_USER_COMPETENCIES_RESULT]: (state, { payload }) => {
        return { ...state, competenciesResult: payload };
    },
});

export { rsvUserCompetencies };
