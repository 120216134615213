import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { EmployerStateType, EmployerStateExtName } from 'src/models/negotiationTopic.types';

export interface NegotiationsHistoryItem {
    '@employerState': EmployerStateType;
    '@changeTime': number;
    '@employerStateExtName': EmployerStateExtName;
    '@senderApplicantUserId': number | null;
    '@senderEmployerManagerId': number | null;
    '@withMessage': boolean;
    '@senderFullName'?: string;
}

interface NegotiationsHistoryArea {
    id: number;
    name: string;
}

export interface NegotiationsHistory {
    '@archived': boolean;
    '@vacancyId': number;
    '@topicId': number;
    '@canEdit': boolean;
    '@name': string;
    items: NegotiationsHistoryItem[];
    area: NegotiationsHistoryArea;
}

const negotiationsHistory = autoGeneratedReducer<NegotiationsHistory[]>([]);
export { negotiationsHistory };
