import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';
import { DataProviderItem } from 'bloko/blocks/suggest/types';

export enum SupernovaSearchName {
    Employers = 'employersList',
    Resumes = 'resumeSearch',
    Vacancies = 'searchVacancy',
}

export const SupernovaSearchNameAnalyticsMapping = {
    [SupernovaSearchName.Employers]: 'employer_search_line',
    [SupernovaSearchName.Resumes]: 'resume_search_line',
    [SupernovaSearchName.Vacancies]: 'vacancy_search_line',
};

const supernovaSearchName = autoGeneratedReducer<SupernovaSearchName>(null);
export { supernovaSearchName };

export interface SupernovaSearchItem extends DataProviderItem {
    query?: string;
}
