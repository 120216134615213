import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import * as ResumeTypes from 'src/models/resume/resumeCommon.types';

export interface ResumeZp {
    zpId: string;
    creationTime?: string;
    lastModified?: string;
    birthDate: string;
    skills: string[];
    age: number;
    firstName: string;
    lastName: string;
    middleName: string;
    fullName: string;
    gender: ResumeTypes.Gender;
    email: string;
    title: string;
    about: string;
    salary: ResumeTypes.Salary;
    experiences?: Experience[];
    totalExperience: number;
    photoUrl: string;
    phone: ResumeTypes.Phone;
    educations: Education[];
    searchCity?: string;
    businessTripsReadiness?: TripsReadiness;
    workType?: WorkTypes;
    schedule?: Schedule;
}

export enum TripsReadiness {
    Ready = 'READY',
    NotReady = 'NOT_READY',
}

export interface Experience {
    startDate: string;
    endDate?: string;
    companyName: string;
    position: string;
    description: string;
    profAreas?: string[];
    employeesCount?: string;
    city?: string;
}

export interface Education {
    from: string;
    to: string;
    city?: string;
    form: string;
    institution: string;
    faculty?: string;
    speciality?: string;
}

export enum WorkTypes {
    FullDay = 'FULL_DAY',
    FlyInFlyOut = 'FLY_IN_FLY_OUT',
    TempOrFreelance = 'TEMP_JOB_OR_FREELANCE',
    Probation = 'PROBATION',
    PartTime = 'PART_TIME',
}

export enum Schedule {
    FullDay = 'FULL_DAY',
    Shift = 'SHIFT',
    Flexible = 'FLEXIBLE',
    Remote = 'REMOTE',
}

const resumeZp = autoGeneratedReducer<ResumeZp>(null);
export { resumeZp };
