import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export enum SocialNetworksCode {
    VK = 'VK',
    GPLUS = 'GPLUS',
    MAIL = 'MAIL',
    OK = 'OK',
    ESIA = 'ESIA',
    TinkoffBusiness = 'TINKOFF_BUSINESS',
}

export interface SocialNetworks {
    trl: string;
    url: string;
    code: SocialNetworksCode;
    translationKey: string;
}

interface LoginForm {
    passwordRecovery?: { trl: string };
    registration?: {
        trl: string;
        code: 'REG_APPLICANT' | 'REG_EMPLOYER';
        translationKey: string;
    }[];
    socialNetworks?: SocialNetworks[];
    registrationSocialNetworks?: SocialNetworks[];
}

const loginForm = autoGeneratedReducer<LoginForm>({});
export { loginForm };
