import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';
import { ModelData } from 'bloko/common/tree/types';

import { RegionsListAdditional } from 'src/components/Price/RegionSelectorPopup/RegionsList';
import { CurrencyType } from 'src/models/currencies.types';

import { PriceCountryCode } from 'src/models/price/priceCountryCode';

export enum PriceDataTab {
    Recommended = 'recommended',
    PayForPerformance = 'pay-for-performance',
    Dbaccess = 'dbaccess',
    AddContacts = 'add-contacts',
    RegionalPublications = 'regional-publications',
    Clickme = 'clickme',
    Branding = 'branding',
    EmployerBrand = 'employerbrand',
    Publications = 'publications',
    ZpPublications = 'zp-publications',
    ZpResumeAccess = 'zp-resume-access',
    ZpBundles = 'zp-bundles',
    ZpPromotion = 'zp-promotion',
    ZpPriceBackOffice = 'zp-price-backoffice',
    ProfrolesPublications = 'profroles-publications',
    CRMSystem = 'talantixcrm',
}

export interface PriceData {
    currency: CurrencyType;
    allPriceRegions?: ModelData<RegionsListAdditional>[];
    allProfRoleGroups?: ModelData[];
    priceCountryCode: PriceCountryCode;
    priceEmployerId?: string;
    priceRegionId: string;
    priceTabs: {
        items: PriceDataTab[];
        urls: Record<string, string>;
        activeTabName?: PriceDataTab;
    };
    customerStatus?: {
        maxDiscountPercent: number;
    };
    showDiscountEditor: boolean;
    isContactUsBlockEnabled: boolean;
    canBuyServices: boolean;
}

const priceData = autoGeneratedReducer<PriceData>(null);
export { priceData };
