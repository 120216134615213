import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import type { CountryCode } from 'src/models/countryId';

interface CountryInfo {
    areaId: number;
    countryCode: CountryCode;
    countryName: string;
    internationalPhonePrefix: string;
    nationalPhonePrefix: string;
}

export interface Country {
    code: CountryInfo['countryCode'];
    name: CountryInfo['countryName'];
}

const allowedCountriesForSmsRegistration = autoGeneratedReducer<CountryInfo[]>([]);
export { allowedCountriesForSmsRegistration };
