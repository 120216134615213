import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export enum HHATag {
    AreaNamePre = 'hha:areanamepre',
    Header = 'hha:header',
    Toggle = 'hha:toggle',
    Link = 'hha:link',
    Html = 'hha:html',
    XsFriendly = 'hha:xs-friendly',
    PageHeader = 'hha:page-header',
    Domain = 'hha:domain',
}

export interface HHAPortalData {
    placeName: string;
    tagXmlStr: string;
}

interface ArticleHHATags {
    hhaPortals: Array<HHAPortalData>;
    additionalData: {
        domain?: string;
    };
}

const articleHHATags = autoGeneratedReducer<ArticleHHATags>({
    hhaPortals: [],
    additionalData: {},
});

export { articleHHATags };
