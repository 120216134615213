import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';
import {
    CompensationFrequency,
    WorkingHours,
    WorkScheduleByDays,
    WorkFormat,
    EmploymentForm,
} from '@hh.ru/types-hh-microcore';

import { CriteriaKey, ExperienceOption, OrderByOption, PartTimeOption } from 'src/types/search/common/criteria';
import { EducationOption, ItemsOnPageOption, LabelOption, SearchPeriodOption } from 'src/types/search/vacancy/criteria';
import { EmploymentLower } from 'src/utils/constants/employment';
import { ScheduleCamelCase } from 'src/utils/constants/schedule';

import { SearchField } from 'src/models/search/vacancy/dictionaries';

enum Group {
    Area = 'AREA',
    NoGroupping = 'NO_GROUPING',
    WorkExperience = 'WORK_EXPERIENCE',
}

export interface StatisticGroup {
    id: Group;
    name: string;
    selected: boolean;
}

interface AdvancedVacancySearch {
    [CriteriaKey.Text]?: string;
    randomExampleNumber: 1 | 2 | 3 | 4 | 5;
    searchFields?: SearchField[];
    industrySelectedValues?: string[];
    [CriteriaKey.Salary]?: string;
    selectedValues: {
        [CriteriaKey.AcceptTemporary]?: boolean;
        [CriteriaKey.Education]?: EducationOption[];
        [CriteriaKey.Experience]?: ExperienceOption;
        [CriteriaKey.ExcludedText]?: string;
        [CriteriaKey.Employment]?: EmploymentLower[];
        [CriteriaKey.EmploymentForm]?: EmploymentForm[];
        [CriteriaKey.Schedule]?: ScheduleCamelCase[];
        [CriteriaKey.PartTime]?: PartTimeOption[];
        [CriteriaKey.Label]?: LabelOption[];
        [CriteriaKey.OrderBy]?: OrderByOption;
        [CriteriaKey.Industry]?: string[];
        [CriteriaKey.SearchPeriod]?: SearchPeriodOption;
        [CriteriaKey.ItemsOnPage]?: ItemsOnPageOption;
        [CriteriaKey.Salary]?: string;
        [CriteriaKey.SalaryFrequency]?: CompensationFrequency[];
        [CriteriaKey.Text]?: string;
        searchFields?: SearchField[];
        [CriteriaKey.OnlyWithSalary]?: boolean;
        [CriteriaKey.WorkFormat]?: WorkFormat[];
        [CriteriaKey.WorkingHours]?: WorkingHours[];
        [CriteriaKey.WorkScheduleByDays]?: WorkScheduleByDays[];
    };
    searchSettings?: {
        includeArchived: boolean;
        includeClosed: boolean;
        statisticGroups: StatisticGroup[];
    };
}

const advancedVacancySearch = autoGeneratedReducer<AdvancedVacancySearch>({
    [CriteriaKey.Text]: '',
    randomExampleNumber: 1,
    searchFields: [],
    selectedValues: {},
});

export { advancedVacancySearch };
