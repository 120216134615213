import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

const ADD_EMPLOYER_DIVISION = 'ADD_EMPLOYER_DIVISION';
const DELETE_EMPLOYER_DIVISION = 'DELETE_EMPLOYER_DIVISION';
const UPDATE_EMPLOYER_DIVISION = 'UPDATE_EMPLOYER_DIVISION';
const UPDATE_QUOTA_COUNTS = 'UPDATE_QUOTA_COUNTS';

export interface EmployerDivision {
    divisionId: number;
    divisionName: string;
    servicesInfo: {
        availableCiv: number;
        availableVacancies: number;
        services: {
            serviceId: number;
        }[];
    };
}

type EmployerDivisionState = Record<number, EmployerDivision>;

interface PayloadTypes {
    [ADD_EMPLOYER_DIVISION]: Record<number, EmployerDivision>;
    [UPDATE_EMPLOYER_DIVISION]: EmployerDivision;
    [UPDATE_QUOTA_COUNTS]: { divisionId: number; availableQuotas: Record<string, string> };
    [DELETE_EMPLOYER_DIVISION]: number;
}

const ActionCreator = ActionCreatorHelper<PayloadTypes>();

export const addEmployerDivision = ActionCreator(ADD_EMPLOYER_DIVISION);
export const updateEmployerDivision = ActionCreator(UPDATE_EMPLOYER_DIVISION);
export const updateQuotaCounts = ActionCreator(UPDATE_QUOTA_COUNTS);
export const deleteEmployerDivision = ActionCreator(DELETE_EMPLOYER_DIVISION);

const employerDivisions = createReducer<EmployerDivisionState, PayloadTypes>(
    {},
    {
        [ADD_EMPLOYER_DIVISION]: (state, { payload }) => ({ ...state, ...payload }),
        [UPDATE_EMPLOYER_DIVISION]: (state, { payload }) => ({
            ...state,
            [payload.divisionId]: { ...state[payload.divisionId], ...payload },
        }),
        [UPDATE_QUOTA_COUNTS]: (state, { payload }) => {
            const currentDivision = state[payload.divisionId];

            return {
                ...state,
                [payload.divisionId]: {
                    ...currentDivision,
                    servicesInfo: {
                        ...currentDivision.servicesInfo,
                        services: currentDivision.servicesInfo.services.map((service) => {
                            if (service.serviceId in payload.availableQuotas) {
                                return { ...service, availableQuotasCount: payload.availableQuotas[service.serviceId] };
                            }

                            return service;
                        }),
                    },
                },
            };
        },
        [DELETE_EMPLOYER_DIVISION]: (state, { payload: divisionId }) => {
            const { [divisionId]: deletedDivision, ...withoutDivision } = state;

            return withoutDivision;
        },
    }
);

export { employerDivisions };
