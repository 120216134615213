import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

const ADD_CHECKED_TOPIC = 'ADD_CHECKED_TOPIC';
const REMOVE_CHECKED_TOPIC = 'REMOVE_CHECKED_TOPIC';
const SET_CHECKED_TOPICS = 'SET_CHECKED_TOPICS';

interface PayloadTypes {
    [SET_CHECKED_TOPICS]: string[];
    [ADD_CHECKED_TOPIC]: string;
    [REMOVE_CHECKED_TOPIC]: string[];
}

const ActionCreator = ActionCreatorHelper<PayloadTypes>();

export const setCheckedTopics = ActionCreator(SET_CHECKED_TOPICS);
export const addCheckedTopic = ActionCreator(ADD_CHECKED_TOPIC);
export const removeCheckedTopic = ActionCreator(REMOVE_CHECKED_TOPIC);

const checkedTopics = createReducer<string[], PayloadTypes>([], {
    [SET_CHECKED_TOPICS]: (_state, { payload }) => payload,
    [ADD_CHECKED_TOPIC]: (state, { payload }) => [...state, payload],
    [REMOVE_CHECKED_TOPIC]: (state, { payload }) => state.filter((id) => !payload.includes(id)),
});

export { checkedTopics };
