import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { ActionType, FilterType } from 'src/models/employer/vacancyresponses/collections.types';

interface Candidates {
    collection: {
        actions: { type: ActionType }[];
        filters: { type: FilterType }[];
    };
}

const candidates = autoGeneratedReducer<Candidates>({ collection: { actions: [], filters: [] } });
export { candidates };
