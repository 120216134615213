import { autoGeneratedReducer, makeSetStoreField } from '@hh.ru/redux-create-reducer';

import { LoadingState } from 'src/models/loadingState';

export enum ComparisonResult {
    LOWER = 'LOWER',
    HIGHER = 'HIGHER',
}

export interface VacancyConversionStatistics {
    viewToResponseConversion: number;
    viewToResponseConversionComparison: {
        comparisonResult: ComparisonResult;
        value: number;
    } | null;
}

interface VacancyConversionStatisticsState {
    loadingState: LoadingState;
    data: VacancyConversionStatistics | null;
}

const INITIAL_STATE = {
    loadingState: LoadingState.None,
    data: null,
};

export const setVacancyConversionStatistics = makeSetStoreField('vacancyConversionStatistics');

const vacancyConversionStatistics = autoGeneratedReducer<VacancyConversionStatisticsState>(INITIAL_STATE);
export { vacancyConversionStatistics };
