import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { CurrencyType } from 'src/models/currencies.types';

export interface BrandingPromotion {
    hasBrandedTemplates: boolean;
    hasBrandingChameleonService: boolean;
    hasBrandingConstructorService: boolean;
    possibleToBuyBranding: boolean;
    minimalPrice?: { price: number; currency: CurrencyType };
}

export interface BrandingPromotionWithTemplate extends BrandingPromotion {
    isBrandingTemplateApplied?: boolean;
}

const brandingPromotion = autoGeneratedReducer<BrandingPromotion>(null);
export { brandingPromotion };
