import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { Criteria } from 'src/types/search/resume/criteria';

const REMOVE_SAVED_SEARCH = 'REMOVE_SAVED_SEARCH';

export interface BaseEntityWithTrl {
    id: string;
    text: string;
}

export interface SavedSearch {
    id: string;
    searchQuery: string;
    searchCriteria?: Omit<Criteria, 'area' | 'employment' | 'schedule'> & {
        area: BaseEntityWithTrl[];
        employment: BaseEntityWithTrl[];
        schedule: BaseEntityWithTrl[];
    };
    position?: number;
    text: string;
}

interface PayloadTypes {
    [REMOVE_SAVED_SEARCH]: Pick<SavedSearch, 'id'>;
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();

export const removeSavedSearch = actionCreator(REMOVE_SAVED_SEARCH);

const employerLastSearches = createReducer<SavedSearch[], PayloadTypes>([], {
    [REMOVE_SAVED_SEARCH]: (state, { payload }) => state.filter((savedSearch) => savedSearch.id !== payload.id),
});

export { employerLastSearches };
