import { ButtonName } from '@hh.ru/analytics-js-events/build/xhh/common/header_button_click';
import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { SupernovaModalType } from 'src/components/SupernovaMainMenu/SupernovaModals';

import { UserStat } from 'src/models/userStats';

export interface NavItemAnalytics {
    name: ButtonName;
    params?: Record<string, string | number>;
}

export interface NavItem {
    name: string;
    url: string;
    translations: {
        [x: string]: string;
    };
    params?: Array<{
        [x: string]: string;
    }>;
    analytics?: NavItemAnalytics;
    subItems?: NavItems;
    target?: 'blank';
    bgColor?: string;
    active?: boolean;
    dropdown?: boolean;
    external?: boolean;
    highlighted?: boolean;
    counter?: UserStat;
    badge?: string;
    trackClicks?: boolean;
    trackElementShown?: boolean;
    employer?: boolean;
    arrowed?: boolean;
    modalType?: SupernovaModalType;
}

export type NavItems = NavItem[];

export const footerReducer = autoGeneratedReducer<NavItems>([]);
const supernovaNaviMenu = autoGeneratedReducer<NavItems>([]);
export { supernovaNaviMenu };
