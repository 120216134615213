import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

export enum ResponseQuestion {
    WorkPlaceLocation = 'work_place_location',
    EmploymentAndWorkMode = 'employment_and_work_mode',
    IsVacancyOpen = 'is_vacancy_open',
    SalaryOptions = 'salary_options',
    HowToContact = 'how_to_contact',
    Other = 'other',
}

const SET_RESPONSE_QUESTION = 'SET_RESPONSE_QUESTION';
const INITIAL_STATE = {};

export interface ResponseQuestionItem {
    id: string;
    value: string;
}

interface PayloadTypes {
    [SET_RESPONSE_QUESTION]: Record<string, ResponseQuestionItem>;
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();
export const vacancyResponseQuestionsAction = actionCreator(SET_RESPONSE_QUESTION);

const vacancyResponseQuestions = createReducer<Record<string, ResponseQuestionItem>, PayloadTypes>(INITIAL_STATE, {
    [SET_RESPONSE_QUESTION]: ({ ...state }, action) => {
        return { ...state, ...action.payload };
    },
});

export { vacancyResponseQuestions };
