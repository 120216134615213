import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export enum AppLinkType {
    HhIosApplicant = 'hh-ios-applicant',
    HhAndroidApplicant = 'hh-android-applicant',
    HhHuaweiApplicant = 'hh-huawei-applicant',
    HhIosEmployer = 'hh-ios-employer',
    HhAndroidEmployer = 'hh-android-employer',
    HhHuaweiEmployer = 'hh-huawei-employer',
    JtbIosApplicant = 'jtb-ios-applicant',
    JtbAndroidApplicant = 'jtb-android-applicant',
    JtbIosEmployer = 'jtb-ios-employer',
    JtbAndroidEmployer = 'jtb-android-employer',
    ZpIosApplicant = 'zp-ios-applicant',
    ZpAndroidApplicant = 'zp-android-applicant',
    ZpHuaweiApplicant = 'zp-huawei-applicant',
}

export interface AppLink {
    detected: boolean;
    href: string;
    type: AppLinkType | '';
    name: string;
}

const appLink = autoGeneratedReducer<AppLink>({
    detected: false,
    href: '',
    type: '',
    name: '',
});

export { appLink };
