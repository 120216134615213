import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export enum DeviceTypes {
    Huawei = 'huawei',
    IOS = 'ios',
    Android = 'android',
    WinPhone = 'winphone',
}

export interface Device {
    type: DeviceTypes | undefined;
}

const device = autoGeneratedReducer<Device>({
    type: undefined,
});

export { device };
