import { FC } from 'react';

import { IconWrapperComponentSize24 } from '@hh.ru/magritte-ui-icon';
import { RUSize24, UZSize24, KZSize24, KGSize24, ENSize24, AZSize24 } from '@hh.ru/magritte-ui-icon/variants/country';

import { SiteLanguages } from 'src/models/locale';

const langIcons: Partial<Record<SiteLanguages, IconWrapperComponentSize24>> = {
    [SiteLanguages.RU]: RUSize24,
    [SiteLanguages.EN]: ENSize24,
    [SiteLanguages.AZ]: AZSize24,
    [SiteLanguages.KZ]: KZSize24,
    [SiteLanguages.UZ]: UZSize24,
    [SiteLanguages.KG]: KGSize24,
};

interface IconProps {
    lang: SiteLanguages;
}

const FlagIcon: FC<IconProps> = ({ lang }) => {
    const Icon = langIcons[lang] || null;
    return Icon && <Icon />;
};

export default FlagIcon;
