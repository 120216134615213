import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface EmployerInvoicePaylink {
    employerBudgetUrl: string;
    paylinkUrl: string;
    serviceTitles: string[];
}

const employerInvoicePaylink = autoGeneratedReducer<EmployerInvoicePaylink>(null);
export { employerInvoicePaylink };
