import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

interface ApplicantSettingsMenuItem {
    url: string;
    active?: boolean;
    name: string;
}

const applicantSettingsMenu = autoGeneratedReducer<ApplicantSettingsMenuItem[]>([]);
export { applicantSettingsMenu };
