import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

interface SearchDraft {
    count: number;
    isLoading: boolean;
}

const SET_SEARCH_DRAFT_COUNT = 'SET_SEARCH_DRAFT_COUNT';
const SET_SEARCH_DRAFT_LOADING = 'SET_SEARCH_DRAFT_LOADING';

interface PayloadTypes {
    [SET_SEARCH_DRAFT_COUNT]: number;
    [SET_SEARCH_DRAFT_LOADING]: boolean;
}

const actionHelper = ActionCreatorHelper<PayloadTypes>();

export const setSearchDraftCount = actionHelper(SET_SEARCH_DRAFT_COUNT);
export const setSearchDraftLoading = actionHelper(SET_SEARCH_DRAFT_LOADING);

const INITIAL_STATE: SearchDraft = { count: 0, isLoading: false };

const searchDraft = createReducer<SearchDraft, PayloadTypes>(INITIAL_STATE, {
    [SET_SEARCH_DRAFT_COUNT]: (state, { payload }) => ({ ...state, count: payload }),
    [SET_SEARCH_DRAFT_LOADING]: (state, { payload }) => ({ ...state, isLoading: payload }),
});

export { searchDraft };
