import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface AvailableVacancyActions {
    update?: boolean;
    toggleOption?: boolean;
    edit?: boolean;
    enableAutoProlongation?: boolean;
    disableAutoProlongation?: boolean;
    upgrade?: boolean;
    archive?: boolean;
    delete?: boolean;
    hide?: boolean;
    duplicate?: boolean;
    restore?: boolean;
    restoreToArchive?: boolean;
    print?: boolean;
}

const availableVacancyActions = autoGeneratedReducer<AvailableVacancyActions>({});
export { availableVacancyActions };
