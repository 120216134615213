import { ComponentProps, FC, PropsWithChildren } from 'react';

import {
    Card,
    Avatar,
    Badge,
    ComponentWithBadge,
    Cell,
    CellText,
    CellRightLabel,
    useBreakpoint,
} from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';

interface SupportActionProps {
    url?: string;
    external?: boolean;
    onClick?: () => void;
    'data-qa'?: string;
    icon: ComponentProps<typeof Avatar>['icon'];
    iconAriaLabel: string;
    right?: ComponentProps<typeof Cell>['right'];
    badgeCount?: number | null;
    CellTextSubtitleComponent?: FC;
}

const SupportAction: FC<SupportActionProps & PropsWithChildren> = ({
    url,
    onClick,
    external,
    'data-qa': dataQa,
    icon,
    iconAriaLabel,
    right,
    children,
    badgeCount,
    CellTextSubtitleComponent,
}) => {
    const { isMobile } = useBreakpoint();
    return (
        <ComponentWithBadge
            badge={!isMobile && badgeCount ? <Badge size="medium">{`${badgeCount}`}</Badge> : undefined}
            offset={6}
            stretched
        >
            <Card
                {...((url ? { Element: SPALink, to: url, target: external ? '_blank' : undefined } : {}) as Partial<
                    ComponentProps<typeof Card>
                >)}
                data-qa={dataQa}
                onClick={onClick}
                borderRadius={24}
                padding={12}
                stretched
                borderWidth="default"
            >
                <Cell
                    left={<Avatar size={48} mode="icon" icon={icon} style="secondary" aria-label={iconAriaLabel} />}
                    right={
                        right ||
                        (isMobile ? (
                            <CellRightLabel badge={badgeCount ? <Badge>{`${badgeCount}`}</Badge> : undefined} />
                        ) : undefined)
                    }
                >
                    <CellText>{children}</CellText>
                    {CellTextSubtitleComponent && <CellTextSubtitleComponent />}
                </Cell>
            </Card>
        </ComponentWithBadge>
    );
};

export default SupportAction;
