import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface AdfoxSearchParams {
    puid23: string;
    puid30: string;
}

export type AdsSearchParams = Partial<AdfoxSearchParams>;

const adsSearchParams = autoGeneratedReducer<AdsSearchParams>({});
export { adsSearchParams };
