import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

interface EmployerCompanyMenuItem {
    url: string;
    active: boolean;
    name: string;
}

const employerCompanyMenu = autoGeneratedReducer<EmployerCompanyMenuItem[]>([]);
export { employerCompanyMenu };
