import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface NegotiationStats {
    allTopicCount: number;
    index: {
        hint: string;
        readTopicMultiplier: number;
        replyTopicMultiplier: number;
        replyTopicTimeBonus: number;
        value: number;
    } | null;
    readTopicPercent: number;
    replyByManagerTotalWorkingTimeDays: number;
    replyTopicPercent: number;
}

interface ManagerNegotiationStats {
    employerIndex: NegotiationStats;
    employerManagerIndex: NegotiationStats;
    hasPolitenessIndex: boolean;
}

const managerNegotiationStats = autoGeneratedReducer<Partial<ManagerNegotiationStats>>({});
export { managerNegotiationStats };
