import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { VacancySearchItem } from 'src/types/search/vacancy/card';

interface VacanciesShortState {
    vacanciesList: VacancySearchItem[];
    total?: number;
}

const vacanciesShort = autoGeneratedReducer<VacanciesShortState>({
    vacanciesList: [],
    total: undefined,
});

export { vacanciesShort };
