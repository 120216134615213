import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface Folder {
    name?: string;
    shared?: boolean;
    selected?: boolean;
    resumes?: number;
}

export type FoldersGroupType = Partial<Record<number, Folder>>;

export interface FoldersInvariants {
    own?: FoldersGroupType;
    shared?: FoldersGroupType;
    trash?: FoldersGroupType;
    totalResumesCount?: number;
    searchFolderIds?: string[];
}

export interface TrashFolder {
    name?: string;
    selected?: boolean;
    resumes?: number;
    id?: string;
}

interface ResumeFolders {
    foldersInvariants: FoldersInvariants;
    foldersByResume: Partial<Record<string, string[]>>;
    searchUrl?: string;
    emptySearchUrl?: string;
}

const resumeFolders = autoGeneratedReducer<ResumeFolders>({
    foldersInvariants: {},
    foldersByResume: {},
});

export { resumeFolders };
