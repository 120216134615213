import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { ClickmeProductType } from 'src/models/price/product.types';

const SET_AVAILABLE_CLICKME_PRODUCTS_DATA = 'SET_AVAILABLE_CLICKME_PRODUCTS_DATA';
const SET_CLICKME_PRODUCTS_DATA_LOADING = 'SET_CLICKME_PRODUCTS_DATA_LOADING';
const SET_CLICKME_PRODUCTS_DATA_IS_LOADED = 'SET_CLICKME_PRODUCTS_DATA_IS_LOADED';

export const enum ProductName {
    BASIC = 'BASIC',
    STANDARD = 'STANDARD',
    MAXIMUM = 'MAXIMUM',
}

export interface Product {
    type: ProductName;
    productInfo: ClickmeProductType;
}

interface ClickmeProduct {
    available: boolean;
    products: Product[] | null;
}

export interface ClickmeProducts {
    autoCampaign: ClickmeProduct;
}

interface PayloadTypes {
    [SET_AVAILABLE_CLICKME_PRODUCTS_DATA]: ClickmeProducts;
    [SET_CLICKME_PRODUCTS_DATA_LOADING]: boolean;
    [SET_CLICKME_PRODUCTS_DATA_IS_LOADED]: boolean;
}

const ActionCreator = ActionCreatorHelper<PayloadTypes>();

export const setAvailableClickmeProductsData = ActionCreator(SET_AVAILABLE_CLICKME_PRODUCTS_DATA);
export const setClickmeProductsDataLoading = ActionCreator(SET_CLICKME_PRODUCTS_DATA_LOADING);
export const setClickmeProductsDataIsLoaded = ActionCreator(SET_CLICKME_PRODUCTS_DATA_IS_LOADED);

interface InitialState {
    loading: boolean;
    isLoaded: boolean;
    availableProducts: ClickmeProducts;
}

export const initialProducts = {
    autoCampaign: { available: false, products: null },
};

export const INITIAL_STATE: InitialState = {
    loading: false,
    isLoaded: false,
    availableProducts: {
        ...initialProducts,
    },
};

const clickmeProducts = createReducer<InitialState, PayloadTypes>(INITIAL_STATE, {
    [SET_AVAILABLE_CLICKME_PRODUCTS_DATA]: (state, { payload }) => ({
        ...state,
        availableProducts: payload,
    }),
    [SET_CLICKME_PRODUCTS_DATA_LOADING]: (state, { payload }) => ({
        ...state,
        loading: payload,
    }),
    [SET_CLICKME_PRODUCTS_DATA_IS_LOADED]: (state, { payload }) => ({
        ...state,
        isLoaded: payload,
    }),
});

export { clickmeProducts };
