import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

export enum VacancyViewTab {
    Main = 'main',
    Stat = 'stat',
    History = 'history',
    Managers = 'managers',
    AIAssistant = 'aiAssistant',
}

interface VacancyViewTabState {
    active: VacancyViewTab;
    tabs: VacancyViewTab[];
}

const SET_VACANCY_VIEW_ACTIVE_TAB = 'SET_VACANCY_VIEW_ACTIVE_TAB';

interface PayloadTypes {
    [SET_VACANCY_VIEW_ACTIVE_TAB]: {
        active: VacancyViewTab;
    };
}

const INITIAL_STATE: VacancyViewTabState = {
    active: VacancyViewTab.Main,
    tabs: [],
};

const actionCreator = ActionCreatorHelper<PayloadTypes>();

export const setVacancyViewActiveTab = actionCreator(SET_VACANCY_VIEW_ACTIVE_TAB);

const vacancyViewTab = createReducer<VacancyViewTabState, PayloadTypes>(INITIAL_STATE, {
    [SET_VACANCY_VIEW_ACTIVE_TAB]: (state, action) => {
        const { active } = action.payload;
        return {
            ...state,
            active,
        };
    },
});

export { vacancyViewTab };
