import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface LinkCheckResult {
    status: 'UNSAFE';
    info: {
        title: string;
        description: string;
        imageUrl?: string;
        link?: {
            title: string;
            url: string;
        };
    };
}

export interface LinkCheck {
    targetUrl: string;
    result?: LinkCheckResult;
}

const linkCheck = autoGeneratedReducer<LinkCheck>({ targetUrl: '' });
export { linkCheck };
