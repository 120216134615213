import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface Test {
    uidPk: number;
    guid: string;
    commonTestId?: number;
    userTestId?: number;
    vacanciesCount?: number;

    categoryIds?: number[];
    currentCategory?: CurrentCategory;

    isCommon: boolean;
    copyable?: boolean;
    modifiable: boolean;
    canDelete: boolean;
    canCopy?: boolean;
    overt: boolean;
    noCorrectAnswer: boolean;

    name: string;
    description: string;
    extraDescription?: string;
    extraInfo?: string;
    author?: string;
    url?: string;

    tasks: TestQuestion[];
}

export interface TestQuestion {
    uidPk: number;
    description: string;
    open: boolean;
    multiple: boolean;
    candidateSolutions: TestAnswer[];
}

export interface TestAnswer {
    uidPk: number;
    value: string;
    correct: boolean;
}

export interface CurrentCategory {
    id: number;
    name: string;
    trl: string;
    testsCount: number;
}

const employerTest = autoGeneratedReducer<Test>(null);
export { employerTest };
