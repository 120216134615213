import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { ManagerType } from 'src/models/employerManagers.types';

export enum ImplantType {
    Unknown = 'UNKNOWN',
    StaffMember = 'STAFF_MEMBER',
    Freelancer = 'FREELANCER',
}

export enum MultiManagerPermissionName {
    ActiveVacancies = 'activeVacancies',
    ArchiveVacancies = 'archiveVacancies',
    EmployerManagerControl = 'employerManagerControl',
}

export type ManagerTypes = Exclude<ManagerType, ManagerType.Mcp>[];

export enum PermissionName {
    AssignQuotas = 'assignQuotas',
    BuyServicesCreateOrdinaryBill = 'buyServicesCreateOrdinaryBill',
    ChangeEmail = 'changeEmail',
    ChangeVacancyAfterModeration = 'changeVacancyAfterModeration',
    ContactApplicant = 'contactApplicant',
    CreateEmployerHiringManager = 'createEmployerHiringManager',
    CreateVacancy = 'createVacancy',
    EmployerDescEdit = 'employerDescEdit',
    ViewDesirableCompensation = 'viewDesirableCompensation',
    ViewEmployerStatistics = 'viewEmployerStatistics',
    ViewResumeContacts = 'viewResumeContacts',
}

export interface Permission {
    name: PermissionName;
    text: string;
    active: boolean;
    invertForManagerTypes?: ManagerTypes;
    managerTypes: ManagerTypes | null;
}

interface MultiManagerPermission {
    name: string;
    text: string;
    active: boolean;
    managerIds: string[];
}

interface ModerationData {
    premoderatorId?: number;
    canPostVacancyImmediately: boolean;
    managerTypesForModeration: ManagerType[];
    moderatedManagers: { id: number; fullName: string }[];
}

export interface Phone {
    country: string;
    city: string;
    number: string;
    full: string;
    comment: string;
}

interface EmployerManagerPersonalData {
    currentManagerType: ManagerType;
    currentImplantType?: ImplantType;
    managerTypes: {
        name: ManagerType;
        text: string;
        description: string;
        active: boolean;
    }[];
    permissions: Permission[];
    multiManagerPermissions: MultiManagerPermission[];
    email?: string;
    specialNote1?: string;
    specialNote2?: string;
    moderationData: ModerationData | null;
    position?: string;
    phone?: Phone;
    additionalPhone?: Phone;
    implantTypes: {
        name: ImplantType;
        text: string;
    }[];
    firstName?: string;
    lastName?: string;
    middleName?: string;
    areaId?: string;
    employerId?: number;
}

const CHANGE_EMAIL = 'CHANGE_EMAIL';

interface PayloadTypes {
    [CHANGE_EMAIL]: string;
}

const ActionCreator = ActionCreatorHelper<PayloadTypes>();

export const changeEmail = ActionCreator(CHANGE_EMAIL);

const initialState = {
    currentManagerType: ManagerType.Manager,
    managerTypes: [],
    permissions: [],
    multiManagerPermissions: [],
    implantTypes: [],
    moderationData: {
        canPostVacancyImmediately: false,
        managerTypesForModeration: [],
        moderatedManagers: [],
    },
};

const employerManagerPersonalData = createReducer<EmployerManagerPersonalData, PayloadTypes>(initialState, {
    [CHANGE_EMAIL]: (state, action) => ({
        ...state,
        email: action.payload,
    }),
});

export { employerManagerPersonalData };
