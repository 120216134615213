import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { OrderedClusterMap, SEARCH_CLUSTERS_ORDER_INITIAL_STATE } from 'src/models/search/common/clustersOrder';

const SET_SEARCH_DRAFT_CLUSTERS_ORDER = 'SET_SEARCH_DRAFT_CLUSTERS_ORDER';
const RESET_SEARCH_DRAFT_CLUSTERS_ORDER = 'RESET_SEARCH_DRAFT_CLUSTERS_ORDER';

interface PayloadTypes {
    [SET_SEARCH_DRAFT_CLUSTERS_ORDER]: Partial<OrderedClusterMap>;
    [RESET_SEARCH_DRAFT_CLUSTERS_ORDER]: void;
}

const actionHelper = ActionCreatorHelper<PayloadTypes>();

export const setSearchDraftClustersOrder = actionHelper(SET_SEARCH_DRAFT_CLUSTERS_ORDER);
export const resetSearchDraftClustersOrder = actionHelper(RESET_SEARCH_DRAFT_CLUSTERS_ORDER);

const searchDraftClustersOrder = createReducer<OrderedClusterMap, PayloadTypes>(SEARCH_CLUSTERS_ORDER_INITIAL_STATE, {
    [SET_SEARCH_DRAFT_CLUSTERS_ORDER]: (state, { payload }) => ({ ...state, ...payload }),
    [RESET_SEARCH_DRAFT_CLUSTERS_ORDER]: () => ({ ...SEARCH_CLUSTERS_ORDER_INITIAL_STATE }),
});

export { searchDraftClustersOrder };
