import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

export interface ChatikFloatActivatorState {
    disabled?: boolean;
    fixed?: boolean;
    shift?: number;
}

const SHIFT_ACTIVATOR = 'SHIFT_ACTIVATOR';
const RESET_ACTIVATOR_SHIFT = 'RESET_ACTIVATOR_SHIFT';

interface PayloadTypes {
    [SHIFT_ACTIVATOR]: { shift: number };
    [RESET_ACTIVATOR_SHIFT]: void;
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();

export const shiftChatikActivator = actionCreator(SHIFT_ACTIVATOR);
export const resetChatikActivatorShift = actionCreator(RESET_ACTIVATOR_SHIFT);

const INITIAL_STATE: ChatikFloatActivatorState = { disabled: false, fixed: false, shift: 0 };

const chatikFloatActivator = createReducer<ChatikFloatActivatorState, PayloadTypes>(INITIAL_STATE, {
    [SHIFT_ACTIVATOR]: (state, action) => ({ ...state, shift: action.payload.shift }),
    [RESET_ACTIVATOR_SHIFT]: (state) => ({ ...state, shift: 0 }),
});

export { chatikFloatActivator };
