import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface VacanciesWidgetVacancy {
    id: number;
    type: 'active' | 'draft' | 'archive';
    title: string;
    area?: {
        id: number;
        name: string;
    };
    expireTime?: number;
    isProlongateHighlight?: boolean;
    unread?: {
        responses?: number;
        messages?: number;
    };
}

interface EmployerVacanciesWidget {
    activeVacanciesAmount: number;
    moreResponses?: {
        responses: number;
        vacancies: number;
    };
    vacancies: VacanciesWidgetVacancy[];
}

const vacanciesWidget = autoGeneratedReducer<EmployerVacanciesWidget>({
    activeVacanciesAmount: 0,
    vacancies: [],
});

export { vacanciesWidget };
