import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { CommentsOwner } from 'src/components/ResumeComments/types';

export const ADD_COMMENT_TO_USER = 'ADD_COMMENT_TO_USER';
export const ADD_BATCH_COMMENTS_TO_USER = 'ADD_BATCH_COMMENTS_TO_USER';

enum Action {
    AddCommentToUser = 'ADD_COMMENT_TO_USER',
    DeleteCommentFromUser = 'DELETE_COMMENT_FROM_USER',
    AddBatchCommentsToUser = 'ADD_BATCH_COMMENTS_TO_USER',
    UpdateCommentsByUserId = 'UPDATE_COMMENTS_BY_USER_ID',
}

export interface ResumeComments {
    items: number[];
    total: number;
    maxOffset?: number;
}
export type ResumeCommentsState = Record<string, ResumeComments>;

interface PayloadTypes {
    [Action.AddCommentToUser]: { userId: CommentsOwner; commentId: number };
    [Action.AddBatchCommentsToUser]: { userId: CommentsOwner; commentIds: number[]; total: number; maxOffset: number };
    [Action.UpdateCommentsByUserId]: ResumeCommentsState;
    [Action.DeleteCommentFromUser]: { userId: CommentsOwner; commentId: number };
}

const actionCreatorHelper = ActionCreatorHelper<PayloadTypes>();

export const addCommentToUser = actionCreatorHelper(Action.AddCommentToUser);
export const deleteCommentFromUser = actionCreatorHelper(Action.DeleteCommentFromUser);
export const addBatchCommentsToUser = actionCreatorHelper(Action.AddBatchCommentsToUser);
export const updateComments = actionCreatorHelper(Action.UpdateCommentsByUserId);

const INITIAL_STATE = {};

const getComments = (state: ResumeCommentsState, userId: CommentsOwner) => {
    return (
        state[userId] || {
            items: [],
            total: 0,
        }
    );
};

const commentsByUserId = createReducer<ResumeCommentsState, PayloadTypes>(INITIAL_STATE, {
    [Action.AddCommentToUser]: (state, { payload: { userId, commentId } }) => {
        const comments = getComments(state, userId);

        return {
            ...state,
            [userId]: {
                items: [...new Set([commentId, ...comments.items])],
                total: comments.total + 1,
            },
        };
    },
    [Action.DeleteCommentFromUser]: (state, { payload: { userId, commentId } }) => {
        const comments = getComments(state, userId);

        return {
            ...state,
            [userId]: {
                items: comments.items.filter((itemId) => itemId !== commentId),
                total: comments.total - 1,
            },
        };
    },
    [Action.AddBatchCommentsToUser]: (state, { payload: { userId, commentIds, total, maxOffset } }) => {
        const comments = getComments(state, userId);

        return {
            ...state,
            [userId]: {
                items: [...new Set([...comments.items, ...commentIds])],
                total,
                maxOffset,
            },
        };
    },
    [Action.UpdateCommentsByUserId]: (state, { payload }) => ({
        ...state,
        ...payload,
    }),
});

export { commentsByUserId };
