// https://wiki.hh.ru/pages/viewpage.action?pageId=349800423
enum CartStatus {
    Activated = 'activated',
    Error = 'error',
    InsufficientFunds = 'insufficient-funds',
    InvalidService = 'invalid-service',
    Irrelevant = 'irrelevant',
    New = 'new',
    ReadyToActivate = 'ready2activate',
    UnableToActivate = 'unableToActivate',
    WaitingForPayment = 'waiting4payment',
}

export { CartStatus };
