import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { TopicAction } from 'src/models/applicantNegotiationTopic.types';

export enum ApplicantNegotiationStatus {
    Discard = 'DISCARD',
    Invitation = 'INVITATION',
    Read = 'READ',
    Unread = 'UNREAD',
    OnlyNew = 'ONLY_NEW',
    All = 'ALL',
    Interview = 'INTERVIEW',
    Hired = 'HIRED',
}

interface ApplicantNegotiationsActionsDataState {
    deleteAction?: TopicAction;
}

const applicantNegotiationsActionsData = autoGeneratedReducer<ApplicantNegotiationsActionsDataState>({
    deleteAction: undefined,
});

export { applicantNegotiationsActionsData };

export enum ApplicantNegotiationFiltersStates {
    All = 'all',
    Awaiting = 'awaiting',
    Invitation = 'invitation',
    Discard = 'discard',
    Deleted = 'deleted',
    Archived = 'archived',
    Interview = 'interview',
    Hired = 'hired',
}
