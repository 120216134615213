import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { StateId } from 'src/models/employerNegotiations/stateId.types';

export enum TransitionType {
    ChangeTopic = 'changeTopic',
    Invite = 'invite',
    InviteAnother = 'inviteAnother',
    InviteWithAssessements = 'inviteWithAssessements',
    Consider = 'consider',
    Discard = 'discard',
    Complaint = 'complaint',
    Mail = 'mail',
}

export interface NegotiationLink {
    id: string;
    state: StateId;
    text: string;
    url: string | VoidFunction;
    substates?: NegotiationLink[];
}

interface NegotiationLinks {
    stateLinks: NegotiationLink[];
    defaultLink: string;
}

export type NegotiationLinksMap = Record<TransitionType, NegotiationLinks>;

const employerNegotiationLinks = autoGeneratedReducer<Record<string, NegotiationLinksMap>>({});
export { employerNegotiationLinks };
