import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { LangsList } from 'src/models/resume/resumeCommon.types';

export interface UserTargeting {
    userId?: number;
    siteId?: number;
    lang?: LangsList;
    domainAreaId?: number;
}

const userTargeting = autoGeneratedReducer<UserTargeting>({});
export { userTargeting };
