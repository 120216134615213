import { FocusEventHandler, KeyboardEventHandler, useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import Analytics from '@hh.ru/analytics-js';
import { useBreakpoint, SearchInput } from '@hh.ru/magritte-ui';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import paths from 'src/app/routePaths';
import { INPUT_ID } from 'src/components/A11y/A11yConstants';
import SuggestWithDefaultErrorPlaceholder from 'src/components/SuggestWithDefaultErrorPlaceholder';
import useSendSuggestAnalytics from 'src/components/SupernovaSearch/SmartSuggest/useSendAnalytics';
import useSearchInputValue from 'src/components/SupernovaSearch/useSearchInputValue';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import useSetStateWithCallback from 'src/hooks/useSetStateWithCallback';
import { SupernovaSearchName, SupernovaSearchNameAnalyticsMapping } from 'src/models/supernovaSearchName';
import { UserType } from 'src/models/userType';

import { ComponentProps, SuggestItem } from 'src/components/SupernovaSearch/SearchSuggest/types';
import useProvider from 'src/components/SupernovaSearch/SearchSuggest/useProvider';

const TrlKeys = {
    submit: 'supernova.search.submit',
    tooLongQuery: 'query.length.moreThanMax',
    [`placeholder.${SupernovaSearchName.Resumes}`]: 'supernova.search.placeholder.resumeSearch',
    [`placeholder.${SupernovaSearchName.Vacancies}`]: 'supernova.search.placeholder.searchVacancy',
    [`placeholder.${SupernovaSearchName.Vacancies}.mobile`]: 'supernova.search.placeholder.searchVacancy.mobile',
    [`placeholder.${SupernovaSearchName.Vacancies}.mobile.long`]:
        'supernova.search.placeholder.searchVacancy.mobile.long',
    [`placeholder.${SupernovaSearchName.Employers}`]: 'supernova.search.placeholder.employersList',
    mobilePlaceholder: 'supernova.search.mobile.placeholder',
    submitEmployers: 'supernovaSearch.bottomSheet.title.employers',
    submitVacancies: 'supernovaSearch.bottomSheet.title.vacancies',
    submitResumes: 'supernovaSearch.bottomSheet.title.resumes',
};

const criteriaTextUpdatedAction = makeSetStoreField('criteriaTextUpdated');

const MAX_QUERY_LENGTH = 3000;

const SearchSuggest: TranslatedComponent<ComponentProps> = ({
    trls,
    searchName,
    isDashboardMobileSearch,
    submitForm,
}) => {
    const dispatch = useDispatch();
    const [fromSuggest, setFromSuggest] = useState(false);
    const searchValue = useSearchInputValue(searchName);
    const isMultiText = Array.isArray(searchValue);
    const defaultValue = isMultiText ? searchValue[0] : searchValue;
    const [queryText, setQueryText] = useSetStateWithCallback<string>(defaultValue || '');
    const inputRef = useRef<HTMLElement>(null);
    const dataProvider = useProvider(searchName);
    const { sendSuggestItemClickAnalytics, sendSuggestShownAnalytics } = useSendSuggestAnalytics();
    const userType = useSelector(({ userType }) => userType);
    const isEmployer = userType === UserType.Employer;
    const isResumeSearchPage = useSelector(({ router }) => router.location.pathname === paths.resumeSearch);
    const fromLocation = useSelector(({ router }) => router.location.query.from);
    const { isMobile } = useBreakpoint();
    const isAnonymousMagritteExp = useSelector((state) => state.isAnonymousMagritteExp);
    const isAnonymousMagritteExpD = useSelector((state) => state.isAnonymousMagritteExpD);

    useEffect(() => {
        // Подставляем значение в строку поиска при spa-переходах.
        const isMultiText = Array.isArray(searchValue);

        dispatch(criteriaTextUpdatedAction(searchValue));
        setQueryText(isMultiText ? searchValue[0] : searchValue);
    }, [dispatch, searchValue, setQueryText]);

    useEffect(() => {
        if (isEmployer && isResumeSearchPage && !isMobile && fromLocation === 'header-menu') {
            inputRef?.current?.focus();
        }
    }, [isEmployer, isResumeSearchPage, isMobile, fromLocation]);

    const updateText = useCallback(
        (text: string, submit?: () => void) => {
            let newCriteriaTextUpdated: string | string[] = text;
            // on resume search can be []
            if (Array.isArray(searchValue)) {
                newCriteriaTextUpdated = [...searchValue];
                newCriteriaTextUpdated[0] = text;
            }
            dispatch(criteriaTextUpdatedAction(newCriteriaTextUpdated));
            setQueryText(text, submit);
        },
        [dispatch, searchValue, setQueryText]
    );

    const handleSuggestChange = useCallback(
        (item: SuggestItem) => {
            setFromSuggest(true);

            if (item) {
                updateText(item.text, submitForm);
            }
        },
        [submitForm, updateText]
    );

    const handleItemSelect = useCallback(
        (query: string, item?: SuggestItem): boolean => {
            if (item) {
                sendSuggestItemClickAnalytics({
                    id: item.id,
                    text: item.text,
                    query,
                });

                handleSuggestChange(item);
            }

            return true;
        },
        [sendSuggestItemClickAnalytics, handleSuggestChange]
    );

    const onFocusInput: FocusEventHandler<HTMLInputElement> = useCallback(() => {
        Analytics.sendHHEvent('form_field_touch', {
            formName: SupernovaSearchNameAnalyticsMapping[searchName],
            fieldName: 'search_line',
        });
    }, [searchName]);

    const onSubmitInput: KeyboardEventHandler<HTMLInputElement> = useCallback(
        (event) => {
            // Проверяем на isMobile, чтобы в десктопной версии не вызывать триггер формы дважды.
            // Там срабатывает нативный сабмит внутри формы.
            if (event.key === 'Enter' && isMobile) {
                submitForm();
            }
        },
        [isMobile, submitForm]
    );

    const inputLabel = useMemo(() => {
        if (searchName === SupernovaSearchName.Employers) {
            return trls[TrlKeys.submitEmployers];
        } else if (searchName === SupernovaSearchName.Vacancies) {
            return trls[TrlKeys.submitVacancies];
        } else if (searchName === SupernovaSearchName.Resumes) {
            return trls[TrlKeys.submitResumes];
        }
        return trls[TrlKeys.submit];
    }, [searchName, trls]);

    const inputProps = useMemo(() => {
        const inputName = isDashboardMobileSearch || searchName !== SupernovaSearchName.Employers ? 'text' : 'query';
        let placeholder = trls[TrlKeys[`placeholder.${searchName}`]];
        if (isMobile && isAnonymousMagritteExp && searchName === SupernovaSearchName.Vacancies) {
            placeholder = trls[TrlKeys[`placeholder.${searchName}.mobile`]];
            if (isAnonymousMagritteExpD) {
                placeholder = trls[TrlKeys[`placeholder.${searchName}.mobile.long`]];
            }
        }

        return {
            id: INPUT_ID,
            name: inputName,
            value: queryText,
            placeholder,
            onChange: updateText,
            onFocus: onFocusInput,
            onKeyDown: onSubmitInput,
            clearable: true,
            maxLength: MAX_QUERY_LENGTH,
            autoComplete: 'off',
            'aria-label': inputLabel,
            'data-qa': 'search-input',
        };
    }, [
        isDashboardMobileSearch,
        searchName,
        trls,
        isMobile,
        isAnonymousMagritteExp,
        queryText,
        updateText,
        onFocusInput,
        onSubmitInput,
        inputLabel,
        isAnonymousMagritteExpD,
    ]);

    return (
        <>
            <SuggestWithDefaultErrorPlaceholder
                inputValue={queryText}
                onSelectValidator={handleItemSelect}
                dataProvider={dataProvider}
                input={{
                    component: SearchInput,
                    props: inputProps,
                }}
                navigationBarProps={{
                    title: inputLabel,
                }}
                onDropOpen={sendSuggestShownAnalytics}
                onBottomSheetOpen={sendSuggestShownAnalytics}
                ref={inputRef}
            />
            {fromSuggest && <input type="hidden" name="from" value="suggest_post" />}
        </>
    );
};

export default translation(SearchSuggest);
