import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { AdviceDefaultType, AdviceType } from 'src/utils/employer/adviceTypes';

// tempexp_30797_start
export interface CloseToMarketEmployerAdvice {
    adviceType: AdviceType.MakeSalaryCloserToMarket;
    adviceDetails: {
        vacanciesIds: string[];
    };
}
// tempexp_30797_end

export interface ManagersEmployerAdvice {
    adviceType: AdviceType.ManagerUnreadResponses | AdviceType.McpManagersUnreadResponses;
    adviceDetails: {
        unreadResponsesCount: number;
    };
}

export interface DefaultEmployerAdvice {
    adviceType: AdviceDefaultType;
    adviceDetails?: never;
}

export type EmployerAdviceItem = CloseToMarketEmployerAdvice | ManagersEmployerAdvice | DefaultEmployerAdvice;

const employerAdvices = autoGeneratedReducer<EmployerAdviceItem[]>([]);
export { employerAdvices };
