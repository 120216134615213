import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface Draft {
    draftId: number;
    name: string;
    completedFieldsPercentage: number;
    suitableResumesCountTotal: number;
    suitableResumesCountLastMonth: number;
}

export interface NextDraftScheduled {
    draftId: number;
    availableDraft: {
        name: string;
        requiredPublications: PublicationTypeCount[];
    };
    draftScheduledPublication: {
        isoDateTimePublication: string;
        gmt: string;
    };
}

export interface PublicationTypeCount {
    publicationType: string;
    count: number;
}

export interface NewEmployerDashboardVacancy {
    draft?: Draft;
    nextDraftScheduled?: NextDraftScheduled;
    bill?: string;
    employerRegionTrl?: string;
    employerRegionResumesCount: number;
    employerRegion: number;
    giftsCount: number;
    isFreeStandardPlusAvailable: boolean;
}

const newEmployerDashboardVacancy = autoGeneratedReducer<NewEmployerDashboardVacancy>({
    employerRegionResumesCount: 0,
    employerRegion: 0,
    giftsCount: 0,
    isFreeStandardPlusAvailable: false,
});

export { newEmployerDashboardVacancy };
