import { Dispatch } from 'react';
import { ThunkAction } from 'redux-thunk';

import { Action, autoGeneratedReducer, makeSetStoreField } from '@hh.ru/redux-create-reducer';

import { AppStore } from 'src/app/store';

export enum VisibilityReason {
    BoughtResume = 'BOUGHT_RESUME',
    Topic = 'TOPIC',
    ResumeGift = 'RESUME_GIFT',
}

export interface ResumeFields {
    resumeId: string;
    resumeHash: string;
    userId: string;
    title: string;
}

export interface VacancyFields {
    vacancyId: string;
    name: string;
}
export interface ResumeVisibility {
    resumeId: string;
    contactsVisible: boolean;
    contactsVisibilityReasons: VisibilityReason[];
    contactsVisibilityHistory: {
        [VisibilityReason.BoughtResume]: BoughtResumeHistoryItem[];
        [VisibilityReason.Topic]: TopicHistoryItem[];
        [VisibilityReason.ResumeGift]: ResumeGiftHistoryItem[];
    };
}

interface ContactsVisibilityHistory {
    applicantUserId: string | null;
    requestedResume: ResumeVisibility | null;
    otherResumes: ResumeVisibility[];
    resumeById: Record<string, ResumeFields>;
    vacancyById: Record<string, VacancyFields>;
}

export interface BoughtResumeHistoryItem {
    applicantUserId: string;
    creationTime: string;
    expirationTime: string;
    service: {
        serviceId: string;
        activationTime: string;
        expirationTime: string;
        title: string;
    };
    contactsVisible: boolean;
}

export enum TopicState {
    Response = 'RESPONSE',
    Invitation = 'INVITATION',
}

export interface TopicHistoryItem {
    topicId: string;
    resumeId: string;
    vacancyId: string;
    initialState: TopicState;
    creationTime: string;
    contactsVisible: boolean;
}

export interface ResumeGiftHistoryItem {
    resumeGiftId: string;
    resumeId: string;
    vacancyId: string;
    creationTime: string;
    expirationTime: string | null;
    contactsVisible: boolean;
}

export enum ErrorReason {
    UnknownError = 'UNKNOWN_ERROR',
    ResumeHasWrongStatus = 'RESUME_HAS_WRONG_STATUS',
    ResumeIsNotListed = 'RESUME_IS_NOT_LISTED',
    ResumeIsNotFound = 'RESUME_IS_NOT_FOUND',
    MissingEmployerId = 'MISSING_EMPLOYER_ID',
    EmployerIsNotFound = 'EMPLOYER_IS_NOT_FOUND',
}

export interface ContactsVisibility {
    request: {
        employerId?: string;
        employerIdFromResumeHash?: string;
        actualEmployerId?: string;
        resumeHash?: string;
    };
    error?: { reason: ErrorReason };
    employer: {
        employerId: string;
        name: string;
    } | null;
    serviceUrlTemplate: string;
    visibilityHistory: ContactsVisibilityHistory;
}

const initialState: ContactsVisibility = {
    request: {},
    employer: null,
    serviceUrlTemplate: '',
    visibilityHistory: {
        applicantUserId: null,
        requestedResume: null,
        otherResumes: [],
        resumeById: {},
        vacancyById: {},
    },
};

export const setEmployerContactsVisibility = makeSetStoreField('employerContactsVisibility');
export const resetEmployerContactsVisibility =
    () =>
    (dispatch: Dispatch<ThunkAction<Action | null, AppStore, unknown, Action> | Action>): void => {
        dispatch(setEmployerContactsVisibility(initialState));
    };

const employerContactsVisibility = autoGeneratedReducer<ContactsVisibility>(initialState);
export { employerContactsVisibility };
