import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { type ProfileStore, type ProfileFields, type ProfileConditions } from 'src/models/applicantProfile/types';

const PROFILE_FIELDS: ProfileFields = {
    area: [],
    metro: [],
    gender: [],
    lastName: [],
    language: [],
    birthday: [],
    firstName: [],
    workTicket: [],
    middleName: [],
    citizenship: [],
    driverLicenseTypes: [],
    hasVehicle: [],
    relocation: [],
    relocationArea: [],
    preferredWorkAreas: [],
    preferredWorkAllAreas: [],
};

const PROFILE_CONDITIONS = Object.keys(PROFILE_FIELDS).reduce(
    (acc, key) => ({ ...acc, [key]: undefined }),
    {} as ProfileConditions
);

const applicantProfileStore = autoGeneratedReducer<ProfileStore>({
    disabled: true,
    status: 'NEW',
    fields: PROFILE_FIELDS,
    conditions: PROFILE_CONDITIONS,
    photoUrls: {},
});

export { applicantProfileStore };
