import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { PaymentServicesFromBackend } from 'src/models/applicantPaymentServices';

export enum ApplicantPackageType {
    Basic = 'basic',
    Premium = 'premium',
    Lite = 'lite',
    Standard = 'standard',
    Pro = 'pro',
}

export enum ApplicantPaymentSource {
    MovileApp = 'mobile_app',
    Desktop = 'desktop',
}

export enum ApplicantPaymentType {
    CreditCard = 'CREDIT_CARD',
    YandexMoney = 'YANDEX_MONEY',
    Webmoney = 'WEBMONEY',
}

interface ServicePrice {
    amount: number;
    amountWithoutDiscount: number;
    currency: string;
}

const applicantServicesPayment = {
    applicantPackageType: autoGeneratedReducer<ApplicantPackageType>(ApplicantPackageType.Basic),
    applicantServiceType: autoGeneratedReducer<string>(''),
    applicantPaymentBackUrl: autoGeneratedReducer<string>(''),
    applicantAnalyticsAction: autoGeneratedReducer<string>(''),
    applicantPaymentTypes: autoGeneratedReducer<ApplicantPaymentType[]>([]),
    applicantAvailableResumeServices: autoGeneratedReducer<PaymentServicesFromBackend[]>([]),
    applicantPackageContent: autoGeneratedReducer<PaymentServicesFromBackend[]>([]),
    applicantAvailableQuantities: autoGeneratedReducer<string[]>([]),
    applicantServicesPrices: autoGeneratedReducer<Record<string, ServicePrice>>({}),
    applicantPaymentSource: autoGeneratedReducer<ApplicantPaymentSource>(ApplicantPaymentSource.Desktop),
    applicantFindJobRecommendedQuantity: autoGeneratedReducer<string>(null),
};

export { applicantServicesPayment };
