import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { PhotoState } from 'src/models/resume/resumeCommon.types';

export const enum ArtifactType {
    Photo = 'RESUME_PHOTO',
    Portfolio = 'PORTFOLIO',
}

export const enum ArtifactRulesFields {
    Photo = 'photo',
    Portfolio = 'portfolio',
}

export const MapArtifactTypeToArtifactRulesType = {
    [ArtifactType.Photo]: ArtifactRulesFields.Photo,
    [ArtifactType.Portfolio]: ArtifactRulesFields.Portfolio,
};
export interface ApplicantGalleryImage {
    id: string;
    state: PhotoState;
    type: string;
    artifactType: ArtifactType;
    title?: string;
    big?: string;
    preview?: string;
    avatar?: string;
}

export const enum ApplicantGalleryArtifactError {
    BadImage = 'bad_image',
    ImageTooLarge = 'image_too_large',
    BadFileType = 'bad_file_type',
    FileTooLarge = 'file_too_large',
    LimitReached = 'limit_reached',
    BadImageSize = 'bad_image_size',
    UploadError = 'upload_error',
    TooManyImages = 'too_many_images',
}

export interface ApplicantGalleryArtifact {
    images: ApplicantGalleryImage[];
    error: ApplicantGalleryArtifactError | null;
    notUploadedImagesCount: number;
}

export interface ApplicantGallery {
    [ArtifactType.Photo]: ApplicantGalleryArtifact;
    [ArtifactType.Portfolio]: ApplicantGalleryArtifact;
}

const SET_GALLERY_IMAGES = 'SET_GALLERY_IMAGES';
const ADD_GALLERY_IMAGE = 'ADD_GALLERY_IMAGE';
const REMOVE_GALLERY_IMAGE = 'REMOVE_GALLERY_IMAGE';
const SET_GALLERY_ERROR = 'SET_GALLERY_ERROR';
const SET_PORTFOLIO_TITLE = 'SET_PORTFOLIO_TITLE';

interface PayloadTypes {
    [SET_GALLERY_IMAGES]: { type: ArtifactType; images: ApplicantGalleryImage[] };
    [ADD_GALLERY_IMAGE]: { type: ArtifactType; image: ApplicantGalleryImage };
    [REMOVE_GALLERY_IMAGE]: { type: ArtifactType; id: string };
    [SET_GALLERY_ERROR]: {
        type: ArtifactType;
        error: ApplicantGalleryArtifactError | null;
        notUploadedImagesCount?: number;
    };
    [SET_PORTFOLIO_TITLE]: { id: string; title: string };
}

const INITIAL_STATE: ApplicantGallery = {
    [ArtifactType.Photo]: { images: [], error: null, notUploadedImagesCount: 0 },
    [ArtifactType.Portfolio]: { images: [], error: null, notUploadedImagesCount: 0 },
};

const actionCreator = ActionCreatorHelper<PayloadTypes>();

export const setGalleryImages = actionCreator(SET_GALLERY_IMAGES);
export const addGalleryImage = actionCreator(ADD_GALLERY_IMAGE);
export const removeGalleryImage = actionCreator(REMOVE_GALLERY_IMAGE);
export const setGalleryError = actionCreator(SET_GALLERY_ERROR);
export const setPortfolioTitle = actionCreator(SET_PORTFOLIO_TITLE);

const applicantGallery = createReducer<ApplicantGallery, PayloadTypes>(INITIAL_STATE, {
    [SET_GALLERY_IMAGES]: (state, action) => {
        return {
            ...state,
            [action.payload.type]: {
                ...state[action.payload.type],
                images: [...action.payload.images],
            },
        };
    },
    [ADD_GALLERY_IMAGE]: (state, action) => {
        return {
            ...state,
            [action.payload.type]: {
                ...state[action.payload.type],
                images: [...state[action.payload.type].images, action.payload.image],
            },
        };
    },
    [REMOVE_GALLERY_IMAGE]: (state, action) => {
        return {
            ...state,
            [action.payload.type]: {
                ...state[action.payload.type],
                images: state[action.payload.type].images.filter((image) => image.id !== action.payload.id),
            },
        };
    },
    [SET_GALLERY_ERROR]: (state, action) => {
        return {
            ...state,
            [action.payload.type]: {
                ...state[action.payload.type],
                error: action.payload.error,
                notUploadedImagesCount: action.payload.notUploadedImagesCount || 0,
            },
        };
    },
    [SET_PORTFOLIO_TITLE]: (state, action) => {
        return {
            ...state,
            [ArtifactType.Portfolio]: {
                ...state[ArtifactType.Portfolio],
                images: [
                    ...state[ArtifactType.Portfolio].images.map((image) =>
                        image.id === action.payload.id ? { ...image, title: action.payload.title } : { ...image }
                    ),
                ],
            },
        };
    },
});

export { applicantGallery };
