import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

enum FloodlightUtmTypes {
    UtmMedium = 'utm_medium',
    UtmSource = 'utm_source',
    UtmCampaign = 'utm_campaign',
    UtmTerm = 'utm_term',
}
export interface FloodlightUtm {
    [FloodlightUtmTypes.UtmMedium]?: string;
    [FloodlightUtmTypes.UtmSource]?: string;
    [FloodlightUtmTypes.UtmCampaign]?: string;
    [FloodlightUtmTypes.UtmTerm]?: string;
}

const floodlightUtm = autoGeneratedReducer<FloodlightUtm>({});
export { floodlightUtm };
