import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { Resume } from 'src/models/resume/resume.types';

type AccountDeleteResume = Omit<Resume, 'title'> & {
    title: string;
};

const accountDelete = autoGeneratedReducer<{
    applicantName: string;
    resumesList: {
        resumes: {
            published: AccountDeleteResume[];
            unpublished: AccountDeleteResume[];
        };
        count: number;
    };
}>({
    applicantName: '',
    resumesList: {
        resumes: {
            published: [],
            unpublished: [],
        },
        count: 0,
    },
});

export { accountDelete };
