import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export enum EmployerPfpLiquidityStatus {
    LowBudget = 'TOO_MANY_UNPROCESSED_RESPONSES_FOR_BUDGET',
    ToManyResponses = 'TOO_MANY_UNPROCESSED_RESPONSES_ABSOLUTE',
    LowConversionRate = 'POOR_CONVERSION_TO_RR',
    Ok = 'OK',
}

export interface EmployerPfpLiquidity {
    status: EmployerPfpLiquidityStatus;
    vacancies: { id: number; name: string; areaName: string }[];
}

const employerPfpLiquidity = autoGeneratedReducer<EmployerPfpLiquidity>(null);
export { employerPfpLiquidity };
