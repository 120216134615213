import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';
import {
    CompensationFrequency,
    EmploymentForm,
    WorkFormat,
    WorkingHours,
    WorkScheduleByDays,
} from '@hh.ru/types-hh-microcore';

import { CriteriaKey, ExperienceOption, OrderByOption, PartTimeOption } from 'src/types/search/common/criteria';
import { EducationOption, LabelOption, SearchPeriodOption } from 'src/types/search/vacancy/criteria';
import { EmploymentLower } from 'src/utils/constants/employment';
import { ScheduleCamelCase } from 'src/utils/constants/schedule';

export enum SearchField {
    Name = 'name',
    CompanyName = 'company_name',
    Description = 'description',
}

export interface VacancySearchDictionaries {
    searchPeriod: SearchPeriodOption[];
    orderBy: OrderByOption[];
    searchFields: SearchField[];
    [CriteriaKey.SalaryFrequency]: CompensationFrequency[];
    [CriteriaKey.Education]: EducationOption[];
    [CriteriaKey.Experience]: ExperienceOption[];
    [CriteriaKey.Employment]: EmploymentLower[];
    [CriteriaKey.Schedule]: ScheduleCamelCase[];
    partTime: PartTimeOption[];
    labels: LabelOption[];
    [CriteriaKey.WorkFormat]: WorkFormat[];
    [CriteriaKey.WorkingHours]: WorkingHours[];
    [CriteriaKey.WorkScheduleByDays]: WorkScheduleByDays[];
    [CriteriaKey.EmploymentForm]: EmploymentForm[];
    trls?: {
        [CriteriaKey.SalaryFrequency]: Record<CompensationFrequency, string>;
        [CriteriaKey.WorkFormat]: Record<WorkFormat, string>;
        [CriteriaKey.WorkingHours]: Record<WorkingHours, string>;
        [CriteriaKey.WorkScheduleByDays]: Record<WorkScheduleByDays, string>;
    };
}

const vacancySearchDictionaries = autoGeneratedReducer<VacancySearchDictionaries>({
    searchPeriod: [],
    orderBy: [],
    searchFields: [],
    [CriteriaKey.SalaryFrequency]: [],
    [CriteriaKey.Education]: [],
    [CriteriaKey.Experience]: [],
    [CriteriaKey.Employment]: [],
    [CriteriaKey.Schedule]: [],
    partTime: [],
    labels: [],
    [CriteriaKey.WorkFormat]: [],
    [CriteriaKey.WorkingHours]: [],
    [CriteriaKey.WorkScheduleByDays]: [],
    [CriteriaKey.EmploymentForm]: [],
});

export { vacancySearchDictionaries };
