import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { TariffProductType, DBAccessProductType } from 'src/models/price/product.types';

export interface SnippetAccessResumeInterface {
    productList: TariffProductType[] | DBAccessProductType[] | null;
    area: string | null;
    isShowSnippetResume: boolean;
    sim?: {
        has: boolean;
        simServiceEntryCode: string;
        vacancyId: number;
    };
}

const snippetAccessResume = autoGeneratedReducer<SnippetAccessResumeInterface>({
    productList: [],
    area: '',
    isShowSnippetResume: false,
});

export { snippetAccessResume };
