import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

interface ResumeSearchQuery {
    ItemsOnPage: [number];
    PageNumber: [string];
    Collection: string[];
    VacancyId: [string];
    ShowOnlyNewResponses: [boolean];
    ShowOnlyNew: [boolean];
}

const resumeSearchQuery = autoGeneratedReducer<ResumeSearchQuery>({
    ItemsOnPage: [50],
    PageNumber: ['0'],
    Collection: [],
    VacancyId: ['0'],
    ShowOnlyNewResponses: [false],
    ShowOnlyNew: [false],
});

export { resumeSearchQuery };
