import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { EmployerStateType } from 'src/models/negotiationTopic.types';

export const RECOMMENDED_SUBASTATE_NAME_LENGTH = 20;
export const MAX_SUBASTATE_NAME_LENGTH = 50;

const UPDATE_VACANCY_FUNNEL = 'UPDATE_VACANCY_FUNNEL';
const CREATE_VACANCY_FUNNEL_SUBSTATE = 'CREATE_VACANCY_FUNNEL_SUBSTATE';
const UPDATE_VACANCY_FUNNEL_STAGE = 'UPDATE_VACANCY_FUNNEL_STAGE';
const DELETE_VACANCY_FUNNEL_STAGE = 'DELETE_VACANCY_FUNNEL_STAGE';
const UPDATE_VACANCY_FUNNEL_SUBSTATES_ORDER = 'UPDATE_VACANCY_FUNNEL_SUBSTATES_ORDER';
const ADD_VACANCY_FUNNEL_STAGES = 'ADD_VACANCY_FUNNEL_STAGES';

export enum VacancyFunnelLabel {
    Multipost = 'MULTIPOST',
    Pfp = 'PFP',
}

interface PayloadTypes {
    [UPDATE_VACANCY_FUNNEL]: VacancyFunnel;
    [UPDATE_VACANCY_FUNNEL_STAGE]: VacancyFunnelStage | VacancyFunnelSubstate;
    [DELETE_VACANCY_FUNNEL_STAGE]: {
        funnelStageId: string;
        funnelSubstateId: string;
    };
    [UPDATE_VACANCY_FUNNEL_SUBSTATES_ORDER]: {
        funnelStageId: number;
        funnelSubStateIds: number[];
    };
    [CREATE_VACANCY_FUNNEL_SUBSTATE]: VacancyFunnelSubstate;
    [ADD_VACANCY_FUNNEL_STAGES]: VacancyFunnel;
}

export interface TemplateInfo {
    addressAllowed: boolean;
    addressId: number | null;
    employerManagerTemplateId: number | null;
    fromEmployerManagerTemplates: boolean;
    sendMessage: boolean;
    sendSms: boolean;
    smsAllowed: boolean;
    smsEmployerManagerTemplateId: number | null;
    smsTemplate: string | null;
    template: string | null;
    useAddressFromVacancy: boolean;
}

interface StageWithoutSubstates {
    creationTime: string;
    lastChangeTime: string;
    funnelStageId: number;
    state: EmployerStateType;
    substateName: string | null;
    quickTransferToStageEnabled: boolean;
    templateInfo: TemplateInfo;
    hidden: boolean;
}

export interface VacancyFunnelSubstate extends StageWithoutSubstates {
    substateName: string;
}

export interface VacancyFunnelStage extends StageWithoutSubstates {
    substates?: VacancyFunnelSubstate[];
}

export interface VacancyFunnel {
    creationTime: string;
    defaultFunnel: boolean;
    funnelId: number;
    lastChangeTime: string;
    name: string;
    ownerEmployerManagerId: number;
    stages: VacancyFunnelStage[] | null;
    currentUserHasWriteAccess: boolean;
    labels: VacancyFunnelLabel[];
}

export interface StageSettingsModalFormData extends TemplateInfo {
    substateName?: string;
    addressChecked?: boolean;
    quickTransferToStageEnabled: boolean;
}

const ActionCreator = ActionCreatorHelper<PayloadTypes>();

export const updateVacancyFunnel = ActionCreator(UPDATE_VACANCY_FUNNEL);
export const updateVacancyFunnelStage = ActionCreator(UPDATE_VACANCY_FUNNEL_STAGE);
export const deleteVacancyFunnelStage = ActionCreator(DELETE_VACANCY_FUNNEL_STAGE);
export const createVacancyFunnelSubstate = ActionCreator(CREATE_VACANCY_FUNNEL_SUBSTATE);
export const updateVacancyFunnelSubstatesOrder = ActionCreator(UPDATE_VACANCY_FUNNEL_SUBSTATES_ORDER);
export const addVacancyFunnelStages = ActionCreator(ADD_VACANCY_FUNNEL_STAGES);

const vacancyFunnel = createReducer<VacancyFunnel, PayloadTypes>(null, {
    [UPDATE_VACANCY_FUNNEL]: (_, actions) => actions.payload,
    [UPDATE_VACANCY_FUNNEL_STAGE]: (state, action) => {
        if (!state.stages) {
            return state;
        }
        const { payload } = action;
        const isSubstate = payload.substateName;
        const stageIndex = state.stages.findIndex((stage) =>
            isSubstate ? stage.state === payload.state : stage.funnelStageId === payload.funnelStageId
        );
        if (stageIndex < 0) {
            return state;
        }
        const newStages = [...state.stages];
        // Т.к. бэк возвращает структуру без вложенности – оставляем подстатусы на месте
        const substates = [...(newStages[stageIndex].substates || [])];
        if (isSubstate && substates) {
            const substateIndex = substates.findIndex((substate) => substate.funnelStageId === payload.funnelStageId);
            if (substateIndex < 0) {
                return state;
            }
            substates.splice(substateIndex, 1, payload as VacancyFunnelSubstate);
            newStages.splice(stageIndex, 1, { ...newStages[stageIndex], substates });
            return {
                ...state,
                stages: newStages,
            };
        }
        newStages.splice(stageIndex, 1, { ...action.payload, substates });
        return { ...state, stages: newStages };
    },
    [DELETE_VACANCY_FUNNEL_STAGE]: (state, action) => {
        const stageIndex = state.stages?.findIndex(
            (stage) => stage.funnelStageId.toString() === action.payload.funnelStageId
        );
        if (typeof stageIndex === 'undefined' || stageIndex < 0 || !state.stages) {
            return state;
        }
        const stageClone = { ...state.stages[stageIndex] };

        stageClone.substates = stageClone.substates?.filter(
            (substate) => substate.funnelStageId.toString() !== action.payload.funnelSubstateId
        );
        state.stages.splice(stageIndex, 1, stageClone);
        return { ...state, stages: [...state.stages] };
    },
    [UPDATE_VACANCY_FUNNEL_SUBSTATES_ORDER]: (state, { payload }) => {
        const stageIndex = state.stages?.findIndex((stage) => stage.funnelStageId === payload.funnelStageId);
        if (typeof stageIndex === 'undefined' || stageIndex < 0 || !state.stages) {
            return state;
        }

        const stageClone = { ...state.stages[stageIndex] };

        const reorderedSubstates = stageClone.substates?.sort((a, b) => {
            return (
                payload.funnelSubStateIds.indexOf(a.funnelStageId) - payload.funnelSubStateIds.indexOf(b.funnelStageId)
            );
        });
        state.stages.splice(stageIndex, 1, { ...stageClone, substates: reorderedSubstates });
        return { ...state, stages: [...state.stages] };
    },
    [CREATE_VACANCY_FUNNEL_SUBSTATE]: (state, action) => {
        if (!state.stages) {
            return state;
        }
        const stageIndex = state.stages.findIndex((stage) => stage.state === action.payload.state);
        if (stageIndex < 0) {
            return state;
        }
        const stages = [...state.stages];
        stages.splice(stageIndex, 1, {
            ...stages[stageIndex],
            substates: (stages[stageIndex].substates || []).concat(action.payload),
        });
        return {
            ...state,
            stages,
        };
    },
    [ADD_VACANCY_FUNNEL_STAGES]: (state, action) => {
        return { ...state, ...action.payload };
    },
});

export { vacancyFunnel };
