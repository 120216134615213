import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface EmployerCategory {
    '@value': number;
    '@code': string;
    '@translationKey': string;
}

const authNewEmployerCategories = autoGeneratedReducer<EmployerCategory[]>([]);
export { authNewEmployerCategories };
