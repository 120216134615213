import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer/lib/createReducer';

import { CartStatus } from 'src/models/cartStatus';
import { CurrencyType } from 'src/models/currencies.types';
import { BillingPlatform } from 'src/models/price/product.types';
import { Service } from 'src/models/price/service.types';

export type InvoiceCartService<T extends Service = Service> = T & {
    clickmeAccountName?: string;
    activationTime?: string;
    expirationTime: string;
    profRoleGroupTrls?: string[];
    regionTrl?: string;
    tariffName?: string;
};

export interface EmployerInvoiceCart {
    agreementId: number;
    autoActivationAllowed: boolean;
    cartId: number;
    costInCents: number;
    costWithoutDiscountInCents: number;
    currency: CurrencyType;
    defaultDiscountRate: number;
    grossPriceInCents: number;
    payerId: number;
    servicesList: InvoiceCartService[];
    status: CartStatus;
    shouldBeRendered: boolean;
    billingPlatform: BillingPlatform;
    givenDiscountPercent?: number;
}

export const updateEmployerInvoiceCart = makeSetStoreField('employerInvoiceCart');

const employerInvoiceCart = autoGeneratedReducer<EmployerInvoiceCart>(null);
export { employerInvoiceCart };
