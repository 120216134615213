import createReducer from '@hh.ru/redux-create-reducer';

const SUITABLE_COUNTER = 'SUITABLE_COUNTER';

export interface SuitableCounter {
    [id: number]: {
        counts?: number;
        isFetching: boolean;
        error?: boolean;
    };
}

interface PayloadTypes {
    [SUITABLE_COUNTER]: SuitableCounter;
}

interface Action {
    type: 'SUITABLE_COUNTER';
    payload: SuitableCounter;
}

export const fetchSuitableCounterFailed = (ids: number[]): Action => ({
    type: SUITABLE_COUNTER,
    payload: ids.reduce((result, id) => {
        result[id] = { isFetching: false, error: true };
        return result;
    }, {} as SuitableCounter),
});

export const fetchSuitableCounterStart = (ids: number[]): Action => ({
    type: SUITABLE_COUNTER,
    payload: ids.reduce((result, id) => {
        result[id] = { isFetching: true };
        return result;
    }, {} as SuitableCounter),
});

export const fetchSuitableCounterSuccess: (
    ids: number[],
    { counts }: { counts: { [id: number]: number } }
) => Action = (ids, { counts }) => ({
    type: SUITABLE_COUNTER,
    payload: ids.reduce((result, id) => {
        result[id] = { counts: counts[id], isFetching: false };
        return result;
    }, {} as SuitableCounter),
});

export const INITIAL_STATE = {};

const suitableCounterByVacancy = createReducer<SuitableCounter, PayloadTypes>(INITIAL_STATE, {
    [SUITABLE_COUNTER]: (state, action) => {
        return { ...state, ...action.payload };
    },
});

export { suitableCounterByVacancy };
