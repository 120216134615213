import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

export interface SmartScriptParams {
    [key: string]:
        | {
              [key: string]: string | string[];
          }
        | Array<{ [key: string]: string }>;
}

interface SmartScript {
    isAvailable: boolean;
    href: string;
    params: SmartScriptParams;
    deepLink: string;
}

const SET_SMART_SCRIPT_AVAILABILITY = 'SET_SMART_SCRIPT_AVAILABILITY';

interface PayloadTypes {
    [SET_SMART_SCRIPT_AVAILABILITY]: boolean;
}

const initialState = {
    isAvailable: false,
    href: '',
    deepLink: '',
    params: {},
};

const actionCreator = ActionCreatorHelper<PayloadTypes>();
export const setSmartScriptAvailability = actionCreator(SET_SMART_SCRIPT_AVAILABILITY);

const smartScript = createReducer<SmartScript, PayloadTypes>(initialState, {
    [SET_SMART_SCRIPT_AVAILABILITY]: (state, action) => {
        return { ...state, isAvailable: action.payload };
    },
});

export { smartScript };
