import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export const BELARUS_AREA_ID = '16';

export enum CountryId {
    Georgia = '8',
    Kirghizistan = '7',
    Uzbekistan = '6',
    Azerbaijan = '5',
    Belarus = '4',
    Kazakhstan = '3',
    Russia = '1',
}

export enum CountryCode {
    Georgia = 'GE',
    Kirghizistan = 'KG',
    Uzbekistan = 'UZ',
    Azerbaijan = 'AZ',
    Belarus = 'BY',
    Kazakhstan = 'KZ',
    Russia = 'RU',
}

const countryId = autoGeneratedReducer(CountryId.Russia);
export { countryId };
