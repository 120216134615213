import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { EmployerStateType } from 'src/models/negotiationTopic.types';
import { Gender, SalaryRange, Range, DriverLicense } from 'src/models/vacancyResponsesFilters';
import { FULL_TO_SHORT_EXPERIENCE } from 'src/utils/employer/filters';

export type FullExperience = keyof typeof FULL_TO_SHORT_EXPERIENCE;

export interface Filters {
    ageInterval: Range;
    workExperiences: FullExperience[];
    gender: Uppercase<Gender>;
    languages: { id: number; degree: string[] }[];
    salary: SalaryRange;
    educationLevel: string;
    driverLicenseTypes: DriverLicense[];
    hasVehicle: boolean;
    citizenship: number[];
    areas: string[];
    relocation: string;
}

interface StateInfo {
    state: EmployerStateType;
    message: string | null;
    addressId?: number | null;
}

export enum AnswerType {
    Correct = 'CORRECT',
    Wrong = 'WRONG',
}

export enum SurveyAnswerOptionProp {
    SurveyAnswerOptionTemplateId = 'surveyAnswerOptionTemplateId',
    Text = 'text',
    AnswerType = 'answerType',
    Error = 'error',
}

export interface SurveyAnswerOption {
    [SurveyAnswerOptionProp.SurveyAnswerOptionTemplateId]?: number;
    [SurveyAnswerOptionProp.Text]: string;
    [SurveyAnswerOptionProp.AnswerType]: AnswerType;
    [SurveyAnswerOptionProp.Error]?: string;
}

export interface SurveyQuestion {
    surveyQuestionTemplateId?: number;
    text: string;
    enabled: boolean;
    editable: boolean;
    answerOptions: SurveyAnswerOption[];
}

export interface AutoAction {
    filters: Filters;
    failedTopicStateInfo: StateInfo | null;
    passedTopicStateInfo: StateInfo | null;
    autoSurvey?: {
        questions: SurveyQuestion[];
    };
    vacancyId?: number;
    sharedWithOtherManagers?: boolean;
    copyFromVacancyId?: number;
}

enum ActionKind {
    SetAutoAction = 'SET_AUTO_ACTION',
    ResetAutoAction = 'RESET_AUTO_ACTION',
}

interface PayloadTypes {
    [ActionKind.SetAutoAction]: AutoAction;
    [ActionKind.ResetAutoAction]: void;
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();
export const setAutoActionAction = actionCreator(ActionKind.SetAutoAction);
export const resetAutoActionAction = actionCreator(ActionKind.ResetAutoAction);

const autoAction = createReducer<AutoAction | null, PayloadTypes>(null, {
    [ActionKind.SetAutoAction]: (_, action) => action.payload,
    [ActionKind.ResetAutoAction]: () => null,
});

export { autoAction };
