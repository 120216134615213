import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

enum SingupFildNames {
    Login = 'login',
    Password = 'password',
    FirstName = 'firstName',
    LastName = 'lastName',
}

interface SignupField {
    name: SingupFildNames;
    conditions: {
        minCount: number;
        maxCount: number;
        parts: { minLength: number; maxLength: number }[];
    };
}

interface ApplicantSignup {
    fields: SignupField[];
}

const applicantSignup = autoGeneratedReducer<ApplicantSignup>({ fields: [] });
export { applicantSignup };
