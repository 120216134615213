import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { Topic } from 'src/models/applicantNegotiationTopic.types';
import { Paging } from 'src/models/paging.types';

export interface Negotiations {
    topicList: Topic[];
    total?: number;
    paging?: Paging;
}

const applicantNegotiations = autoGeneratedReducer<Negotiations>({
    topicList: [],
});

export { applicantNegotiations };
