import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface ServiceAdditional {
    serviceNames: Record<string, string>;
}

const serviceAdditional = autoGeneratedReducer<ServiceAdditional>({
    serviceNames: {},
});

export { serviceAdditional };
