import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

const REFRESH_APPLICANT_SKILLS_VERIFICATION_ERROR = 'REFRESH_APPLICANT_SKILLS_VERIFICATION_ERROR';

interface PayloadTypes {
    [REFRESH_APPLICANT_SKILLS_VERIFICATION_ERROR]: null;
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();

export const refreshApplicantSkillsVerificationError = actionCreator(REFRESH_APPLICANT_SKILLS_VERIFICATION_ERROR);

const initialState = null;

const applicantSkillsVerificationError = createReducer(initialState, {
    [REFRESH_APPLICANT_SKILLS_VERIFICATION_ERROR]: () => initialState,
});

export { applicantSkillsVerificationError };
