import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

interface EmployerVacancyPromotion {
    vacancy: {
        id: string;
        name: string;
        template: string | null;
    };
}

const employerVacancyPromotion = autoGeneratedReducer<EmployerVacancyPromotion>(null);
export { employerVacancyPromotion };
