import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { BillingPlatform } from 'src/models/price/product.types';
import { ServiceItemCode } from 'src/models/price/productCodes';

interface TemporalServices {
    name: string;
    code: string;
    period: string;
    status: string;
    areaIds: string;
    date: string;
    time: string;
    activationDatetime: {
        date: string;
        time: string;
    };
}

export interface SingleServices {
    count: string;
    code: string;
    serviceCode: ServiceItemCode;
    areaIds: string[];
    profAreaIds: string[];
    profRoleGroupIdsTrl: string[];
    zpCrossPostAvailable: boolean;
    activationTime?: string;
    parsedExpirationTime: {
        date: string;
        time?: string;
    };
    parsedActivationTime: {
        date: string;
        time?: string;
    };
    billingPlatforms: BillingPlatform[];
}

export interface FreeServices {
    name: string;
    count: string;
    maxFree: string;
    serviceCode: ServiceItemCode;
    areaIds: string[];
    profAreaIds: string[];
    profRoleGroupIdsTrl: string[];
    nextFreeUpdate?: string;
    expiredDate?: string;
}

interface AccountHistoryServices {
    employerActiveTemporalServices: TemporalServices[];
    employerTemporalServices: TemporalServices[];
    employerActiveSingleServices: SingleServices[];
    employerFreeServices: FreeServices[];
}

const accountHistoryServices = autoGeneratedReducer<AccountHistoryServices>({
    employerActiveTemporalServices: [],
    employerTemporalServices: [],
    employerActiveSingleServices: [],
    employerFreeServices: [],
});

export { accountHistoryServices };
