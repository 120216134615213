import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

interface PricePageMetaInfo {
    description: string;
    pageTitle: string;
    priceRegionTrl?: string;
    title?: string;
    employerBudgetLink: string;
}

const pricePageMetaInfo = autoGeneratedReducer<PricePageMetaInfo>({
    description: '',
    pageTitle: '',
    employerBudgetLink: '',
});

export { pricePageMetaInfo };
