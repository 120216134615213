import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { CurrencyType } from 'src/models/currencies.types';

const UPDATE_FINANCIAL_DOCUMENT_DEEDS = 'UPDATE_FINANCIAL_DOCUMENT_DEEDS';

export interface FinancialDocumentsDeed {
    number?: string;
    deedId: number;
    serviceName?: string;
    creationTime?: string;
    payerInfo?: {
        operator: string;
        date: string;
        payerName: string;
        fio: string;
        phone: string;
        email: string;
    };
    // TODO Remove after HH-128466
    bills?: { id: number; uid: string; fileUrlPdf: string }[];
    price?: number;
    currency?: CurrencyType;
    invoices?: Record<string, string>;
    deedFileUrl?: string;
    hardCopyState?: number;
    requiredWorkFinished?: boolean;
}

interface PayloadTypes {
    [UPDATE_FINANCIAL_DOCUMENT_DEEDS]: FinancialDocumentsDeed[];
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();

export const updateFinancialDocumentDeedsAction = actionCreator(UPDATE_FINANCIAL_DOCUMENT_DEEDS);

const financialDocumentDeeds = createReducer<FinancialDocumentsDeed[], PayloadTypes>([], {
    [UPDATE_FINANCIAL_DOCUMENT_DEEDS]: (state, action) => {
        const indexedDeeds = new Map(action.payload.map((deed) => [deed.deedId, deed]));

        return state.map((deed) => indexedDeeds.get(deed.deedId) ?? deed);
    },
});

export { financialDocumentDeeds };
