import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';

export interface StoredNotification {
    type: string;
}

interface PayloadTypes {
    [ADD_NOTIFICATION]: StoredNotification;
    [CLEAR_NOTIFICATIONS]: void;
}

const ActionCreator = ActionCreatorHelper<PayloadTypes>();
/**
 *  Используется в редких случаях, когда нотификацию требуется прокинуть из стора
 */
export const addNotificationAction = ActionCreator(ADD_NOTIFICATION);
export const clearNotificationsAction = ActionCreator(CLEAR_NOTIFICATIONS);

export const INITIAL_STATE = [];

const notifications = createReducer<StoredNotification[], PayloadTypes>(INITIAL_STATE, {
    [ADD_NOTIFICATION]: (state, action) => [action.payload, ...state],
    [CLEAR_NOTIFICATIONS]: () => INITIAL_STATE,
});

export { notifications };
