import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

interface ExtraFilter {
    path: string;
    title: string;
    name: string;
}

const extraFilters = autoGeneratedReducer<ExtraFilter[]>([]);
export { extraFilters };
