import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export enum VacancyFeature {
    Archive = 'archive',
    CanApply = 'canApply',
    CanChangeOpenClosed = 'canChangeOpenClosed',
    Duplicate = 'duplicate',
    Edit = 'edit',
    Prolongate = 'prolongate',
}

interface VacancyFeaturesState {
    feature?: VacancyFeature[];
    isUpdatable?: boolean;
}

const vacancyFeatures = autoGeneratedReducer<VacancyFeaturesState>(null);
export { vacancyFeatures };
