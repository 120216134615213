import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface PlatformInfo {
    platform: string;
    userPlatform: string;
    appDomain?: string;
    appType?: string;
    appVersion?: string;
}

const platformInfo = autoGeneratedReducer<PlatformInfo>({
    platform: 'XHH',
    userPlatform: 'DESKTOP_WEB',
});

export { platformInfo };
