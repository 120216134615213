import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { ServicePriceItem } from 'src/models/price/priceBranding/priceBranding.types';

interface PriceBranding {
    items: ServicePriceItem[];
    defaultPriceRegions: string[];
    hasEmployerConstructor: boolean;
    tabName: string;
}

const initialState = {
    items: [],
    defaultPriceRegions: [],
    hasEmployerConstructor: false,
    tabName: '',
};
const priceBranding = autoGeneratedReducer<PriceBranding>(initialState);
export { priceBranding };
