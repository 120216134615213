// tempexp_PORTFOLIO-40023_file
import { FC } from 'react';

import ContainerForMicroFrontend from 'src/components/CodeInjector/ContainerForMicroFrontend';

const ApplicantServicesModalActivator: FC = () => (
    <ContainerForMicroFrontend place="applicantServicesModalSubscription" preloadContent={null} />
);

export default ApplicantServicesModalActivator;
