import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export enum DisplayType {
    Desktop = 'DESKTOP',
    Mobile = 'MOBILE',
    Tablet = 'TABLET',
}

const displayType = autoGeneratedReducer<DisplayType>(null);
export { displayType };
