import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface RestorePasswordConditions {
    code?: {
        mincount: number;
        maxcount: number;
        string: {
            required: boolean;
            type: string;
        };
    };

    password?: {
        mincount: number;
        maxcount: number;
        string: {
            minlength: number;
            maxlength: number;
            regexp: string;
            required: boolean;
            type: string;
        };
    };
}

const restorePasswordConditions = autoGeneratedReducer<RestorePasswordConditions>({});
export { restorePasswordConditions };
