import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

interface Webcall {
    stable: boolean;
    enabled: boolean;
}

const webcall = autoGeneratedReducer<Webcall>({
    stable: true,
    enabled: true,
});

export { webcall };
