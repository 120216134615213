import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { NegotiationTopic } from 'src/models/negotiationTopic.types';

type ResponseLetters = Partial<Record<string, string>>;

interface EmployerNegotiations {
    total: number | null;
    maximumTopicsPerUser: number | null;
    topicList: NegotiationTopic[];
    responseLetters: ResponseLetters;
    count: number | null;
}

const employerNegotiations = autoGeneratedReducer<EmployerNegotiations>({
    total: null,
    maximumTopicsPerUser: null,
    topicList: [],
    responseLetters: {},
    count: null,
});

export { employerNegotiations };
