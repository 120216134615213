import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface SupportChat {
    available: boolean;
    workday?: {
        timeFrom: string;
        timeTo: string;
    };
    weekend?: {
        timeFrom: string;
        timeTo: string;
    };
}

const supportChat = autoGeneratedReducer<SupportChat>({ available: false });
export { supportChat };
