import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { Resume } from 'src/models/resume/resume.types';

const UPDATE_TASK_SOLUTION_MARK = 'UPDATE_TASK_SOLUTION_MARK';

export enum TestMark {
    Unfair = 'UNFAIR',
    Fair = 'FAIR',
    Good = 'GOOD',
    Excellent = 'EXCELLENT',
}

export interface CandidateSolution {
    value: string;
    correct: boolean;
    selected: boolean;
    uidPk: number;
}

export interface TaskSolution {
    uidPk: number;
    task: {
        uidPk: number;
        description: string;
        open: boolean;
        multiple: boolean;
        candidateSolutions: CandidateSolution[];
    };
    solutionValue: {
        value: string;
        mark: TestMark;
    };
}

interface ShortVacancy {
    vacancyId: number;
    name: string;
}

interface EmployerVacancyResponseTest {
    resume: Pick<Resume, '_attributes' | 'middleName' | 'lastName' | 'firstName' | 'title'>;
    vacancy: ShortVacancy;
    testSolution: {
        dateTimeDuration: Array<{
            metric: string;
            ending: 'some';
            trl: string;
            value: number;
        }>;
        test: {
            description: string;
            guid: string;
            name: string;
            uidPk: number;
        };
        score: number;
        examined: boolean;
        mark: TestMark;
        marks: TestMark[];
        duration: number;
        uidPk: number;
        taskSolutions: TaskSolution[];
    };
}

interface PayloadTypes {
    [UPDATE_TASK_SOLUTION_MARK]: {
        uidPk: number;
        mark: TestMark;
        newScore: number;
    };
}

const ActionCreator = ActionCreatorHelper<PayloadTypes>();

export const updateTaskSolutionMark = ActionCreator(UPDATE_TASK_SOLUTION_MARK);

const employerVacancyResponseTest = createReducer<EmployerVacancyResponseTest, PayloadTypes>(null, {
    [UPDATE_TASK_SOLUTION_MARK]: (state, action) => {
        const { newScore, mark, uidPk } = action.payload;
        const index = state.testSolution.taskSolutions.findIndex((task) => task.uidPk === uidPk);
        if (index < 0) {
            return state;
        }

        return {
            ...state,
            testSolution: {
                ...state.testSolution,
                score: newScore,
                taskSolutions: Object.assign([], state.testSolution.taskSolutions, {
                    [index]: {
                        ...state.testSolution.taskSolutions[index],
                        solutionValue: {
                            ...state.testSolution.taskSolutions[index].solutionValue,
                            mark,
                        },
                    },
                }),
            },
        };
    },
});

export { employerVacancyResponseTest };
