import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface FieldConditions {
    parts: Array<{
        minLength?: number;
        maxLength?: number;
        regexp?: string;
        required?: boolean;
    }>;
}

export interface EmployerField {
    name: string;
    status: string;
    conditions: FieldConditions;
}

const authNewEmployerFields = autoGeneratedReducer<EmployerField[]>([]);
export { authNewEmployerFields };
