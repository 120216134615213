import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

const SET_EMPLOYER_BUTTON = 'SET_EMPLOYER_BUTTON';

interface EmployerButtons {
    saveSearch?: {
        emailRequired: boolean;
        count: number;
        userEmail: string;
    } | null;
    workHere?: boolean;
    complain?: {
        success: boolean;
    };
}

export type EmployerButtonsState = EmployerButtons;

interface PayloadTypes {
    [SET_EMPLOYER_BUTTON]: EmployerButtonsState;
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();

export const setEmployerButton = actionCreator(SET_EMPLOYER_BUTTON);

const employerButtons = createReducer<EmployerButtonsState, PayloadTypes>(
    {},
    {
        [SET_EMPLOYER_BUTTON]: (state, { payload }) => ({ ...state, ...payload }),
    }
);

export { employerButtons };
