import { useEffect, useState, useCallback } from 'react';

import { useBreakpoint, Button, CellText, Link } from '@hh.ru/magritte-ui';
import { CopyOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import HelpModalActionWithButton from 'src/components/SupernovaMainMenu/HelpModal/HelpModalActionWithButton';
import useTrackClicks from 'src/components/SupernovaMainMenu/useTrackClicks';
import SupportAction from 'src/components/Support/SupportActions/SupportAction';
import { SUPPORT_ACTIONS_ICONS } from 'src/components/Support/SupportActions/constants';
import translation from 'src/components/translation';
import { NavItem } from 'src/models/supernovaNaviMenu';

const TrlKeys = {
    copy: 'supernova.modal.help.zpFeedback.copy',
};

interface HelpModalButtonLinkProps {
    isWide?: boolean;
}

const ZP_SUPPORT_MAIL_ADDRESS = 'support@zarplata.ru';

const ZpFeedbackActionComponent: TranslatedComponent<HelpModalButtonLinkProps & NavItem> = ({
    trls,
    ...supportAction
}) => {
    const commonProps = {
        url: supportAction.url,
        external: supportAction.external,
        icon: SUPPORT_ACTIONS_ICONS[supportAction.name as keyof typeof SUPPORT_ACTIONS_ICONS],
        iconAriaLabel: supportAction.name,
        children: supportAction.translations.name,
        name: supportAction.name,
    };
    const [isWinPlatform, setIsWinPlatform] = useState(false);

    useEffect(() => {
        setIsWinPlatform(navigator.userAgent.includes('Win'));
    }, []);

    const { isMobile } = useBreakpoint();
    const trackClick = useTrackClicks(supportAction.name, supportAction.trackClicks, supportAction.analytics);

    const copyMailAdress = useCallback((mail: string) => {
        void navigator.clipboard.writeText(mail);
    }, []);

    return (
        <>
            {isWinPlatform ? (
                <HelpModalActionWithButton
                    item={supportAction}
                    avatarProps={{
                        size: 48,
                        mode: 'icon',
                        icon: commonProps.icon,
                        style: 'secondary',
                        'aria-label': commonProps.iconAriaLabel,
                    }}
                    cellTextTitle={commonProps.children}
                    cellTextSubtitleComponent={
                        <CellText type="subtitle">
                            {isMobile ? (
                                ZP_SUPPORT_MAIL_ADDRESS
                            ) : (
                                <Link inline href={commonProps.url} typography="label-3-regular">
                                    {ZP_SUPPORT_MAIL_ADDRESS}
                                </Link>
                            )}
                        </CellText>
                    }
                    actionButton={
                        <Button
                            onClick={() => copyMailAdress(ZP_SUPPORT_MAIL_ADDRESS)}
                            icon={<CopyOutlinedSize16 />}
                            mode="secondary"
                            data-qa={`mainmenu_${commonProps.name}`}
                        >
                            {trls[TrlKeys.copy]}
                        </Button>
                    }
                />
            ) : (
                <SupportAction
                    data-qa={`mainmenu_${supportAction.name}`}
                    onClick={trackClick}
                    CellTextSubtitleComponent={() => {
                        return <CellText type="subtitle">{ZP_SUPPORT_MAIL_ADDRESS}</CellText>;
                    }}
                    {...commonProps}
                />
            )}
        </>
    );
};

export default translation(ZpFeedbackActionComponent);
