import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import {
    BillingDeed,
    BillingPayer,
    DeliveryCountry,
    PacketOrder,
    PickupInterval,
} from 'src/models/employerDeedOrder/types';

interface EmployerDeedsListState {
    deeds: BillingDeed[];
    pickupAllowed?: boolean;
    packetOrders?: PacketOrder[];
    daysBetweenCreationAndFirstHardCopy?: number;
    daysBetweenHardCopies?: number;
    payers: BillingPayer[];
    selectedPayerId: string | null;
    checkedDeedIds?: string[];
    datesRange?: {
        from?: string;
        to?: string;
    };
    countries?: DeliveryCountry[];
    pickupIntervals?: PickupInterval[];
}

const UPDATE_SELECTED_PAYER_ID = 'UPDATE_SELECTED_PAYER_ID';
const UPDATE_SELECTED_DATE_RANGE = 'UPDATE_SELECED_DATE_RANGE';
const TOGGLE_CHECK_DEED_ID = 'TOGGLE_CHECKED_DEED_ID';

interface PayloadTypes {
    [UPDATE_SELECTED_PAYER_ID]: {
        payerId: string | null;
    };
    [UPDATE_SELECTED_DATE_RANGE]: {
        from?: string;
        to?: string;
    };
    [TOGGLE_CHECK_DEED_ID]: {
        id: string;
    };
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();

export const updateSelectedPayerIdAction = actionCreator(UPDATE_SELECTED_PAYER_ID);
export const updateSelectedDateRangeAction = actionCreator(UPDATE_SELECTED_DATE_RANGE);
export const toggleCheckDeedIdAction = actionCreator(TOGGLE_CHECK_DEED_ID);

export const INITIAL_STATE: EmployerDeedsListState = {
    deeds: [],
    payers: [],
    selectedPayerId: null,
    checkedDeedIds: [],
};

const employerDeedOrder = createReducer<EmployerDeedsListState, PayloadTypes>(INITIAL_STATE, {
    [UPDATE_SELECTED_PAYER_ID]: (oldState, action) => {
        const { payerId } = action.payload;

        return {
            ...oldState,
            selectedPayerId: payerId,
            checkedDeedIds: [],
        };
    },
    [TOGGLE_CHECK_DEED_ID]: (oldState, action) => {
        const { id } = action.payload;

        const newCheckedArr = (oldState.checkedDeedIds || []).slice();

        const checkedIndex = newCheckedArr.indexOf(id);

        if (checkedIndex > -1) {
            newCheckedArr.splice(checkedIndex, 1);
        } else {
            newCheckedArr.push(id);
        }

        return {
            ...oldState,
            checkedDeedIds: newCheckedArr,
        };
    },
    [UPDATE_SELECTED_DATE_RANGE]: (oldState, action) => {
        const { from, to } = action.payload;

        return {
            ...oldState,
            datesRange: {
                from: from || oldState.datesRange?.from,
                to: to || oldState.datesRange?.to,
            },
            checkedDeedIds: [],
        };
    },
});

export { employerDeedOrder };
