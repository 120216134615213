import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { Paging } from 'src/models/paging.types';
import { SearchTypeGenitive } from 'src/types/search/common';

export enum LetterGroupType {
    ETC = 'ETC',
    RUS = 'RUS',
    ENG = 'ENG',
}

export interface Letter {
    letterGroupType: LetterGroupType;
    letterGroupId: string;
    alias: string;
    hasItems: boolean;
    selected: boolean;
}

export interface Letters {
    [LetterGroupType.RUS]: Letter[];
    [LetterGroupType.ENG]: Letter[];
    [LetterGroupType.ETC]: Letter[];
}

export interface Catalog {
    catalogPath: string;
    nominative: string;
    count: number;
}

export interface SeoPaging extends Paging {
    basePath: string;
    templatePageParam: string;
}

export enum SearchCatalogListType {
    Main = 'main',
    ByLetter = 'byLetter',
}

export interface SearchCatalogListMain {
    listType: SearchCatalogListType.Main;
    catalogType: SearchTypeGenitive;
    letters: Letters;
}

export interface SearchCatalogListByLetter {
    listType: SearchCatalogListType.ByLetter;
    catalogType: SearchTypeGenitive;
    letters: Letters;
    catalogs: Catalog[];
    seoPaging?: SeoPaging;
}

type SearchCatalogList = SearchCatalogListMain | SearchCatalogListByLetter;

const searchCatalogList = autoGeneratedReducer<SearchCatalogList>(null);
export { searchCatalogList };
