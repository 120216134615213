import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { Paging } from 'src/models/paging.types';

interface Manager {
    id: string;
    fullName: string;
}

interface Quota {
    creationTime: string;
    authorFullName: string;
    targetFullName: string;
    changes: {
        name: string;
        oldValue: string | null;
        newValue: string | null;
    };
}

interface EmployerQuotaHistory {
    managers: Manager[];
    quotas: {
        dates: {
            date: string;
            list: Quota[];
        }[];
        paging: Paging | null;
    };
}

const employerQuotaHistory = autoGeneratedReducer<EmployerQuotaHistory>(null);
export { employerQuotaHistory };
