import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { VacancySearchItem } from 'src/types/search/vacancy/card';

interface RecommendedVacancies {
    itemsOnPage: number;
    resultsFound: number;
    totalPages: number;
    type: string;
    lastActivityTimeByHHID: Record<string, { dt: string }>;
    vacancies: VacancySearchItem[];
    hasVacanciesWithAddress: boolean;
    queries: Record<string, string>;
}

const recommendedVacancies = autoGeneratedReducer<RecommendedVacancies>(null);
export { recommendedVacancies };
