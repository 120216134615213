import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { CurrencyType } from 'src/models/currencies.types';
import { ClickmeProductType } from 'src/models/price/product.types';

export enum CampaignCreationStatus {
    Disabled = 'DISABLED',
    NotCreated = 'NOT_CREATED',
    CampaignDraftCreated = 'CAMPAIGN_DRAFT_CREATED',
    CampaignCreated = 'CAMPAIGN_CREATED',
}

export const CAMPAIGN_CREATION_ENABLED_STATUSES = [
    CampaignCreationStatus.NotCreated,
    CampaignCreationStatus.CampaignDraftCreated,
];

export enum AuctionCampaignStatus {
    Blocked = 'BLOCKED',
    Active = 'ACTIVE',
    CampaignLowBalance = 'CAMPAIGN_LOW_BALANCE',
    AccountLowBalance = 'ACCOUNT_LOW_BALANCE',
}

export interface CampaignAccountInfo {
    id: number;
    title: string;
    isMain: boolean;
}
export interface CurrentVacancyAuctionCampaign {
    campaignId: number;
    campaignStatus: AuctionCampaignStatus;
    bid: number;
    budget: number;
    campaignBalance: number;
    campaignAccountInfo: CampaignAccountInfo;
}

export interface CurrentVacancyAuctionData {
    campaignCreationStatus: CampaignCreationStatus;
    campaignStatus?: AuctionCampaignStatus;
}

export interface CampaignDraft {
    campaignDraftId: string;
    purchaseUrl: string;
}

export enum AuctionFieldName {
    Bid = 'publication.auction.bid',
    Budget = 'publication.auction.budget',
    Replenishment = 'publication.auction.replenishment',
    Checked = 'publication.auction.checked',
    UseAvalilableMoney = 'publication.auction.useAvailableMoney',
}

type AuctionControlName = AuctionFieldName.Bid | AuctionFieldName.Budget | AuctionFieldName.Replenishment;

export enum AuctionFieldError {
    BidMoreThanCampaignBalance = 'publication.auction.bid.bidMoreThanCampaignBalance',
    BidRequired = 'publication.auction.bid.required',
    BudgetRequired = 'publication.auction.budget.required',
    InvalidBid = 'publication.auction.bid.invalidBid',
    InvalidBudget = 'publication.auction.budget.invalidBudget',
    MinBidValue = 'publication.auction.bid.minValue',
    MinBudgetValue = 'publication.auction.budget.minValue',
    MinReplenishmentValue = 'publication.auction.replenishment.minValue',
    InvalidReplenishment = 'auction.controls.error.invalidReplenishment',
    ReplenishmentRequired = 'publication.auction.replenishment.required',
}

export enum BidFrequencyStatus {
    Rare = 'RARE',
    Average = 'AVERAGE',
    Often = 'OFTEN',
}

export interface BidRatingData {
    competingCampaignBids: number[];
    competingCampaignIds: number[];
    maxBidAmongSimilarVacancies: number | null;
    bidFrequencyStatus: BidFrequencyStatus;
    oftenThresholdBid: number;
    averageThresholdBid?: number;
}

export interface BidRatingRequestParams {
    bid?: number;
    budget?: number;
    areaIds: string[];
    name: string;
    professionalRoleIds: string[];
}

interface BidRating {
    data: BidRatingData;
    loading: boolean;
}

interface AuctionStatistics {
    clicks: number;
    conversion: number;
    responses: number;
    startAuctionDate: string | null;
}

interface AuctionVacancyInfo {
    areaIds: string[];
    areaName: string;
    name: string;
    professionalRoleIds: string[];
}

export interface ControlInfo {
    fieldName: AuctionControlName;
    minValue: number;
    value: string;
    initialValue: string;
    error?: AuctionFieldError;
    showError?: boolean;
}

export interface ProductInfo {
    data: ClickmeProductType | null;
    loading: boolean;
}

export interface AuctionData {
    bidRating: BidRating;
    auctionStatistics: AuctionStatistics;
    campaignCreationStatus: CampaignCreationStatus;
    controls: Record<AuctionControlName, ControlInfo>;
    currency: CurrencyType;
    currentVacancyAuctionCampaign?: CurrentVacancyAuctionCampaign;
    campaignDraft?: CampaignDraft;
    productInfo: ProductInfo;
    vacancyId?: string;
    useAvailableMoney: boolean;
    availableMoney: number;
    hideClickmeBalance: boolean;
    accountBalance: number;
    vacancyInfo: AuctionVacancyInfo;
}

export interface AuctionDataWithCampaign extends AuctionData {
    vacancyId: string;
    currentVacancyAuctionCampaign: CurrentVacancyAuctionCampaign;
    campaignDraft: never;
}

export interface DisabledAuctionData {
    campaignCreationStatus: CampaignCreationStatus;
}

export interface CreatedAuctionData {
    auctionStatistics: AuctionStatistics;
    campaignCreationStatus: CampaignCreationStatus.CampaignCreated;
    currentVacancyAuctionCampaign: CurrentVacancyAuctionCampaign;
}

const SET_BID_RATING_DATA = 'SET_BID_RATING_DATA';
const SET_BID_RATING_LOADING = 'SET_BID_RATING_LOADING';
const SET_PRODUCT_INFO_DATA = 'SET_PRODUCT_INFO_DATA';
const SET_PRODUCT_INFO_LOADING = 'SET_PRODUCT_INFO_LOADING';
const UPDATE_AUCTION_CONTROL_INFO = 'UPDATE_AUCTION_CONTROL_INFO';
const UPDATE_AUCTION_STATUS = 'UPDATE_AUCTION_STATUS';
const UPDATE_AUCTION_CAMPAIGN_INFO = 'UPDATE_AUCTION_CAMPAIGN_INFO';
const CHANGE_USE_AVAILABLE_MONEY = 'CHANGE_USE_AVAILABLE_MONEY';
const SET_CREATED_AUCTION_DATA = 'SET_CREATED_AUCTION_DATA';

interface PayloadAuctionDataTypes {
    [SET_BID_RATING_DATA]: BidRatingData;
    [SET_BID_RATING_LOADING]: boolean;
    [SET_PRODUCT_INFO_DATA]: ProductInfo['data'];
    [SET_PRODUCT_INFO_LOADING]: boolean;
    [UPDATE_AUCTION_CONTROL_INFO]: { fieldName: AuctionControlName } & Partial<ControlInfo>;
    [UPDATE_AUCTION_STATUS]: AuctionCampaignStatus;
    [UPDATE_AUCTION_CAMPAIGN_INFO]: Partial<CurrentVacancyAuctionCampaign>;
    [CHANGE_USE_AVAILABLE_MONEY]: boolean;
    [SET_CREATED_AUCTION_DATA]: CreatedAuctionData;
}

const actionCreator = ActionCreatorHelper<PayloadAuctionDataTypes>();
export const setBidRatingData = actionCreator(SET_BID_RATING_DATA);
export const setBidRatingLoading = actionCreator(SET_BID_RATING_LOADING);
export const setProductInfoData = actionCreator(SET_PRODUCT_INFO_DATA);
export const setProductInfoLoading = actionCreator(SET_PRODUCT_INFO_LOADING);
export const updateAuctionControlInfo = actionCreator(UPDATE_AUCTION_CONTROL_INFO);
export const updateAuctionStatus = actionCreator(UPDATE_AUCTION_STATUS);
export const updateAuctionCampaignInfo = actionCreator(UPDATE_AUCTION_CAMPAIGN_INFO);
export const changeUseAvailableMoney = actionCreator(CHANGE_USE_AVAILABLE_MONEY);
export const setCreatedAuctionData = actionCreator(SET_CREATED_AUCTION_DATA);

export const defaultControlInfo = {
    default: null,
    minValue: null,
    value: null,
    initialValue: null,
    error: false,
};

const auctionData = createReducer<AuctionData, PayloadAuctionDataTypes>(null, {
    [SET_BID_RATING_DATA]: (state, { payload }) => {
        return { ...state, bidRating: { ...state.bidRating, data: payload } };
    },
    [SET_BID_RATING_LOADING]: (state, { payload }) => {
        return { ...state, bidRating: { ...state.bidRating, loading: payload } };
    },
    [SET_PRODUCT_INFO_DATA]: (state, { payload }) => {
        return { ...state, productInfo: { ...state.productInfo, data: payload } };
    },
    [SET_PRODUCT_INFO_LOADING]: (state, { payload }) => {
        return { ...state, productInfo: { ...state.productInfo, loading: payload } };
    },
    [UPDATE_AUCTION_CONTROL_INFO]: (state, { payload }) => {
        return {
            ...state,
            controls: {
                ...state.controls,
                [payload.fieldName]: { ...state.controls[payload.fieldName], ...payload },
            },
        };
    },
    [UPDATE_AUCTION_STATUS]: (state, { payload }) => {
        if (state.currentVacancyAuctionCampaign) {
            return {
                ...state,
                currentVacancyAuctionCampaign: {
                    ...state.currentVacancyAuctionCampaign,
                    campaignStatus: payload,
                },
            };
        }

        return state;
    },
    [UPDATE_AUCTION_CAMPAIGN_INFO]: (state, { payload }) => {
        if (state.currentVacancyAuctionCampaign) {
            return {
                ...state,
                currentVacancyAuctionCampaign: {
                    ...state.currentVacancyAuctionCampaign,
                    ...payload,
                },
            };
        }

        return state;
    },
    [CHANGE_USE_AVAILABLE_MONEY]: (state, { payload }) => {
        return { ...state, useAvailableMoney: payload };
    },
    [SET_CREATED_AUCTION_DATA]: (state, { payload }) => {
        return { ...state, ...payload };
    },
});

export { auctionData };
