import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

interface VacancyInfo {
    areaName: string;
    name: string;
    responsesCount: number;
    vacancyId: string;
}

interface PromotionUseSearchTop {
    vacancyList: VacancyInfo[];
}

const promotionUseSearchTop = autoGeneratedReducer<PromotionUseSearchTop>(null);
export { promotionUseSearchTop };
