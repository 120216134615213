import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface HiringPlanType {
    id?: number;
    hiredWithoutResumeCount?: number;
    plannedCount?: number;
    type?: 'NEW_HIRE' | 'REPLACEMENT';
    plannedHireDate?: string;
}

export const hiringPlanDefaultFormValue: HiringPlanType = {
    plannedCount: 0,
    type: 'NEW_HIRE',
    plannedHireDate: '',
};

const vacancyHiringPlans = autoGeneratedReducer<Record<string, HiringPlanType[]>>({});
export { vacancyHiringPlans };
