import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { Paging } from 'src/models/paging.types';

export enum TemplatesType {
    Personal = 'personal',
    Shared = 'shared',
    Global = 'global',
}

export interface VacancyTemplate {
    id: number;
    name: string;
    shared?: boolean;
    canEdit?: boolean;
    employerManager?: {
        id: number;
        firstName: string | null;
        lastName: string | null;
    };
}

export interface VacancyTemplates {
    items: VacancyTemplate[];
    paging: Paging | null;
}

const REMOVE_VACANCY_TEMPLATE = 'REMOVE_VACANCY_TEMPLATE';

interface PayloadTypes {
    [REMOVE_VACANCY_TEMPLATE]: { id: number };
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();

export const removeVacancyTemplate = actionCreator(REMOVE_VACANCY_TEMPLATE);

const vacancyTemplates = createReducer<VacancyTemplates, PayloadTypes>(
    { items: [], paging: null },
    {
        [REMOVE_VACANCY_TEMPLATE]: (state, action) => {
            return {
                ...state,
                items: state.items.filter((template) => template.id !== action.payload.id),
            };
        },
    }
);

export { vacancyTemplates };
