import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { VisibilityList } from 'src/models/resumeVisibility/visibilityList.types';

export const RESUME_VISIBILITY_EMPLOYER_BLACKLIST = 'RESUME_VISIBILITY_EMPLOYER_BLACKLIST';
export const RESUME_VISIBILITY_EMPLOYER_BLACKLIST_FETCHING = 'RESUME_VISIBILITY_EMPLOYER_BLACKLIST_FETCHING';

export const INITIAL_STATE: VisibilityList = {
    items: [],
    total: 0,
    page: 0,
    perPage: 10,
    isFetching: false,
    canAdd: 0,
    limit: 0,
    pager: null,
};

interface PayloadTypes {
    [RESUME_VISIBILITY_EMPLOYER_BLACKLIST_FETCHING]: void;
    [RESUME_VISIBILITY_EMPLOYER_BLACKLIST]: VisibilityList;
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();
export const resumeVisibilityEmployerBlacklistAction = actionCreator(RESUME_VISIBILITY_EMPLOYER_BLACKLIST);
export const resumeVisibilityEmployerBlacklistFetchingAction = actionCreator(
    RESUME_VISIBILITY_EMPLOYER_BLACKLIST_FETCHING
);

const resumeVisibilityEmployerBlacklist = createReducer<VisibilityList, PayloadTypes>(INITIAL_STATE, {
    [RESUME_VISIBILITY_EMPLOYER_BLACKLIST_FETCHING]: (state) => {
        return { ...state, isFetching: true };
    },
    [RESUME_VISIBILITY_EMPLOYER_BLACKLIST]: (state, action) => {
        const payload = action.payload ?? INITIAL_STATE;
        payload.items = payload.items || [];
        return { ...state, ...payload, isFetching: false };
    },
});

export { resumeVisibilityEmployerBlacklist };
