import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { SearchPage, SearchType } from 'src/types/search/common';

export interface SearchSettings {
    type: SearchType;
    page: SearchPage;
    actionPath: string;
}

const searchSettings = autoGeneratedReducer<SearchSettings>({
    type: SearchType.Resume,
    page: SearchPage.ResumeSearch,
    actionPath: '/search/resume',
});

export { searchSettings };
