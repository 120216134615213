import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export enum ActivityEvent {
    VacancyView = 'vacancy_view',
    VacancyResponse = 'vacancy_response',
    VacancyShowContacts = 'vacancy_show_contacts',
}

export interface ActivityEventScore {
    event: ActivityEvent;
    eventScore: number;
}

interface ApplicantActivityEvents {
    eventScores: ActivityEventScore[];
}

const applicantActivityEvents = autoGeneratedReducer<ApplicantActivityEvents>(null);
export { applicantActivityEvents };
