import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export enum VoteType {
    Dislike = '0',
    Like = '1',
    NoVoted = 'noVoted',
}

export interface ArticleRating {
    voteState: VoteType;
    likesCount: number;
}

const articleRating = autoGeneratedReducer<ArticleRating>(null);
export { articleRating };
