import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface GraphHistoryItem {
    day: number;
    month: number;
    total: number;
}

interface Identifier {
    hash: string;
    id: string;
    title: string;
}

interface HistoryCompany {
    id?: string;
    name: string;
    views: string[];
    viewed: boolean;
}

interface HistoryDay {
    month: number;
    day: number;
    companies: HistoryCompany[];
}

interface HistoryViewYear {
    year: number;
    days: HistoryDay[];
}

interface HistoryView {
    years: HistoryViewYear[];
    total: number;
    new: number;
}

interface ApplicantResumesHistoryViews {
    graphHistoryViews: GraphHistoryItem[];
    identifiers: Identifier[];
    paging: {
        currentPage: number;
        itemsNumber: number;
        itemsOnPage: number;
    };
    historyViews: HistoryView;
    isRoleSuitableForShowSetkaPromo: boolean;
}

const applicantResumeViewHistory = autoGeneratedReducer<ApplicantResumesHistoryViews>({
    graphHistoryViews: [],
    historyViews: { total: 0, new: 0, years: [] },
    identifiers: [],
    paging: {
        currentPage: 0,
        itemsOnPage: 0,
        itemsNumber: 0,
    },
    isRoleSuitableForShowSetkaPromo: false,
});

export { applicantResumeViewHistory };
