import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { SearchClusterMap, ClusterKey, SelectedValuesTypeByClusterKey } from 'src/types/search/common/clusters';

import { SEARCH_CLUSTERS_INITIAL_STATE } from 'src/models/search/common/clusters/index';

const UPDATE_DRAFT_CLUSTER_SELECTED_VALUES = 'UPDATE_DRAFT_CLUSTER_SELECTED_VALUES';
const RESET_DRAFT_CLUSTERS = 'RESET_DRAFT_CLUSTERS';

interface UpdateClusterSelectedValuesProps<T extends ClusterKey> {
    data: SelectedValuesTypeByClusterKey<T>;
    filter: T;
}

interface PayloadTypes {
    [UPDATE_DRAFT_CLUSTER_SELECTED_VALUES]: UpdateClusterSelectedValuesProps<ClusterKey>;
    [RESET_DRAFT_CLUSTERS]: void;
}

const actionHelper = ActionCreatorHelper<PayloadTypes>();

export const updateSearchDraftCluster = actionHelper(UPDATE_DRAFT_CLUSTER_SELECTED_VALUES);
export const resetSearchDraftClusters = actionHelper(RESET_DRAFT_CLUSTERS);

const searchDraftClusters = createReducer<SearchClusterMap, PayloadTypes>(SEARCH_CLUSTERS_INITIAL_STATE, {
    [UPDATE_DRAFT_CLUSTER_SELECTED_VALUES]: (state, { payload: { data, filter } }) => ({
        ...state,
        [filter]: {
            ...(state[filter] ? state[filter] : { groups: {} }),
            selectedValues: data,
        },
    }),
    [RESET_DRAFT_CLUSTERS]: () => ({ ...SEARCH_CLUSTERS_INITIAL_STATE }),
});

export { searchDraftClusters };
