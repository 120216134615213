import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

export interface RecommendedKeySkill {
    text: string;
    selected: boolean;
}

export interface SkillsInInput {
    string: string;
}

export interface RecommendedKeySkills {
    recommendedKeySkills: RecommendedKeySkill[];
    recommendedCompetencies: RecommendedKeySkill[];
    skillsInInput: SkillsInInput[];
}

const INITIAL_STATE: RecommendedKeySkills = {
    recommendedKeySkills: [],
    recommendedCompetencies: [],
    skillsInInput: [],
};

const UPDATE_RECOMMENDED_SKILLS = 'UPDATE_RECOMMENDED_SKILLS';
const UPDATE_RECOMMENDED_COMPETENCE = 'UPDATE_RECOMMENDED_COMPETENCE';
const UPDATE_SKILLS_IN_INPUT = 'UPDATE_SKILLS_IN_INPUT';

interface PayloadTypes {
    [UPDATE_RECOMMENDED_SKILLS]: RecommendedKeySkill[];
    [UPDATE_RECOMMENDED_COMPETENCE]: RecommendedKeySkill[];
    [UPDATE_SKILLS_IN_INPUT]: SkillsInInput[];
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();
export const updateRecommendedSkills = actionCreator(UPDATE_RECOMMENDED_SKILLS);
export const updateRecommendedCompetence = actionCreator(UPDATE_RECOMMENDED_COMPETENCE);
export const updateSkillsInInput = actionCreator(UPDATE_SKILLS_IN_INPUT);

const employerRecommendedSkills = createReducer<RecommendedKeySkills, PayloadTypes>(INITIAL_STATE, {
    [UPDATE_RECOMMENDED_SKILLS]: (state, action) => ({
        ...state,
        recommendedKeySkills: action.payload,
    }),
    [UPDATE_RECOMMENDED_COMPETENCE]: (state, action) => ({
        ...state,
        recommendedCompetencies: action.payload,
    }),
    [UPDATE_SKILLS_IN_INPUT]: (state, action) => ({
        ...state,
        skillsInInput: action.payload,
    }),
});

export { employerRecommendedSkills };
