import { AnyAction } from 'redux';

import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { DragParams } from 'src/components/EmployerConstructor/drag/DragElement';
import { PriceWithFormatData } from 'src/models/price/priceBranding/priceBranding.types';

import { PictureType } from 'src/models/employerConstructor/settings';
import { Widget, AddWidgetButtonType } from 'src/models/employerConstructor/widget.types';

const EMPLOYER_CONSTRUCTOR_FETCH = 'EMPLOYER_CONSTRUCTOR_FETCH';
const EMPLOYER_CONSTRUCTOR_SET_SAVED = 'EMPLOYER_CONSTRUCTOR_SET_SAVED';
const EMPLOYER_CONSTRUCTOR_SET_EDIT_MODE = 'EMPLOYER_CONSTRUCTOR_SET_EDIT_MODE';
const EMPLOYER_CONSTRUCTOR_SET_PREVIEW_MODE_WITH_BRANDING = 'EMPLOYER_CONSTRUCTOR_SET_PREVIEW_MODE_WITH_BRANDING';
const EMPLOYER_CONSTRUCTOR_SET_STATUS = 'EMPLOYER_CONSTRUCTOR_SET_STATUS';
const EMPLOYER_CONSTRUCTOR_UPDATE_WIDGETS_ORDER = 'EMPLOYER_CONSTRUCTOR_UPDATE_WIDGETS_ORDER';

const EMPLOYER_CONSTRUCTOR_ADD_IMAGE = 'EMPLOYER_CONSTRUCTOR_ADD_IMAGE';
const EMPLOYER_CONSTRUCTOR_MODIFY_IMAGE = 'EMPLOYER_CONSTRUCTOR_MODIFY_IMAGE';
const EMPLOYER_CONSTRUCTOR_SET_MODAL_ERROR = 'EMPLOYER_CONSTRUCTOR_SET_MODAL_ERROR';

const EMPLOYER_CONSTRUCTOR_MODIFY_WIDGET = 'EMPLOYER_CONSTRUCTOR_MODIFY_WIDGET';
const EMPLOYER_CONSTRUCTOR_ADD_WIDGET = 'EMPLOYER_CONSTRUCTOR_ADD_WIDGET';
const EMPLOYER_CONSTRUCTOR_REMOVE_WIDGET = 'EMPLOYER_CONSTRUCTOR_REMOVE_WIDGET';
const EMPLOYER_CONSTRUCTOR_UPDATE_HEADER = 'EMPLOYER_CONSTRUCTOR_UPDATE_HEADER';

const EMPLOYER_CONSTRUCTOR_RESET_DESCRIPTION_ERROR = 'EMPLOYER_CONSTRUCTOR_RESET_DESCRIPTION_ERROR';
const EMPLOYER_CONSTRUCTOR_SET_DESCRIPTION_ERROR = 'EMPLOYER_CONSTRUCTOR_SET_DESCRIPTION_ERROR';

const EMPLOYER_CONSTRUCTOR_SET_SIDEBAR_COLOR = 'EMPLOYER_CONSTRUCTOR_SET_SIDEBAR_COLOR';

export enum Status {
    Dirty = 'DIRTY',
    Fetching = 'FETCHING',
    Success = 'SUCCESS',
    Fail = 'FAIL',
}

export const PICTURE_RESIZE_MAX_TRIES = 20;

export interface EmployerConstructorWidget {
    id: number;
    type: AddWidgetButtonType;
    invalid?: boolean;
}

export interface EmployerConstructorTextWidget extends EmployerConstructorWidget {
    type: Widget.Text;
    content: string;
    invalidOnSave: boolean;
}
export interface EmployerConstructorAddressWidget extends EmployerConstructorWidget {
    type: Widget.Address;
    addressId: number;
}
export interface EmployerConstructorPictureWidget extends EmployerConstructorWidget {
    type: Widget.Picture;
    pictureId?: number | null;
    resizeStatus: Status;
}
export interface EmployerConstructorGalleryWidget extends EmployerConstructorWidget {
    type: Widget.Gallery;
    items?: GalleryPictureItem[];
}
export interface EmployerConstructorVideoWidget extends EmployerConstructorWidget {
    type: Widget.Video;
    url: string;
}
export interface EmployerConstructorSeparatorWidget extends EmployerConstructorWidget {
    type: Widget.Separator;
}

export type WidgetComponentProps<W extends EmployerConstructorWidget> = { editMode?: boolean } & DragParams & W;

export type AnyWidget =
    | EmployerConstructorSeparatorWidget
    | EmployerConstructorTextWidget
    | EmployerConstructorAddressWidget
    | EmployerConstructorPictureWidget
    | EmployerConstructorGalleryWidget
    | EmployerConstructorVideoWidget;

export interface GalleryPictureItem {
    pictureId: number;
}

export interface EmployerConstructorImage {
    pictureId: number;
    resizedPath?: string;
    resizedSmallPath?: string;
    blurredPath?: string;
    path: string;
    type: PictureType;
    originalPath: string;
    originalWidth: number;
    originalHeight: number;
    selectionLeft: number;
    selectionTop: number;
    selectionWidth: number;
    selectionHeight: number;
    resizeStatus?: Status;
    status?: Status;
}

export interface AreaSelection {
    x: number;
    y: number;
    width: number;
    height: number;
}

export enum SaveDescriptionErrorType {
    TextTooLong = 'TEXT_TOO_LONG',
    TextTooShort = 'TEXT_TOO_SHORT',
    TooManyCaps = 'TOO_MANY_CAPS',
    Unknown = 'UNKNOWN',
}
export const isSaveDescriptionError = (error: string | undefined): error is SaveDescriptionErrorType =>
    Object.values(SaveDescriptionErrorType).includes(error);

export const EMPTY_ERROR_STATE = undefined;
export interface EmployerConstructorState {
    editMode: boolean;
    previewModeWithBranding: boolean;
    version?: number;
    canEdit: boolean;
    modalError?: string | typeof EMPTY_ERROR_STATE;
    widgets: EmployerConstructorWidget[];
    widgetsOrder: number[];
    saveStatus?: Status;
    hasUnsavedChanges: boolean;
    pictureId?: number | null;
    resizeStatus?: Status;
    images: EmployerConstructorImage[];
    tabPrices: PriceWithFormatData[];
    hasConstructor: boolean;
    hasService: boolean;
    readyToActivateCartId?: string;
    readyToActivateCartAgreementId?: number;
    sidebarColors: Record<string, string>;
    hasServiceExpiration?: boolean;
    saveDescriptionErrorType?: SaveDescriptionErrorType;
    defaultPriceRegions: string[];
}

const INITIAL_STATE = {
    editMode: false,
    previewModeWithBranding: true,
    canEdit: false,
    widgets: [],
    widgetsOrder: [],
    saveStatus: Status.Dirty,
    hasUnsavedChanges: false,
    resizeStatus: Status.Dirty,
    images: [],
    tabPrices: [],
    hasConstructor: false,
    hasService: false,
    sidebarColors: {},
    defaultPriceRegions: [],
};

const actionCreator = ActionCreatorHelper<PayloadTypes>();
export const employerConstructorFetch = actionCreator(EMPLOYER_CONSTRUCTOR_FETCH);
export const setEditMode = actionCreator(EMPLOYER_CONSTRUCTOR_SET_EDIT_MODE);
export const setPreviewModeWithBranding = actionCreator(EMPLOYER_CONSTRUCTOR_SET_PREVIEW_MODE_WITH_BRANDING);
export const setConstructorStatus = actionCreator(EMPLOYER_CONSTRUCTOR_SET_STATUS);
export const employerConstructorUpdateWidgetsOrder = actionCreator(EMPLOYER_CONSTRUCTOR_UPDATE_WIDGETS_ORDER);
export const employerConstructorModifyWidget = actionCreator(EMPLOYER_CONSTRUCTOR_MODIFY_WIDGET);
export const employerConstructorAddWidget = actionCreator(EMPLOYER_CONSTRUCTOR_ADD_WIDGET);
export const employerConstructorRemoveWidget = actionCreator(EMPLOYER_CONSTRUCTOR_REMOVE_WIDGET);
export const employerConstructorUpdateHeader = actionCreator(EMPLOYER_CONSTRUCTOR_UPDATE_HEADER);
export const employerConstructorSetSaved = actionCreator(EMPLOYER_CONSTRUCTOR_SET_SAVED);
export const employerConstructorAddImage = actionCreator(EMPLOYER_CONSTRUCTOR_ADD_IMAGE);
export const employerConstructorModifyImage = actionCreator(EMPLOYER_CONSTRUCTOR_MODIFY_IMAGE);
export const setModalError = actionCreator(EMPLOYER_CONSTRUCTOR_SET_MODAL_ERROR);
export const setSaveDescriptionErrorType = actionCreator(EMPLOYER_CONSTRUCTOR_SET_DESCRIPTION_ERROR);
export const resetSaveDescriptionErrorType = actionCreator(EMPLOYER_CONSTRUCTOR_RESET_DESCRIPTION_ERROR);
export const setSidebarColor = actionCreator(EMPLOYER_CONSTRUCTOR_SET_SIDEBAR_COLOR);

type SwitchImagesActionWrapperParams = (params: {
    payload: Partial<EmployerConstructorPictureWidget> | Partial<EmployerConstructorState>;
    widgetId?: number;
}) => AnyAction;

export const switchImagesActionWrapper: SwitchImagesActionWrapperParams = (params) => {
    if (params.widgetId === undefined) {
        return employerConstructorUpdateHeader(params.payload);
    }
    return employerConstructorModifyWidget({
        ...params.payload,
        id: params.widgetId,
    });
};

interface PayloadTypes {
    [EMPLOYER_CONSTRUCTOR_FETCH]: EmployerConstructorState;
    [EMPLOYER_CONSTRUCTOR_SET_EDIT_MODE]: boolean;
    [EMPLOYER_CONSTRUCTOR_SET_PREVIEW_MODE_WITH_BRANDING]: boolean;
    [EMPLOYER_CONSTRUCTOR_SET_STATUS]: Status;
    [EMPLOYER_CONSTRUCTOR_UPDATE_WIDGETS_ORDER]: number[];
    [EMPLOYER_CONSTRUCTOR_MODIFY_WIDGET]: Partial<AnyWidget>;
    [EMPLOYER_CONSTRUCTOR_ADD_WIDGET]: EmployerConstructorWidget;
    [EMPLOYER_CONSTRUCTOR_REMOVE_WIDGET]: number;
    [EMPLOYER_CONSTRUCTOR_UPDATE_HEADER]: Partial<EmployerConstructorState>;
    [EMPLOYER_CONSTRUCTOR_SET_SAVED]: void;
    [EMPLOYER_CONSTRUCTOR_ADD_IMAGE]: EmployerConstructorImage;
    [EMPLOYER_CONSTRUCTOR_MODIFY_IMAGE]: Partial<EmployerConstructorImage>;
    [EMPLOYER_CONSTRUCTOR_SET_MODAL_ERROR]: string | typeof EMPTY_ERROR_STATE;
    [EMPLOYER_CONSTRUCTOR_SET_DESCRIPTION_ERROR]: SaveDescriptionErrorType | undefined;
    [EMPLOYER_CONSTRUCTOR_RESET_DESCRIPTION_ERROR]: void;
    [EMPLOYER_CONSTRUCTOR_SET_SIDEBAR_COLOR]: { name: string; color: string };
}

const employerConstructor = createReducer<EmployerConstructorState, PayloadTypes>(INITIAL_STATE, {
    [EMPLOYER_CONSTRUCTOR_FETCH]: (state, { payload }) => ({ ...state, ...payload }),
    [EMPLOYER_CONSTRUCTOR_SET_EDIT_MODE]: (state, { payload }) => {
        if (!state.previewModeWithBranding) {
            return { ...state, editMode: payload, previewModeWithBranding: true };
        }
        return { ...state, editMode: payload };
    },
    [EMPLOYER_CONSTRUCTOR_SET_PREVIEW_MODE_WITH_BRANDING]: (state, { payload }) => ({
        ...state,
        previewModeWithBranding: payload,
    }),
    [EMPLOYER_CONSTRUCTOR_SET_STATUS]: (state, { payload }) => ({ ...state, saveStatus: payload }),
    [EMPLOYER_CONSTRUCTOR_UPDATE_WIDGETS_ORDER]: (state, { payload }) => {
        return {
            ...state,
            widgetsOrder: payload,
            hasUnsavedChanges: true,
        };
    },
    [EMPLOYER_CONSTRUCTOR_MODIFY_WIDGET]: (state, { payload }) => {
        return {
            ...state,
            widgets: state.widgets.map((widget) => (widget.id === payload.id ? { ...widget, ...payload } : widget)),
            hasUnsavedChanges: true,
        };
    },
    [EMPLOYER_CONSTRUCTOR_ADD_WIDGET]: (state, { payload }) => {
        return {
            ...state,
            widgets: [...state.widgets, { ...payload }],
            hasUnsavedChanges: true,
        };
    },
    [EMPLOYER_CONSTRUCTOR_REMOVE_WIDGET]: (state, { payload }) => {
        return {
            ...state,
            widgets: state.widgets.filter((widget) => widget.id !== payload),
            hasUnsavedChanges: true,
        };
    },
    [EMPLOYER_CONSTRUCTOR_UPDATE_HEADER]: (state, { payload }) => {
        return {
            ...state,
            ...payload,
            hasUnsavedChanges: true,
        };
    },
    [EMPLOYER_CONSTRUCTOR_SET_SAVED]: (state) => {
        return {
            ...state,
            hasUnsavedChanges: false,
        };
    },
    [EMPLOYER_CONSTRUCTOR_ADD_IMAGE]: (state, { payload }) => {
        return {
            ...state,
            images: [...state.images, payload],
        };
    },
    [EMPLOYER_CONSTRUCTOR_MODIFY_IMAGE]: (state, { payload }) => {
        return {
            ...state,
            images: state.images.map((image) =>
                image.pictureId === payload.pictureId ? { ...image, ...payload } : image
            ),
        };
    },
    [EMPLOYER_CONSTRUCTOR_SET_MODAL_ERROR]: (state, { payload }) => {
        return {
            ...state,
            modalError: payload,
        };
    },
    [EMPLOYER_CONSTRUCTOR_SET_DESCRIPTION_ERROR]: (state, { payload }) => {
        return {
            ...state,
            saveDescriptionErrorType: payload,
        };
    },
    [EMPLOYER_CONSTRUCTOR_RESET_DESCRIPTION_ERROR]: (state) => {
        return {
            ...state,
            saveDescriptionErrorType: undefined,
        };
    },
    [EMPLOYER_CONSTRUCTOR_SET_SIDEBAR_COLOR]: (state, { payload }) => {
        return {
            ...state,
            sidebarColors: {
                ...state.sidebarColors,
                [payload.name]: payload.color,
            },
        };
    },
});

export { employerConstructor };
