// tempexp_37869_file
import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { RiseExtraProductType } from 'src/models/price/product.types';

export interface RiseExtra {
    count: number | null;
    totalPrice: number | null;
    product: RiseExtraProductType | null;
    areaId: string | null;
    isTest: boolean;
    isControl: boolean;
}
const SET_RISE_EXTRA_PRODUCT = 'SET_RISE_EXTRA_PRODUCT';
const SET_RISE_EXTRA_COUNT = 'SET_RISE_EXTRA_COUNT';

const INITIAL_STATE = {
    count: null,
    totalPrice: null,
    product: null,
    areaId: null,
    isTest: false,
    isControl: false,
};

interface PayloadTypes {
    [SET_RISE_EXTRA_COUNT]: number;
    [SET_RISE_EXTRA_PRODUCT]: {
        product: RiseExtraProductType | null;
        isTest: boolean;
        isControl: boolean;
        areaId: string;
    };
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();
export const setRiseExtraCount = actionCreator(SET_RISE_EXTRA_COUNT);
export const setRiseExtraProduct = actionCreator(SET_RISE_EXTRA_PRODUCT);

const riseExtra = createReducer<RiseExtra, PayloadTypes>(INITIAL_STATE, {
    [SET_RISE_EXTRA_PRODUCT]: (state, action) => {
        const { isTest, isControl, product, areaId } = action.payload;
        return {
            ...INITIAL_STATE,
            isTest,
            isControl,
            product,
            areaId,
        };
    },
    [SET_RISE_EXTRA_COUNT]: (state, action) => {
        const count = action.payload;
        if (count === 0) {
            return {
                ...state,
                count: null,
                totalPrice: null,
            };
        }
        return {
            ...state,
            count,
            totalPrice: state.product ? count * state.product.price : state.totalPrice,
        };
    },
});

export { riseExtra };
