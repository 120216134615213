import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { BrandSnippet } from 'src/models/brandSnippets';

export interface BrandSnippetsData {
    available: boolean;
    availableCount: number;
    quotaLimitReached: boolean;
    brandSnippetExpirationTime: string | null;
    brandSnippetId: string | null;
    brandSnippets: BrandSnippet[];
}

const brandSnippetsData = autoGeneratedReducer<BrandSnippetsData>({
    available: false,
    availableCount: 0,
    quotaLimitReached: false,
    brandSnippetExpirationTime: null,
    brandSnippetId: null,
    brandSnippets: [],
});

export { brandSnippetsData };
