import { FC, PropsWithChildren, KeyboardEvent, MouseEvent, ComponentProps, ReactNode } from 'react';

import { useBreakpoint, Card, Avatar, Cell, CellText, CellRightLabel } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';

import useTrackClicks from 'src/components/SupernovaMainMenu/useTrackClicks';
import translation from 'src/components/translation';
import { NavItem } from 'src/models/supernovaNaviMenu';

import styles from './help-modal-action-with-button.less';

const WrapperCard: FC<
    PropsWithChildren<{
        external?: boolean;
        handleClick: (event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>) => void;
        url: string;
        name: string;
    }>
> = ({ children, external, handleClick, url, name }) => {
    const { isMobile } = useBreakpoint();

    if (isMobile) {
        return (
            <Card
                borderRadius={24}
                padding={12}
                stretched
                borderWidth="default"
                Element={SPALink}
                to={url}
                target={external ? '_blank' : undefined}
                onClick={handleClick}
                data-qa={`mainmenu_${name}`}
            >
                {children}
            </Card>
        );
    }
    return (
        <Card borderRadius={24} padding={12} stretched borderWidth="default">
            {children}
        </Card>
    );
};

interface HelpModalActionWithButtonProps {
    item: NavItem;
    avatarProps: ComponentProps<typeof Avatar>;
    cellTextTitle: string;
    cellTextSubtitle?: string;
    cellTextSubtitleComponent?: ReactNode;
    actionButton: ReactNode;
}

const HelpModalActionWithButton: FC<HelpModalActionWithButtonProps> = ({
    item,
    avatarProps,
    cellTextTitle,
    cellTextSubtitle,
    cellTextSubtitleComponent,
    actionButton,
}) => {
    const { name, trackClicks, analytics, url, external } = item;

    const { isMobile } = useBreakpoint();

    const trackClick = useTrackClicks(name, trackClicks, analytics);

    return (
        <WrapperCard external={external} handleClick={trackClick} url={url} name={name}>
            <div className={styles.helpCardContainer}>
                <Cell left={<Avatar {...avatarProps} />} right={isMobile ? <CellRightLabel /> : undefined}>
                    <CellText type="title">{cellTextTitle}</CellText>
                    {cellTextSubtitle && <CellText type="subtitle">{cellTextSubtitle}</CellText>}
                    {cellTextSubtitleComponent}
                </Cell>
                {!isMobile && actionButton}
            </div>
        </WrapperCard>
    );
};

export default translation(HelpModalActionWithButton);
