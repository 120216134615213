import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export enum PaymentServicesFromBackend {
    CompleteResume = 'COMPLETE_RESUME',
    CompleteResumeConsultOnly = 'COMPLETE_RESUME_CONSULT_ONLY',
    CompleteResumeBase = 'COMPLETE_RESUME_BASE',
    CompleteResumeOptimal = 'COMPLETE_RESUME_OPTIMAL',
    CompleteResumeMaximal = 'COMPLETE_RESUME_MAXIMAL',
    CompleteResumeInterviewPractice = 'COMPLETE_RESUME_INTERVIEW_PRACTICE',
    ResumeMark = 'RESUME_MARK',
    ResumeRenewal = 'RESUME_RENEWAL',
    CareerGuidance = 'CAREER_GUIDANCE',
    VacancyResponsesSummary = 'VACANCY_RESPONSES_SUMMARY',
    FastVacancies = 'FAST_VACANCIES',
    HHAcademy = 'HH_ACADEMY',
    CareerMarathonBase = 'CAREER_MARATHON_BASE',
    CareerMarathonOptimal = 'CAREER_MARATHON_OPTIMAL',
    CareerMarathonMaximal = 'CAREER_MARATHON_MAXIMAL',
    ResumeTargetEmployer = 'RESUME_TARGET_EMPLOYER',
}

const applicantPaymentServices = autoGeneratedReducer<PaymentServicesFromBackend[]>([]);
export { applicantPaymentServices };
