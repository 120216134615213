import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface OauthCallback {
    oautlCallbackParams: Record<string, string>;
    oautlCallbackRedirect: {
        type?: string;
        data?: string;
    };
}

const oauthCallback = autoGeneratedReducer<OauthCallback>({
    oautlCallbackParams: {},
    oautlCallbackRedirect: {},
});

export { oauthCallback };
