import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

interface Service {
    creationTime?: string;
    price?: number;
    names: { title: string; count?: string }[];
}

interface AccountHistoryExpenditures {
    services: Service[];
    currency: string;
}

const accountHistoryExpenditures = autoGeneratedReducer<AccountHistoryExpenditures>({ services: [], currency: '' });
export { accountHistoryExpenditures };
