import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

const SET_ACCOUNT_ID_FOR_SERVICE = 'SET_ACCOUNT_ID_FOR_SERVICE';

interface PayloadTypes {
    [SET_ACCOUNT_ID_FOR_SERVICE]: {
        serviceId: string;
        accountId: string;
    };
}

const actionCreator = ActionCreatorHelper<PayloadTypes>();

export interface ClickmeAccount {
    accountId: string;
    isMain: boolean;
    title: string;
}

export interface EmployerInvoiceClickmeInfo {
    accountIds: string[];
    accountsById: Record<string, ClickmeAccount>;
    selectedAccountIdByServiceId: Record<string, string | null>;
    editable: boolean;
}

const initialState = {
    accountIds: [],
    accountsById: {},
    selectedAccountIdByServiceId: {},
    editable: false,
};

export const setAccountIdForService = actionCreator(SET_ACCOUNT_ID_FOR_SERVICE);

const employerInvoiceClickmeInfo = createReducer<EmployerInvoiceClickmeInfo, PayloadTypes>(initialState, {
    [SET_ACCOUNT_ID_FOR_SERVICE]: (state, { payload: { serviceId, accountId } }) => {
        const selectedAccountIdByServiceId = { ...state.selectedAccountIdByServiceId, [serviceId]: accountId };
        return { ...state, selectedAccountIdByServiceId };
    },
});

export { employerInvoiceClickmeInfo };
