import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface ProbationCatalogItem {
    title: string;
    translit: string;
}

interface ProbationCatalog {
    collection: ProbationCatalogItem[];
    current?: ProbationCatalogItem;
}

const probationCatalog = autoGeneratedReducer<ProbationCatalog>(null);
export { probationCatalog };
