import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

const employerDocumentsSettings = autoGeneratedReducer<{
    maxActiveDocuments: number;
    maxFileSize: number;
    maxFileSizeInMb: number;
    allowedMimeTypes: string[];
    allowedTypes: string[];
    allowedDocumentChanges: string[];
}>({
    maxActiveDocuments: 0,
    maxFileSize: 0,
    maxFileSizeInMb: 0,
    allowedMimeTypes: [],
    allowedTypes: [],
    allowedDocumentChanges: [],
});

export { employerDocumentsSettings };
