import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

interface Client {
    id: string;
    name: string;
    redirectUri: string;
    hhid: string;
    creationTime: string;
    modificationTime: string;
    currentUserGranted: boolean;
}

export interface Applications {
    clients: Client[];
    showRevokeButton: boolean;
}

const DEFAULT_STATE = {
    clients: [],
    showRevokeButton: false,
};

const applications = autoGeneratedReducer<Applications>(DEFAULT_STATE);
export { applications };
