import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface LowActivityAdviceVacancy {
    name: string;
    vacancyId: number;
    typeTrl: string;
    negotiations: {
        responsesCount: number;
        notProcessedResponsesCount: number;
    };
    links: {
        desktop: string;
        mobile: string;
    };
}

const lowActivityVacancies = autoGeneratedReducer<LowActivityAdviceVacancy[]>(null);
export { lowActivityVacancies };
