import { RefObject, useCallback, useMemo } from 'react';

import { VendorAnalytics } from '@hh.ru/analytics-js';
import {
    Link,
    useBreakpoint,
    NavigationBar,
    Action,
    Cell,
    CellText,
    StaticDataFetcherItem,
    useStaticDataProvider,
} from '@hh.ru/magritte-ui';
import { RenderOptionType, Select } from '@hh.ru/magritte-ui-select';
import { CrossOutlinedSize24, HieroglyphLetterOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { usePush } from '@hh.ru/redux-spa-middleware';
import Cookies from 'bloko/common/Cookies';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import urlParser from 'bloko/common/urlParser';

import FlagIcon from 'src/components/Footer/Icon';
import NoIndex from 'src/components/NoIndex';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { SiteLanguages } from 'src/models/locale';

import styles from './lang.less';

const TrlKeys = {
    [SiteLanguages.RU]: 'navi.lang.select.RU',
    [SiteLanguages.AZ]: 'navi.lang.select.AZ',
    [SiteLanguages.KZ]: 'navi.lang.select.KZ',
    [SiteLanguages.EN]: 'navi.lang.select.EN',
    [SiteLanguages.UZ]: 'navi.lang.select.UZ',
    [SiteLanguages.KG]: 'navi.lang.select.KG',
    [SiteLanguages.UA]: 'navi.lang.select.UA',
    bottomSheetHeader: 'navi.lang.select.header',
};

interface LangProps {
    onClick?: () => void;
}

const SESSION_LANG_QUERY_AND_COOKIE_NAME = 'session_language';

const LANG_LIST_ORDER = [
    SiteLanguages.RU,
    SiteLanguages.EN,
    SiteLanguages.KZ,
    SiteLanguages.UZ,
    SiteLanguages.KG,
    SiteLanguages.AZ,
];

const buildLocaleUrl = (language: string, backUrl: string): string => {
    const parsedUrl = urlParser('/locale');
    const parsedBackUrl = urlParser(backUrl);
    delete parsedBackUrl.params[SESSION_LANG_QUERY_AND_COOKIE_NAME];
    parsedUrl.params = {
        language,
        backUrl: parsedBackUrl.href,
    };
    return parsedUrl.href;
};

const Lang: TranslatedComponent<LangProps> = ({ trls, onClick }) => {
    const availableLanguages = useSelector(({ locale }) => locale.availableLanguages);
    const siteLanguage = useSelector(({ locale }) => locale.siteLanguage);

    const backUrl = useSelector((state) => state.request.url);

    const push = usePush();

    const { isMobile } = useBreakpoint();

    const items: StaticDataFetcherItem[] = useMemo(
        () =>
            LANG_LIST_ORDER.filter((lang) => availableLanguages.includes(lang)).map((lang) => ({
                value: lang as string,
                text: trls[TrlKeys[lang]],
            })),
        [availableLanguages, trls]
    );

    const provider = useStaticDataProvider(items);

    const renderDesktopItem: RenderOptionType<StaticDataFetcherItem> = useCallback(({ data }) => {
        return (
            <Cell
                Element="div"
                align="center"
                left={<FlagIcon lang={data.value as SiteLanguages} />}
                data-qa={`change-locale-${data.value}`}
            >
                <CellText>{data.text}</CellText>
            </Cell>
        );
    }, []);

    const renderMobileItem: RenderOptionType<StaticDataFetcherItem> = useCallback(({ data, input }) => {
        return (
            <Cell
                Element="div"
                align="center"
                left={<FlagIcon lang={data.value as SiteLanguages} />}
                right={input}
                data-qa={`change-locale-${data.value}-xs`}
            >
                <CellText>{data.text}</CellText>
            </Cell>
        );
    }, []);

    return (
        <li>
            <NoIndex>
                <Select
                    renderDesktopItem={renderDesktopItem}
                    renderMobileItem={renderMobileItem}
                    type={isMobile ? 'radio' : 'label'}
                    plain={true}
                    name="lang"
                    value={items.find((item) => item.value === siteLanguage)}
                    renderHeader={({ onCloseBottomSheet }) => (
                        <NavigationBar
                            title={trls[TrlKeys.bottomSheetHeader]}
                            right={
                                <Action
                                    icon={CrossOutlinedSize24}
                                    onClick={onCloseBottomSheet}
                                    data-qa="change-locale-bottom-sheet-navigation-close-xs"
                                />
                            }
                        />
                    )}
                    widthEqualToActivator={false}
                    dataProvider={provider}
                    triggerProps={{ size: 'medium' }}
                    onChange={(value) => {
                        Cookies.remove(SESSION_LANG_QUERY_AND_COOKIE_NAME);
                        VendorAnalytics.eventToStorage('Change_language', value.value, siteLanguage);
                        onClick && onClick();
                        push(buildLocaleUrl(value.value, backUrl));
                    }}
                    renderTrigger={({ onChange, ref, expanded }) => {
                        return (
                            <Link
                                data-qa="lang-switch-button"
                                ref={ref as RefObject<HTMLButtonElement>}
                                Element="button"
                                onClick={() => {
                                    onChange?.(!expanded);
                                }}
                                style="neutral"
                                typography="label-2-regular"
                            >
                                <div className={styles.linkContainer}>
                                    <HieroglyphLetterOutlinedSize24 />
                                    {trls[TrlKeys[siteLanguage]]}
                                </div>
                            </Link>
                        );
                    }}
                />
            </NoIndex>
        </li>
    );
};

export default translation(Lang);
