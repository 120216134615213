import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

interface ManagerAnnouncementBanner {
    body: string;
    click: string;
    isExternalAdvertising?: boolean;
    advertiserLegalName?: string;
    viewUrl: string;
}

const managerAnnouncementBanner = autoGeneratedReducer<ManagerAnnouncementBanner>(null);
export { managerAnnouncementBanner };
