import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

interface ArticleEmployerInfo {
    id: number;
    name: string;
    siteUrl?: string;
    logo?: string;
    industries: string[];
}

const articleEmployerInfo = autoGeneratedReducer<ArticleEmployerInfo>(null);
export { articleEmployerInfo };
