import createReducer from '@hh.ru/redux-create-reducer';
import { SuggestPickerItemType } from 'bloko/blocks/suggest/SuggestPicker';
import { ModelData } from 'bloko/common/tree/types';

import { CurrencyType } from 'src/models/currencies.types';
import { WrongPeriodType, ProductIdType, PublicationProductType, RegionIdType } from 'src/models/price/product.types';
import { ServiceItemCode } from 'src/models/price/productCodes';

export type PricePublicationsCodesType =
    | ServiceItemCode.Standart
    | ServiceItemCode.StandartPlus
    | ServiceItemCode.Premium
    | ServiceItemCode.Anonymous;

export interface PriceRegionalPublicationsType {
    regions: ModelData[];
    products: Record<ProductIdType, PublicationProductType>;
    productsByCode: Record<PricePublicationsCodesType, ProductIdType>;
    pricesByCount: Record<PricePublicationsCodesType, Record<string, number>>;
    regionalThreshold: number;
    publicationsAmount: number;
    priceRegion: RegionIdType;
    publicationPeriod: WrongPeriodType;
    zpCrossPostAvailable: boolean;
    zpCrossPostChecked: boolean;
    currency: CurrencyType;
}

export type PricesByCountType = Record<string, number>;

export interface AmountPickerItemType extends SuggestPickerItemType {
    additional: { amount: number; price: number };
}

type TabPartialType = Partial<PriceRegionalPublicationsType>;

interface UpdateTabDataAction {
    type: typeof UPDATE_TAB_DATA;
    payload: TabPartialType;
}

const UPDATE_TAB_DATA = 'UPDATE_TAB_DATA';

interface PayloadTypes {
    [UPDATE_TAB_DATA]: TabPartialType;
}

const updateTabData = (newTabData: TabPartialType): UpdateTabDataAction => ({
    type: UPDATE_TAB_DATA,
    payload: newTabData,
});

export const setPublicationsAmount = (publicationsAmount: number): UpdateTabDataAction =>
    updateTabData({ publicationsAmount });

export const setPriceRegion = (priceRegion: string): UpdateTabDataAction => updateTabData({ priceRegion });

export const setZpCrossPost = (zpCrossPostChecked: boolean): UpdateTabDataAction =>
    updateTabData({ zpCrossPostChecked });

export const setProductsData = (productsData: TabPartialType): UpdateTabDataAction => updateTabData(productsData);

const priceRegionalPublications = createReducer<PriceRegionalPublicationsType, PayloadTypes>(null, {
    [UPDATE_TAB_DATA]: (state, { payload }) => {
        return { ...state, ...payload };
    },
});

export { priceRegionalPublications };
