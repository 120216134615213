import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface EmployerQuotaManager {
    employerManagerId: number;
    hasPermissions: boolean;
    unlimited: boolean;
    value: number;
}

export interface EmployerQuota {
    code: string;
    name: string;
    isAssessments?: boolean;
    totalCount: number;
    manager: EmployerQuotaManager[];
}

export type EmployerQuotas = EmployerQuota[];

const employerQuotas = autoGeneratedReducer<EmployerQuotas>(null);
export { employerQuotas };
