import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { type ResumeAdditionalDataItem } from 'src/models/applicant/resume/additionalData/types';

export interface ResumeAdditionalData {
    resumeHash: string;
    requiredAdditionalData: ResumeAdditionalDataItem[];
}

const resumeAdditionalData = autoGeneratedReducer<ResumeAdditionalData>(null);
export { resumeAdditionalData };
