import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface Participant {
    id: number;
    code: number;
    name: string;
    description: string;
    jobTitle: string;
    bigPicture: string;
    smallPicture: string;
}

export interface InterviewLinkData {
    code: string;
    title: string;
    previewImg: string;
}

interface InsiderArticleSpecificData {
    participants: Participant[];
    otherInterviews: InterviewLinkData[];
}

const insiderArticleSpecificData = autoGeneratedReducer<InsiderArticleSpecificData>(null);
export { insiderArticleSpecificData };
