import { Dispatch } from 'react';
import { AnyAction } from 'redux';

import { makeSetStoreField } from '@hh.ru/redux-create-reducer';

import Debug from 'HHC/Debug';
import { setSearchClustersOrder } from 'src/models/search/common/clustersOrder';
import { replaceVacancySearchResult, VacancySearchResult } from 'src/models/search/vacancy/result';
import { Vacancy, BBox, GeoCluster } from 'src/models/searchMapResponse';
import { SearchClusterMap, ClusterKey } from 'src/types/search/common/clusters';
import { CriteriaKey } from 'src/types/search/common/criteria';
import fetcher from 'src/utils/fetcher';

const URL = '/shards/vacancymap/searchvacancymap';

interface Response {
    clusters: SearchClusterMap;
    searchClustersOrder: Record<ClusterKey, string[]>;
    found: number;
    geoClusters: GeoCluster[];
    mapInit: { bbox: BBox };
    precision: number;
    query: string;
    sendAnalytics: boolean;
    vacancySearchResult: VacancySearchResult;
    vacancies: Vacancy[];
    mapHasGeoClusters: boolean;
}

declare global {
    interface FetcherGetApi {
        [URL]: {
            queryParams: unknown;
            response: Response;
        };
    }
}

const searchClustersAction = makeSetStoreField('searchClusters');
const searchMapResponseAction = makeSetStoreField('searchMapResponse');
const searchSessionIdAction = makeSetStoreField('searchSessionId');
const searchLoadingAction = makeSetStoreField('searchLoading');
const searchCountsAction = makeSetStoreField('searchCounts');

interface FetchVacanciesProps {
    dispatch: Dispatch<unknown>;
    query: string;
    abortSignal?: AbortSignal;
}

const fetchMapVacancies =
    ({ dispatch, query, abortSignal }: FetchVacanciesProps) =>
    async (): Promise<void> => {
        dispatch(searchCountsAction({ isLoad: true, value: 0 }));
        let response;
        try {
            response = await fetcher.get<typeof URL>(`${URL}?${query}`, { signal: abortSignal, params: undefined });
        } catch (error) {
            Debug.log('error', error);
            return;
        }

        const { clusters, searchClustersOrder, vacancySearchResult } = response;

        window.dispatchEvent(new CustomEvent('HH-VacancySearchMapFilter-Changed', { detail: response }));

        const actions: AnyAction[] = [
            setSearchClustersOrder(searchClustersOrder),
            searchClustersAction(clusters),
            replaceVacancySearchResult(vacancySearchResult),
            searchMapResponseAction({
                vacanciesFromResponse: response.vacancies,
                geoClusters: response.geoClusters,
                mapHasGeoClusters: response.mapHasGeoClusters,
                query: response.vacancySearchResult.queries.map || '',
                clusters: response.clusters,
                vacancySearchResult,
                sendResponse: true,
            }),
            searchLoadingAction(false),
            searchCountsAction({ isLoad: false, value: vacancySearchResult.totalResults }),
        ];
        const searchSessionId = vacancySearchResult.criteria?.[CriteriaKey.SearchSessionId];
        if (searchSessionId) {
            actions.push(searchSessionIdAction(searchSessionId));
        }
        dispatch(actions);
    };

export default fetchMapVacancies;
