import { autoGeneratedReducer, makeSetStoreField } from '@hh.ru/redux-create-reducer';

export enum ActivationType {
    Auto = 'AUTO_ACTIVATION',
    Manual = 'MANUAL_ACTIVATION',
}

export interface ServiceActivationInfo {
    activationTime: null | string;
    allowedActivationPeriod: { startTime: string; endTime: string };
    bestPriceBefore: string;
    bestPriceBeforeNextDate: string;
    currentActivationType: ActivationType | null;
    hasLowerPrice: boolean;
    priceChangesSoon: boolean;
    priceExpiresSoon: boolean;
}

export interface CartActivationInfo {
    bestPriceBefore: string;
    bestPriceBeforeNextDate: string;
    currentActivationType: ActivationType | null;
    hasLowerPrice: boolean;
    priceChangesSoon: boolean;
    priceExpiresSoon: boolean;
}

export interface EmployerInvoiceActivationInfo {
    services: Record<string, ServiceActivationInfo>;
    cart: CartActivationInfo;
}

export const setEmployerInvoiceActivationInfo = makeSetStoreField('employerInvoiceActivationInfo');

const initialState = null;
const employerInvoiceActivationInfo = autoGeneratedReducer<EmployerInvoiceActivationInfo>(initialState);
export { employerInvoiceActivationInfo };
