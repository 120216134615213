import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { Paging } from 'src/models/paging.types';

export interface LockHistory {
    id: number;
    fullName: string;
    creationTime: string;
    lockReason: string;
}

interface LockHistoryState {
    total: number;
    list: LockHistory[];
    paging?: Paging;
}

const lockHistory = autoGeneratedReducer<LockHistoryState>({
    total: 0,
    list: [],
});

export { lockHistory };
