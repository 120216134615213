import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { CurrencyType } from 'src/models/currencies.types';
import { PfpInfo } from 'src/models/payForPerformance/pfpInfo';
import { ClickmeProductType, PublicationProductType, RiseExtraProductType } from 'src/models/price/product.types';
import { MetallicVacancyType } from 'src/models/vacancy/metallicVacancyType.types';
import { PublicationVacancyProperties, PublicationVacancyPropertiesId } from 'src/models/vacancyProperties';

export const PUBLICATION_TYPE_FIELD = 'publication.publicationVacancyProperties.id';
export const PUBLICATION_EMPLOYER_SERVICE_ID_FIELD = 'publication.employerServiceId';
export const PFP_PUBLICATION_TYPE_ID = 'HH_STANDARD_PLUS-HH_PAY_FOR_PERFORMANCE';

export enum PublicationPaymentType {
    ForPublication = 'forPublication',
    ForResponses = 'forResponses',
}

export interface AvailablePublicationTypesParams {
    areaIds: string[];
    professionalRoleIds: string[];
    divisionId?: string | undefined;
    withZpCrossPost?: boolean;
}
// tempexp_37869_next_line
export type PurchaseProductType = ClickmeProductType | PublicationProductType | RiseExtraProductType;
export type PurchaseProductList = PurchaseProductType[];

export interface PublicationTypePurchase {
    /** Цена за публикацию без доп. продуктов */
    labelPrice: number;
    /** Цена за всё */
    totalPrice: number;
    currency: CurrencyType;
    products: PurchaseProductList;
    /** Признак того, что для текущего типа публикаций доступны продукты с кросспостингом */
    zpCrossPostAvailable: boolean;
    /** Значение галочки кросспостинга по умолчанию */
    zpCrossPostDefault: boolean;
    /** Стоимость кросспостинга */
    zpCrossPostPrice: number;
}

export interface PublicationTypeSuitablePackage {
    count: number;
    employerServiceId: string;
    expirationTime: string;
    priceRegionIds: string[];
    profRoleGroupIds: string[];
    zpCrossPostAvailable: boolean;
}

export interface AvailablePublicationType {
    additionalFields: string[];
    areaIdsWhereFailedToPublish: string[];
    availableForPurchase: boolean;
    canChangeVisibility: boolean;
    ignoredFields: string[];
    insufficientQuotaCount: number;
    insufficientPublicationsCount: number;
    invalidEmployerServiceIds?: string[];
    possibleToSubmit: boolean;
    publicationsCount: number;
    purchase: PublicationTypePurchase | null;
    quotaCount: number | null;
    removedAdditionalFields: string[];
    selectPackageEnabled: boolean;
    suitablePackages: PublicationTypeSuitablePackage[];
    translations: {
        description: string;
        title: string;
    };
    legacyPublicationType: MetallicVacancyType;
    vacancyProperties: PublicationVacancyProperties;
    vacancyPublicationLimitPfp?: {
        allAreasFailedToPublish: boolean;
        areaLimitFailedToPublishTrls: string[];
        areaIdsLimitFailedToPublish: number[];
        pfpVacancyPublicationAreaLimit: number;
    };
}

export interface AvailablePublicationTypeTrls {
    area: Record<string, string>;
    profRoleGroup: Record<string, string>;
    region: Record<string, string>;
}

export interface AvailablePublicationTypesData {
    areaIdsRegions?: Record<number, number[]>;
    publicationTypes: Record<PublicationVacancyPropertiesId, AvailablePublicationType>;
    publicationTypesOrder: PublicationVacancyPropertiesId[];
    recommendedPublicationType: PublicationVacancyPropertiesId;
    currency: CurrencyType;
    hideSuitableResumesFeature: boolean;
    pfpOnboardingAvailable: boolean;
    trls: AvailablePublicationTypeTrls;
    pfpInfo?: PfpInfo;
    // tempexp_36140_next_line
    isNewEmployerWithoutServices?: boolean;
}

const UPDATE_AVAILABLE_PUBLICATION_TYPES_PARAMS = 'UPDATE_AVAILABLE_PUBLICATION_TYPES_PARAMS';
const SET_AVAILABLE_PUBLICATION_TYPES_LOADING = 'SET_AVAILABLE_PUBLICATION_TYPES_LOADING';
const SET_AVAILABLE_PUBLICATION_TYPES_ERROR = 'SET_AVAILABLE_PUBLICATION_TYPES_ERROR';
const SET_AVAILABLE_PUBLICATION_TYPES_WATCHING = 'SET_AVAILABLE_PUBLICATION_TYPES_WATCHING';
const SET_AVAILABLE_PUBLICATION_TYPES_DATA = 'SET_AVAILABLE_PUBLICATION_TYPES_DATA';

type PartialAvailablePublicationTypesParams = Partial<AvailablePublicationTypesParams>;

interface PayloadAvailablePublicationTypes {
    [UPDATE_AVAILABLE_PUBLICATION_TYPES_PARAMS]: PartialAvailablePublicationTypesParams;
    [SET_AVAILABLE_PUBLICATION_TYPES_LOADING]: boolean;
    [SET_AVAILABLE_PUBLICATION_TYPES_ERROR]: boolean;
    [SET_AVAILABLE_PUBLICATION_TYPES_WATCHING]: boolean;
    [SET_AVAILABLE_PUBLICATION_TYPES_DATA]: AvailablePublicationTypesData | null;
}

const actionCreator = ActionCreatorHelper<PayloadAvailablePublicationTypes>();
export const updateAvailablePublicationTypesParams = actionCreator(UPDATE_AVAILABLE_PUBLICATION_TYPES_PARAMS);
export const setAvailablePublicationTypesLoading = actionCreator(SET_AVAILABLE_PUBLICATION_TYPES_LOADING);
export const setAvailablePublicationTypesError = actionCreator(SET_AVAILABLE_PUBLICATION_TYPES_ERROR);
export const setAvailablePublicationTypesWatching = actionCreator(SET_AVAILABLE_PUBLICATION_TYPES_WATCHING);
export const setAvailablePublicationTypesData = actionCreator(SET_AVAILABLE_PUBLICATION_TYPES_DATA);

interface AvailablePublicationTypes {
    loading: boolean;
    watching: boolean;
    error: boolean;
    params: AvailablePublicationTypesParams;
    data: AvailablePublicationTypesData | null;
}

const initialState: AvailablePublicationTypes = {
    loading: false,
    watching: false,
    error: false,
    params: {
        areaIds: [],
        divisionId: undefined,
        professionalRoleIds: [],
        withZpCrossPost: undefined,
    },
    data: null,
};

const availablePublicationTypes = createReducer<AvailablePublicationTypes, PayloadAvailablePublicationTypes>(
    initialState,
    {
        [UPDATE_AVAILABLE_PUBLICATION_TYPES_PARAMS]: (state, { payload }) => {
            return { ...state, params: { ...state.params, ...payload } };
        },
        [SET_AVAILABLE_PUBLICATION_TYPES_LOADING]: (state, { payload }) => {
            return { ...state, loading: payload };
        },
        [SET_AVAILABLE_PUBLICATION_TYPES_ERROR]: (state, { payload }) => {
            return { ...state, error: payload };
        },
        [SET_AVAILABLE_PUBLICATION_TYPES_WATCHING]: (state, { payload }) => {
            return { ...state, watching: payload };
        },
        [SET_AVAILABLE_PUBLICATION_TYPES_DATA]: (state, { payload }) => {
            return { ...state, data: payload };
        },
    }
);

export { availablePublicationTypes };
