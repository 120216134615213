import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface AnonymousDashboard {
    backgroundScope: string;
    backgroundIndex: string;
    bigPromoEmployerId: string;
    bigPromoSlogan: string;
    bigPromoHighlightedTextInSlogan: string | null;
    bigPromoLinkText: string;
    bigPromoLinkUrl: string;
    bigPromoFootnote: string | null;
    hideBigPromoFooter: boolean | null;
}

const anonymousDashboard = autoGeneratedReducer<AnonymousDashboard>({
    backgroundScope: '',
    backgroundIndex: '',
    bigPromoEmployerId: '',
    bigPromoSlogan: '',
    bigPromoHighlightedTextInSlogan: null,
    bigPromoLinkText: '',
    bigPromoLinkUrl: '',
    bigPromoFootnote: null,
    hideBigPromoFooter: null,
});

export { anonymousDashboard };
