import createReducer, { ActionCreatorHelper } from '@hh.ru/redux-create-reducer';

import { CriteriaKey } from 'src/types/search/common/criteria';
import { EmployerCriteria } from 'src/types/search/vacancy/criteria';

export const allowedCriteria = [
    CriteriaKey.EmployerId,
    CriteriaKey.Area,
    CriteriaKey.Metro,
    CriteriaKey.Employment,
    CriteriaKey.Schedule,
    CriteriaKey.ProfessionalRole,
    CriteriaKey.Salary,
    CriteriaKey.OnlyWithSalary,
    CriteriaKey.CurrencyCode,
    CriteriaKey.Experience,
    CriteriaKey.Text,
    CriteriaKey.SearchField,
    CriteriaKey.Department,
] as const;

export type AllowedCriteria = (typeof allowedCriteria)[number];

export type EmployerVacancyTemplateFilter = {
    [K in AllowedCriteria]?: EmployerCriteria[K];
};

const SET_EMPLOYER_VACANCY_TEMPLATE_FILTER = 'SET_EMPLOYER_VACANCY_TEMPLATE_FILTER';
const RESET_EMPLOYER_VACANCY_TEMPLATE_TEXT_CRITERIA = 'RESET_EMPLOYER_VACANCY_TEMPLATE_TEXT_CRITERIA';

interface PayloadTypes {
    [SET_EMPLOYER_VACANCY_TEMPLATE_FILTER]: EmployerVacancyTemplateFilter;
    [RESET_EMPLOYER_VACANCY_TEMPLATE_TEXT_CRITERIA]: void;
}

const ActionCreator = ActionCreatorHelper<PayloadTypes>();

export const setEmployerVacancyTemplateFilter = ActionCreator(SET_EMPLOYER_VACANCY_TEMPLATE_FILTER);
export const resetEmployerVacancyTemplateTextCriteria = ActionCreator(RESET_EMPLOYER_VACANCY_TEMPLATE_TEXT_CRITERIA);

const employerVacancyTemplateFilter = createReducer<EmployerVacancyTemplateFilter, PayloadTypes>(
    {},
    {
        [SET_EMPLOYER_VACANCY_TEMPLATE_FILTER]: (_state, action) => ({
            ...action.payload,
        }),
        [RESET_EMPLOYER_VACANCY_TEMPLATE_TEXT_CRITERIA]: (state) => ({
            ...state,
            [CriteriaKey.Text]: undefined,
            [CriteriaKey.SearchField]: undefined,
        }),
    }
);

export { employerVacancyTemplateFilter };
