import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface Category {
    id: number;
    name: string;
    testsCount: number;
    categories: Category[];
}

export type Categories = Category;

export interface CategoriesList {
    rootCategoryId: number;
    categories: Categories[];
}

const testCategories = autoGeneratedReducer<CategoriesList>(null);
export { testCategories };
