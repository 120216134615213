import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export enum EmployerBannerName {
    Clickme = 'clickme',
    ClickmeSecondary = 'clickmeSecondary',
    StartLink = 'startLink',
    HrLink = 'hrLink',
    MobileApp = 'mobileApp',
    RabotaByMobileApp = 'rabotaByMobileApp',
    Skillaz = 'skillaz',
    SkillazSecondary = 'skillazSecondary',
    Pfp = 'pfp',
    Hrspace = 'hrspace',
    HrspaceSecondary = 'hrspaceSecondary',
    Efficiency = 'efficiency',
    Talantix = 'talantix',
    LiveInCompany = 'liveInCompany',
    ItProject = 'itProject',
    Responses = 'responses',
    BrandPage = 'brandPage',
    BrandVacancy = 'brandVacancy',
    BrandSnippet = 'brandSnippet',
    Dreamjob = 'dreamjob',
    Setka = 'setka',
    Branding = 'branding',
    HrSpaceKz = 'hrSpaceKz',
    Search = 'search',
    Vacancy = 'vacancy',
    VacancyStandardPlus = 'vacancyStandardPlus',
    VacancyPremium = 'vacancyPremium',
    HHStats = 'hhStats',
    TurboResponse = 'turboResponse',
    KakDela = 'kakDela',
    HrBrand = 'hrBrand',
    HrBrandConference = 'hrBrandConference',
    VacancyAdvice = 'vacancyAdvice',
    CompanyOfDay = 'companyOfDay',
    VacancyTypesZp = 'vacancyTypesZp',
    BundlesZp = 'bundlesZp',
    BaseZp = 'baseZp',
}

export interface EmployerBannerItem {
    name: EmployerBannerName;
}

export interface EmployerBannersWidget {
    banner?: EmployerBannerItem;
}

const employerBannersWidget = autoGeneratedReducer<EmployerBannersWidget>({});
export { employerBannersWidget };
