import { JobSearchStatus } from 'src/models/applicantUserStatuses';
import { CurrencyType } from 'src/models/currencies.types';
import { DriverLicense } from 'src/models/driverLicense.types';
import { EducationLevel, Gender } from 'src/models/resume/resumeCommon.types';
import { ExpPeriodOption, LogicOption, PosOption, ExpCompanySizeOption } from 'src/models/search/resume/queries';
import { CriteriaKey, OrderByOption, ExperienceOption, FilterExpPeriodOption } from 'src/types/search/common/criteria';
import { EmploymentLower } from 'src/utils/constants/employment';
import { ScheduleCamelCase } from 'src/utils/constants/schedule';

export enum LabelOption {
    OnlyWithSalary = 'only_with_salary',
    OnlyWithAge = 'only_with_age',
    OnlyWithPhoto = 'only_with_photo',
    OnlyWithGender = 'only_with_gender',
    OnlyWithVehicle = 'only_with_vehicle',
}

export enum SearchPeriodOption {
    AllTime = '0',
    Year = '365',
    Month = '30',
    Week = '7',
    ThreeDays = '3',
    Day = '1',
}

export type PossibleSearchPeriod = SearchPeriodOption | 'customInterval';

export enum RelocationOption {
    Living = 'living',
    LivingButRelocation = 'living_but_relocation',
    LivingOrRelocation = 'living_or_relocation',
    Relocation = 'relocation',
}

export interface Criteria {
    [CriteriaKey.Area]?: number[];
    [CriteriaKey.AgeFrom]: number | null;
    [CriteriaKey.AgeTo]: number | null;
    [CriteriaKey.Citizenship]?: number[];
    [CriteriaKey.District]: number[];
    [CriteriaKey.CurrencyCode]: CurrencyType;
    [CriteriaKey.DriverLicenseTypes]: DriverLicense[];
    [CriteriaKey.Education]: EducationLevel;
    [CriteriaKey.EducationLevel]: EducationLevel[];
    [CriteriaKey.Employment]: EmploymentLower[];
    [CriteriaKey.ExpCompanySize]: ExpCompanySizeOption[];
    [CriteriaKey.ExpIndustry]: string[];
    [CriteriaKey.Experience]: ExperienceOption[];
    [CriteriaKey.FilterExpPeriodForIndustry]: FilterExpPeriodOption;
    [CriteriaKey.FilterExpIndustry]: string[];
    [CriteriaKey.Gender]: Gender;
    [CriteriaKey.JobSearchStatus]: JobSearchStatus[];
    [CriteriaKey.SearchPeriod]: SearchPeriodOption;
    [CriteriaKey.OrderBy]: OrderByOption;
    [CriteriaKey.ItemsOnPage]: string;
    [CriteriaKey.DateFrom]: string;
    [CriteriaKey.DateTo]: string;
    [CriteriaKey.VacancyId]?: number;
    [CriteriaKey.Relocation]?: RelocationOption;
    [CriteriaKey.Text]: string[];
    [CriteriaKey.Logic]?: LogicOption[];
    [CriteriaKey.Label]: LabelOption[];
    [CriteriaKey.Language]: string[];
    [CriteriaKey.Metro]: string[];
    [CriteriaKey.NoMagic]: boolean;
    [CriteriaKey.Pos]?: PosOption[];
    [CriteriaKey.ProfessionalRole]: number[];
    [CriteriaKey.SalaryFrom]: number | null;
    [CriteriaKey.SalaryTo]: number | null;
    [CriteriaKey.Schedule]: ScheduleCamelCase[];
    [CriteriaKey.VerifiedSkills]: string[];
    [CriteriaKey.VerifiedLanguages]: string[];
    [CriteriaKey.Skill]: number[];
    [CriteriaKey.SavedSearchId]: number | null;
    [CriteriaKey.SearchSessionId]: string | null;
    [CriteriaKey.University]: string[];
    [CriteriaKey.ExpPeriod]: ExpPeriodOption[];
    [CriteriaKey.WorkTicket]: number[];
    [CriteriaKey.Resume]?: string;
}
