import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface SortingFilter {
    name: string;
    selected: boolean;
    text: string;
}

const sortingFilters = autoGeneratedReducer<SortingFilter[]>([]);
export { sortingFilters };
