import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { Professionalarea } from 'src/models/agencies';
import { Paging } from 'src/models/paging.types';

export interface Region {
    id: number;
    name: string;
}

export interface RegionGroup {
    letter: string;
    region: Region[];
}

export interface Agency {
    id: number;
    logo: string;
    name: string;
    vacanciesNumber: number;
}

export interface Agencies {
    count: number;
    pageNumber: number;
    pageSize: number;
    agency: Agency[];
}

interface AgenciesList {
    professionalAreas: Professionalarea[];
    regions: RegionGroup[];
    agencies: Agencies;
    paging?: Paging;
}

const agenciesList = autoGeneratedReducer<AgenciesList>({
    professionalAreas: [],
    regions: [],
    agencies: { count: 0, pageNumber: 1, pageSize: 20, agency: [] },
});

export { agenciesList };
