import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export enum Lang {
    RU = 'RU',
    EN = 'EN',
    AZ = 'AZ',
    KZ = 'KZ',
    UA = 'UA',
    UZ = 'UZ',
    KG = 'KG',
}

const langs = autoGeneratedReducer<Lang[]>([Lang.RU]);
export { langs };
