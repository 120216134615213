import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface ErrorPage {
    errorMessage?: string;
    isError: boolean;
    xsrfError?: boolean | string;
}

const errorPage = autoGeneratedReducer<ErrorPage>({
    errorMessage: '',
    isError: false,
    xsrfError: false,
});

export { errorPage };
