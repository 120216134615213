import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { ResumeBaseFields } from 'src/models/resume/resume.types';

import * as Field from 'src/models/resumeForm/fields.types';

type CustomFieldProps = 'birthday' | 'metro' | 'salary';
type ResumeFieldForWrap = Omit<ResumeBaseFields, CustomFieldProps>;
export type ResumeFormFields = {
    [Property in keyof ResumeFieldForWrap]: Field.ResumeFieldValueList<ResumeFieldForWrap[Property]> | [];
} & {
    birthday: Field.DateValueList;
    metro: Field.MetroValueList;
    salary: Field.SalaryValueList;
};

const resumeFormFields = autoGeneratedReducer<Partial<ResumeFormFields>>({
    title: [{ string: '' }],
});

export { resumeFormFields };
