import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface Professionalarea {
    id: number;
    title: string;
}

interface Agencies {
    professionalareas: Professionalarea[];
    statistics?: {
        openVacanciesCount: number;
        agenciesCount: number;
    };
}

const agencies = autoGeneratedReducer<Agencies>({
    professionalareas: [],
    statistics: {
        openVacanciesCount: 0,
        agenciesCount: 0,
    },
});

export { agencies };
