import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface InitialExperimentsState {
    enabled: Record<string, string>;
}

export const INITIAL_STATE: InitialExperimentsState = {
    enabled: {},
};

const experiments = autoGeneratedReducer<InitialExperimentsState>(INITIAL_STATE);
export { experiments };
