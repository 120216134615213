import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

import { PurchaseProductList } from 'src/models/vacancyCreate/availablePublicationTypes';

interface VacancyPurchaseProducts {
    products: PurchaseProductList;
}

const vacancyPurchaseProducts = autoGeneratedReducer<VacancyPurchaseProducts>(null);
export { vacancyPurchaseProducts };
