import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export enum EmployerMarketSegmentType {
    Large = 'Large',
    Premium = 'Premium',
    Micro = 'Micro',
    Small = 'Small',
    Medium = 'Medium',
}

const employerMarketSegmentType = autoGeneratedReducer<EmployerMarketSegmentType>(null);
export { employerMarketSegmentType };
