import { autoGeneratedReducer } from '@hh.ru/redux-create-reducer';

export interface Artifact {
    maxCount: number;
    maxFileSize: number;
    maxTitleSize: number;
}
interface ArtifactRules {
    imageMimeType: string[];
    photo: Artifact;
    portfolio: Artifact;
}

const artifactRules = autoGeneratedReducer<ArtifactRules>(null);
export { artifactRules };
